import React, { useState, useEffect, useRef, useCallback } from "react";
import "./createschedule.css";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  CalendarComponent,
  TimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { decodeToken, toastifyMessage, style } from "../../utilities/CustomFunctions";
import { Box, Modal, Typography } from "@mui/material";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Head from "../Layout.WebAppMate/head/Head";
import DatePicker from "react-datepicker";
/**
 * Add validations by <Kajal>:"Kajal@webappmate.com" on 20-11-2023
 * optimization ny @krishna dobariya <krishna.webappmate@gmail.com>
 *  optimization on: 19-6-2024
 */
enableRipple(true);

const Createschedule = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [resourcesData, setResourcesData] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [scheduleType, setScheduleType] = useState("0");
  const [dateWise, setDateWise] = useState(new Date());
  const [iPopup, setIpopup] = useState(false);  
  const [detail, setDetail] = useState([])
  const [indexNum, setIndexNum] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [dataBlank, setDataBlank] = useState(false);
  const [scheduleData, setScheduleData] = useState({
    startDate: "",
    endDate: "",
    duration: "",
    price: 0,
  });
  const [bookStatus, setBookStatus] = useState(false);
  const [groupSize, setGroupSize] = useState("");
  const [monTime, setMonTime] = useState([
    { start_time: "", end_time: "", isEnabled: false },
  ]);
  const [tueTime, setTueTime] = useState([
    { start_time: "", end_time: "", isEnabled: false },
  ]);
  const [wedTime, setWedTime] = useState([
    { start_time: "", end_time: "", isEnabled: false },
  ]);
  const [thurTime, setThurTime] = useState([
    { start_time: "", end_time: "", isEnabled: false },
  ]);
  const [friTime, setFriTime] = useState([
    { start_time: "", end_time: "", isEnabled: false },
  ]);
  const [satTime, setSatTime] = useState([
    { start_time: "", end_time: "", isEnabled: false },
  ]);
  const [sunTime, setSunTime] = useState([
    { start_time: "", end_time: "", isEnabled: false },
  ]);

  const [deletedData, setDeletedData] = useState({});
  const [open, setOpen] = useState(false);
  const [popupDurationOpen, setPopupDurationOpen] = useState(false)
  const [removeReason, setRemoveReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dummy, setDummy] = useState(false);
  const [isInnerLoader, setIsInnerLoader] = useState(false);
  const [bookedDate, setBookedDate] = useState([]);
  const [bookedDate2, setBookedDate2] = useState([]);
  const payLoad = decodeToken().userAuth?.timezone;
  const [tab3, setTab3] = useState(new Date())
  const [tab3Data, setTab3Data] = useState([])
  const [tabIndex, setTabIndex] = useState(0);
  const [removeIndex, setRemoveIndex] = useState("")
  const [viewDate, setViewDate] = useState(new Date())
  const [setsetselectedDateTime, setSetsetselectedDateTime] = useState([]);
  const [popupDuration, setPopupDuration] = useState(false)
  const [show, setShow] = useState(false)
  const resFocus = useRef();
  const sessionFocus = useRef();
  const priceFocus = useRef();
  const durationFocus = useRef();
  const sdateFocus = useRef();
  const sdate2Focus = useRef();
  const edateFocus = useRef();
  const edate2Focus = useRef();
  const grpSizeFocus = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    const param1 = searchParams.get('resource') || null;
    if (param1) {
      setSelectedResources([param1]);
    }
    // eslint-disable-next-line
  }, [location.search]);

  // this function create for view schedule dates
  const handle3Tab = (e) => {
    window.scrollTo(0, 0);
    setIsInnerLoader(true)
    if (e?.value) {
      setTab3(e.value)
    }
    let body = {
      schedule_date: e?.value ? moment(e.value).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")
    }
    makePostRequest(URLS.getMyScheduleDateWise, null, body, null)
      .then((res) => {
        setTab3Data(res?.data)
        setIsInnerLoader(false);
        getMySchedule();
      })
      .catch((error) => {
        setIsInnerLoader(false);
      });
  }
  const handleDate = (e, startEndDate) => {
    let obj = {
      startDate: scheduleData.startDate,
      endDate: scheduleData.endDate,
      duration: scheduleData.duration,
      price: scheduleData.price,
    };
    if (startEndDate === "start") {
      if (obj?.endDate && obj.endDate < e) {
        obj.startDate = e;
        obj.endDate = e;
      } else {
        obj.startDate = e;
      }
    }
    if (startEndDate === "end") {
      obj.endDate = e;
    }
    setScheduleData(obj);
  };
  // this function use for get the functions
  const getMySchedule = () => {
    let data = {};
    makePostRequest(URLS.getMyScheduleDates, null, data, null)
      .then((res) => {
        console.log("res:::", res)
        setBookedDate2(res?.data)
        setTimeout(() => {
          setShow(true)
        }, 800);
      })
      .catch((error) => {
        setIsInnerLoader(false);
        setTimeout(() => {
          setShow(true)
        }, 800);
      });

  }
  // this function use for handle input
  const handleInput = (e) => {
    let name = e.target.name;
    var num = /\D/g;
    var value = e.target.value.replace(num, "");
    let obj = {
      startDate: scheduleData.startDate,
      endDate: scheduleData.endDate,
      duration: scheduleData.duration,
      price: scheduleData.price,
    };
    if (name === "price") {
      if (e.target.value?.includes(".")) {
        if (e.target.value.split(".")[1]?.length <= 2) {
          obj.price = e.target.value?.replaceAll("$", "");
        }
      } else {
        obj.price = e.target.value?.replaceAll("$", "");
      }
    }
    if (name === "duration") {
      if (popupDuration && scheduleData.duration) {
        setPopupDurationOpen(true)
      } else {
        obj.duration = value;
      }
    }
    setScheduleData(obj);
  };
  // this function use for add more time
  const addMore = (status) => {
    let data = [...setsetselectedDateTime];
    let dummy =
      data?.[0]?.mentoring_session_schedule_times[
      data?.[0]?.mentoring_session_schedule_times?.length - 1
      ];
    if (dummy && dummy?.schedule_start_time) {
      data?.[0]?.mentoring_session_schedule_times?.push({
        schedule_end_time: dummy?.schedule_end_time || "05:00",
        schedule_start_time: dummy?.schedule_end_time || "05:00",
        bookedStatus: status === "addMore" ? "addMore" : false
      });
      setSetsetselectedDateTime(data);
    } else {
      setSetsetselectedDateTime([
        {
          id: "",
          schedule_date: new Date(),
          mentoring_session_schedule_times: [
            {
              id: "",
              schedule_id: "",
              schedule_start_time: "05:00",
              schedule_end_time: "05:00",
              status: 0,
              bookedStatus: status === "addMore" ? "addMore" : false,
            },
          ],
          booked_sessions: [],
        },
      ]);
    }
  };

  // this function use for add more time in recurring
  const addMoreTime = (type) => {
    const days = {
      mon: { state: monTime, setter: setMonTime },
      tue: { state: tueTime, setter: setTueTime },
      wed: { state: wedTime, setter: setWedTime },
      thur: { state: thurTime, setter: setThurTime },
      fri: { state: friTime, setter: setFriTime },
      sat: { state: satTime, setter: setSatTime },
      sun: { state: sunTime, setter: setSunTime }
    };

    const { state, setter } = days[type];
    const dummy = state[state.length - 1];

    setter([
      ...state,
      {
        start_time: dummy?.end_time,
        end_time: dummy?.end_time,
        isEnabled: true,
      },
    ]);
  };
  // this function use for delete signle schedule dates
  const deleteMore = (index, data) => {
    setDeletedData(data);
    setRemoveIndex(index)
    getMySchedule();
    setOpen(true);
    setIndexNum(index)
  };

  // this function use for delete dates and times
  const deleteMoreTime = (type, index, id) => {
    if (type === "mon") {
      const newArray = Array.from(monTime);
      newArray.splice(index, 1);
      if (newArray?.length === 0) {
        setMonTime([
          {
            start_time: "05:00",
            end_time: "05:00",
          },
        ]);
      } else {
        setMonTime(newArray);
      }
    }
    if (type === "tue") {
      const newArray = Array.from(tueTime);
      newArray.splice(index, 1);
      if (newArray?.length === 0) {
        setTueTime([
          {
            start_time: "05:00",
            end_time: "05:00",
          },
        ]);
      } else {
        setTueTime(newArray);
      }
    }
    if (type === "wed") {
      const newArray = Array.from(wedTime);
      newArray.splice(index, 1);
      if (newArray?.length === 0) {
        setWedTime([
          {
            start_time: "05:00",
            end_time: "05:00",
          },
        ]);
      } else {
        setWedTime(newArray);
      }
    }
    if (type === "thur") {
      const newArray = Array.from(thurTime);
      newArray.splice(index, 1);
      if (newArray?.length === 0) {
        setThurTime([
          {
            start_time: "05:00",
            end_time: "05:00",
          },
        ]);
      } else {
        setThurTime(newArray);
      }
    }
    if (type === "fri") {
      const newArray = Array.from(friTime);
      newArray.splice(index, 1);
      if (newArray?.length === 0) {
        setFriTime([
          {
            start_time: "05:00",
            end_time: "05:00",
          },
        ]);
      } else {
        setFriTime(newArray);
      }
    }
    if (type === "sat") {
      const newArray = Array.from(satTime);
      newArray.splice(index, 1);
      if (newArray?.length === 0) {
        setSatTime([
          {
            start_time: "05:00",
            end_time: "05:00",
          },
        ]);
      } else {
        setSatTime(newArray);
      }
    }
    if (type === "sun") {
      const newArray = Array.from(sunTime);
      newArray.splice(index, 1);
      if (newArray?.length === 0) {
        setSunTime([
          {
            start_time: "05:00",
            end_time: "05:00",
          },
        ]);
      } else {
        setSunTime(newArray);
      }
    }
  };
  //api to get all serve resources data
  const getAllResources = () => {
    setIsLoading(true);
    let data = {
      resource_mode: "serve",
      available: "",
      page_number: 1,
      item_per_page: 500,
      sortby: "",
    };
    makePostRequest(URLS.mentorSessionResource, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          let data = res?.data;
          let arr = [];
          data &&
            data.length > 0 &&
            data.forEach((element) => {
              if (element?.resource_type_status === "group") {
                arr.push({ id: element.id, name: element.resource_title + " ( Group )" });
              }
              else {
                arr.push({ id: element.id, name: element.resource_title });
              }
            });
          const sort = arr.sort((a, b) => a.name.localeCompare(b.name))
          setResourcesData(sort);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  // handle single schedule
  const handleChange = (e, index, startend_time) => {
    let newArray = [...setsetselectedDateTime];
    const isGreaterDuplicate = newArray[0].mentoring_session_schedule_times.some(
      (item, i) =>
        i !== index &&
        (moment(item.schedule_start_time, "HH:mm") >= moment(e.target.value, "HH:mm") ||
          moment(item.schedule_end_time, "HH:mm") >= moment(e.target.value, "HH:mm")));
    if (isGreaterDuplicate) {
      newArray[0].mentoring_session_schedule_times.splice(index + 1);
    }

    // Update the selected row with the new time
    if (startend_time === "start") {
      if (
        newArray[0].mentoring_session_schedule_times[index].schedule_end_time &&
        newArray[0].mentoring_session_schedule_times[index].schedule_end_time <
        e.target.prevValue
      ) {
        newArray[0].mentoring_session_schedule_times[index].schedule_end_time =
          moment(e.target.prevValue, "h:mm:ss A").format("HH:mm");
        newArray[0].mentoring_session_schedule_times[index].schedule_start_time =
          moment(e.target.prevValue, "h:mm:ss A").format("HH:mm");
      } else {
        newArray[0].mentoring_session_schedule_times[index].schedule_start_time =
          moment(e.target.prevValue, "h:mm:ss A").format("HH:mm");
      }
    }
    if (startend_time === "end") {
      newArray[0].mentoring_session_schedule_times[index].schedule_end_time =
        moment(e.target.prevValue, "h:mm:ss A").format("HH:mm");
    }
    setSetsetselectedDateTime(newArray);
  };
  //handle start end time
  const handleTime = (e, index, day, startend_time) => {
    const days = {
      mon: { state: monTime, setter: setMonTime },
      tue: { state: tueTime, setter: setTueTime },
      wed: { state: wedTime, setter: setWedTime },
      thur: { state: thurTime, setter: setThurTime },
      fri: { state: friTime, setter: setFriTime },
      sat: { state: satTime, setter: setSatTime },
      sun: { state: sunTime, setter: setSunTime }
    };

    const selectedTime = moment(e.target.value, "h:mm:ss A").format("HH:mm");
    const { state, setter } = days[day];
    const newArray = [...state];

    const isGreaterDuplicate = newArray.some((item, i) => {
      return i !== index &&
        (
          (moment(newArray[newArray.length - 1].start_time, "HH:mm") <= moment(selectedTime, "HH:mm") ||
            moment(newArray[newArray.length - 1].end_time, "HH:mm") <= moment(selectedTime, "HH:mm")) ||
          (moment(item.start_time, "HH:mm") <= moment(selectedTime, "HH:mm") ||
            moment(item.end_time, "HH:mm") <= moment(selectedTime, "HH:mm"))
        );
    });
    if (isGreaterDuplicate) {
      newArray.splice(index + 1);
      newArray[index]["end_time"] = selectedTime;
    }
    newArray[index][startend_time + "_time"] = selectedTime;
    newArray[index]["end_time"] = selectedTime;
    setter(newArray);
  };

  //api to create ongoing schedule
  const createOngoingSchdeule = () => {
    if (parseInt(scheduleType) === 1) {
      if (groupSize === "") {
        grpSizeFocus.current.focus();
        toastifyMessage("Please add group size", "error");
        return;
      }
    }
    if (selectedResources === "") {
      resFocus.current.focus();
      toastifyMessage("Please select resource", "error");
      return;
    } else if (parseInt(scheduleType) === 0) {
      sessionFocus.current.focus();
      toastifyMessage("Please select Session Type ", "error");
      return;
    }
    else if (scheduleData.duration === "") {
      durationFocus.current.focus();
      toastifyMessage("Please add duration", "error");
      return;
    }
    else if (scheduleData.price === "") {
      priceFocus.current.focus();
      toastifyMessage("Please add price", "error");
      return;
    }
    else if (scheduleData?.startDate === "") {
      sdate2Focus.current.setOpen(true);
      sdateFocus.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      toastifyMessage("Please add start date", "error");
      return;
    }
    else if (scheduleData?.endDate === "") {
      edate2Focus.current.setOpen(true);
      edateFocus.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      toastifyMessage("Please add end date", "error");
      return;
    }
    if (
      selectedResources &&
      scheduleType !== 0 &&
      scheduleData?.startDate &&
      scheduleData?.endDate &&
      scheduleData.price &&
      scheduleData.duration
    ) {
      setShow(false)
      setIsLoading(true);
      let data = {
        resource_ids: selectedResources || [],
        start_date: moment(scheduleData?.startDate).format("YYYY-MM-DD"),
        end_date: moment(scheduleData?.endDate).format("YYYY-MM-DD"),
        duration: +scheduleData.duration || 0,
        price: +scheduleData.price || 0,
        schedule_type: scheduleType,
        max_member_size: groupSize || 0,
        day_time: [],
      };
      if (monTime?.[0]?.isEnabled) {
        data["day_time"] = [...data?.day_time, { Monday: monTime }];
      }
      if (tueTime?.[0]?.isEnabled) {
        data["day_time"] = [...data?.day_time, { Tuesday: tueTime }];
      }
      if (wedTime?.[0]?.isEnabled) {
        data["day_time"] = [...data?.day_time, { Wednesday: wedTime }];
      }
      if (thurTime?.[0]?.isEnabled) {
        data["day_time"] = [...data?.day_time, { Thursday: thurTime }];
      }
      if (friTime?.[0]?.isEnabled) {
        data["day_time"] = [...data?.day_time, { Friday: friTime }];
      }
      if (satTime?.[0]?.isEnabled) {
        data["day_time"] = [...data?.day_time, { Saturday: satTime }];
      }
      if (sunTime?.[0]?.isEnabled) {
        data["day_time"] = [...data?.day_time, { Sunday: sunTime }];
      }
      setViewDate({ value: moment(scheduleData?.startDate).format("YYYY-MM-DD") })
      makePostRequest(URLS.reoccurringSchedule, null, data, null)
        .then((res) => {
          setIsLoading(false);
          if (res.code === 200) {
            console.log("res", res)
            setTabIndex(2)
            setTab3(scheduleData?.startDate)
            setDataBlank(true)
            setScheduleData({ ...scheduleData, startDate: "", endDate: "" });
            setMonTime([{ start_time: "", end_time: "", isEnabled: false }]);
            setTueTime([{ start_time: "", end_time: "", isEnabled: false }]);
            setWedTime([{ start_time: "", end_time: "", isEnabled: false }]);
            setThurTime([{ start_time: "", end_time: "", isEnabled: false }]);
            setFriTime([{ start_time: "", end_time: "", isEnabled: false }]);
            setSatTime([{ start_time: "", end_time: "", isEnabled: false }]);
            setSunTime([{ start_time: "", end_time: "", isEnabled: false }]);
            toastifyMessage(res?.message, "success");
          } else {
            toastifyMessage(res?.message, "error")
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  //  this function handle save single schedule
  const handleavailability = () => {
    if (parseInt(scheduleType) === 1) {
      if (groupSize === "") {
        grpSizeFocus.current.focus();
        toastifyMessage("Please add group size ", "error");
        return;
      }
    }
    if (selectedResources === "") {
      resFocus.current.focus();
      toastifyMessage("Please select resource", "error");
      return;
    } else if (parseInt(scheduleType) === 0) {
      sessionFocus.current.focus();
      toastifyMessage("Please select Session Type ", "error");
      return;
    }
    else if (scheduleData.duration === "") {
      durationFocus.current.focus();
      toastifyMessage("Please add duration", "error");
      return;
    }
    else if (scheduleData.price === "") {
      priceFocus.current.focus();
      toastifyMessage("Please add price", "error");
      return;
    }
    else {
      setIsLoading(true);
      let data = {
        resource_ids: selectedResources || [],
        duration: +scheduleData.duration || 0,
        price: +scheduleData.price || 0,
        schedule_type: scheduleType,
        max_member_size: groupSize || 0,
        schedule_date: dateWise
          ? moment(dateWise)?.format("YYYY-MM-DD")
          : moment(new Date())?.format("YYYY-MM-DD"),
        day_time:
          setsetselectedDateTime[0]?.mentoring_session_schedule_times?.map(
            (v) => ({
              start_time: v?.schedule_start_time,
              end_time: v?.schedule_end_time,
            })
          ),
      };
      makePostRequest(URLS.dateWiseSchedule, null, data, null)
        .then((res) => {
          setIsLoading(false);
          if (res.code === 200) {
            setDataBlank(true)
            setShow(false)
            setTab3(dateWise)
            setViewDate({ value: dateWise })
            setTabIndex(2)
            getMySchedule();
            setDateWise(new Date());
            setSetsetselectedDateTime([
              {
                id: "",
                schedule_date: new Date(),
                mentoring_session_schedule_times: [
                  {
                    id: "",
                    schedule_id: "",
                    schedule_start_time: "05:00",
                    schedule_end_time: "05:00",
                    status: 0,
                    bookedStatus: false,
                  },
                ],
                booked_sessions: [],
              },
            ]);
            toastifyMessage(res?.message, "success");
            getDataHead();
          } else {
            toastifyMessage(res?.message, "error");
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if ((tabIndex === 0 || tabIndex === 1) && dataBlank === true) {
      setSelectedResources("")
      setGroupSize("")
      setScheduleData({
        startDate: "",
        endDate: "",
        duration: "",
        price: 0,
      })
      setScheduleType("")
    }
  }, [tabIndex, dataBlank])

  useEffect(() => {
    getMySchedule();
    getAllResources();
    document.body.classList.add("createschedule");
    return () => {
      document.body.classList.remove("createschedule");
    };
  }, []);

  // thid function use for get data on based on type
  const getDataHead = () => {
    setIsLoading(true);
    setSetsetselectedDateTime([]);
    makePostRequest(
      URLS.getScheduleType,
      null,
      {
        schedule_type: scheduleType,
        resource_id: selectedResources[0],
      },
      null
    ).then((res) => {
      if (res?.data?.schedule_dates) {
        setBookedDate(res?.data?.schedule_dates);
        if (res?.data?.schedule_dates.length > 0) {
          const date = res?.data?.schedule_dates?.map((v) => v?.schedule_date);
          date.sort((a, b) => new Date(a) - new Date(b));
          setDateWise(date?.length > 0 ? new Date(date[0]) : new Date())
        } else {
          setDateWise(new Date())
        }
      }
      setGroupSize(res?.data?.max_member_size);
      setScheduleData({
        ...scheduleData,
        duration: res?.data?.duration,
        price: res?.data?.price?.toFixed(2),
      });
      if (res?.data?.duration) {
        setPopupDuration(true)
      }
      setIsLoading(false);
      setShow(true)
    })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (selectedResources && scheduleType) {
      getDataHead();
    }
    // eslint-disable-next-line
  }, [selectedResources, scheduleType]);

  // this function use for handle date 
  const onDateSelect = useCallback((e) => {
    if (selectedResources?.length === 0) {
      toastifyMessage("Please select resource", "error");
    } else {
      setIsInnerLoader(true);
      if (e?.value) {
        setDateWise(e.value);
      }
      let data = {
        resource_ids: selectedResources || [],
        schedule_date: e?.value ? e.value : dateWise || new Date(),
        schedule_type: scheduleType,
      };
      makePostRequest(URLS.getScheduleDatwWise, null, data, null)
        .then((res) => {
          if (res?.data?.length > 0) {
            setSetsetselectedDateTime(res?.data);
          } else {
            setSetsetselectedDateTime([]);
          }
          setIsInnerLoader(false);
        })
        .catch((error) => {
          setIsInnerLoader(false);
        });
    }
  }, [selectedResources, dateWise, scheduleType]);

  useEffect(() => {
    if (scheduleType && selectedResources?.length > 0) onDateSelect();
  }, [scheduleType, selectedResources, onDateSelect]);

  // ---start these function use for handle delete

  const handleDelete2 = () => {
    if (deletedData?.bookedStatus === true) {
      setBookStatus(true);
      setShowButton(false);
    } else {
      setBookStatus(false);
      setShowButton(true);
    }
  }
  // Removing temporary schedules from array
  const handleDelete3 = () => {
    console.log(indexNum, "indexNum")
      let newArray = [...setsetselectedDateTime];
      setOpen(false)
      newArray[0].mentoring_session_schedule_times.splice(indexNum, 1);
      setSetsetselectedDateTime(newArray)
 
  }
  //  this function se for handle delete Schdule
  const handleDelete = () => {
    // setShow(false)
    setIsLoading(true);
    if (deletedData?.id) {
      let body = {
        id: deletedData?.id,
        remove_type: deletedData?.bookedStatus === false ? 1 : 2,
        remove_reason: removeReason,
      };
      if (deletedData?.bookedStatus === true) {
        body["remove_reason"] = removeReason;
      }
      makePostRequest(URLS.removeSchdule, null, body, null)
        .then((res) => {
          setShowButton(true)
          setBookStatus(false)
          getMySchedule();
          getDataHead();
          setOpen(false);
          const momentObject = moment(detail?.schedule_date);
          const formattedDate = momentObject.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
          handle3Tab({ value: formattedDate })
          toastifyMessage(res?.message, "success");
          setIsLoading(false);
        })
        .catch((error) => {
          setOpen(false);
          toastifyMessage(error?.message, "error");
          setIsLoading(false);
        });
    } else {
      let dummy = setsetselectedDateTime;
      const data = removeIndex && setsetselectedDateTime?.[0]?.mentoring_session_schedule_times.filter((item, index) => index !== removeIndex);
      dummy[0].mentoring_session_schedule_times = data
      if (dummy?.[0]?.mentoring_session_schedule_times?.length > 0) {
        setSetsetselectedDateTime(dummy);
      } else {
        setSetsetselectedDateTime([
          {
            id: "",
            schedule_date: new Date(),
            mentoring_session_schedule_times: [
              {
                id: "",
                schedule_id: "",
                schedule_start_time: "",
                schedule_end_time: "",
                status: 0,
                bookedStatus: false,
              },
            ],
            booked_sessions: [],
          },
        ]);
      }
      setOpen(false);
      toastifyMessage("Session removed", "success");
      setIsLoading(false);
    }
  };
  //  ---end these function use for handle delete

  // this function use for disable dates
  const disabledDate = (args) => {
    try {
      if (bookedDate.length > 0) {
        let date = bookedDate?.map((v) => v?.schedule_date);
        let span;
        span = document.createElement("span");
        span.setAttribute("class", "e-icons highlight-day");

        if (
          date?.includes(moment(args?.date).format("YYYY-MM-DD")) &&
          moment(args?.date).format("YYYY-MM-DD") >=
          moment(new Date()).format("YYYY-MM-DD")
        ) {
          args.element.appendChild(span);
          args.element.className = "special";
          args.element.setAttribute("title", "World forest day");
        }
      }
    } catch (error) {
    }
  };

  const disabledDate2 = (args) => {
    try {
      if (bookedDate2.length > 0) {
        let date = bookedDate2?.map((v) => v?.schedule_date);
        let span;
        span = document.createElement("span");
        span.setAttribute("class", "e-icons highlight-day");
        if (
          date?.includes(moment(args?.date).format("YYYY-MM-DD")) &&
          moment(args?.date).format("YYYY-MM-DD") >=
          moment(new Date()).format("YYYY-MM-DD")
        ) {
          args.element.appendChild(span);
          args.element.className = "special";
          args.element.setAttribute("title", "World forest day");
        }
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    viewDate && handle3Tab(viewDate);
    // eslint-disable-next-line
  }, [tabIndex, viewDate])

  // this function use for clear tie
  const clearTime = () => {
    setMonTime([{ start_time: "", end_time: "", isEnabled: false }]);
    setTueTime([{ start_time: "", end_time: "", isEnabled: false }]);
    setWedTime([{ start_time: "", end_time: "", isEnabled: false }]);
    setThurTime([{ start_time: "", end_time: "", isEnabled: false }]);
    setFriTime([{ start_time: "", end_time: "", isEnabled: false }]);
    setSatTime([{ start_time: "", end_time: "", isEnabled: false }]);
    setSunTime([{ start_time: "", end_time: "", isEnabled: false }]);
    setScheduleData({ ...scheduleData, duration: "" });
    setPopupDurationOpen(false)
    setPopupDuration(false)
    setSetsetselectedDateTime([])
  }

  const handleFocus = (e) => {
    if (scheduleData.price !== "") {
      const formattedValue = parseFloat(scheduleData.price).toFixed(2);
      setScheduleData({ ...scheduleData, price: formattedValue });
    }
  };
  return (
    <>
 <Head title="Scheduling"
        content="Scheduling: Schedule availability and sign-up for services and events."
        name="Scheduling" ></Head>
      <div className="col-lg-10">
        <div className="create-schedule">
          <div className="create-schedule-content">
            <h5
              className="col-lg-2 col-md-2 col-sm-2 create-icon cursor-pointer"
              // onClick={() => navigate(-1)}
              onClick={() => {
                if (selectedResources?.[0]) {
                  // Redirect to specified route if there's a selected resource ID
                  navigate(`/serve/${selectedResources[0]}`);
                } else {
                  // Go back to the last page
                  navigate(-1);
                }
              }}
            >
              <span>
                <i class="fa fa-angle-left" aria-hidden="true"></i>
              </span>{" "}
              Go Back
            </h5>
            {isLoading ? (
              <ul>
                <li>
                  <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                  </div>
                </li>
              </ul>
            ) : resourcesData?.length ? (
              <>
                {" "}
                <h1>Set your ongoing availability:</h1>
                <div className="schedule-padding">
                  {tabIndex !== 2 && <div className="row createResourceFilters">
                    <div className="col-lg-3">
                      <h3 className="create-span-sechule">
                        Resource <span>*</span>
                      </h3>
                      <div className="form-select">
                        <select
                          className="res-select"
                          value={selectedResources}
                          onChange={(e) => setSelectedResources([e.target.value])}
                          name="scheduleType"
                          ref={resFocus}
                        >
                          <option value="">Select Resource</option>
                          {resourcesData.length > 0 && resourcesData?.map((v) => (
                            <option value={v?.id}>{v?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="duration-text">
                        <h3>Session Type <span>*</span></h3>
                        <select
                          className="sch-select"
                          value={scheduleType}
                          onChange={(e) => setScheduleType(e.target.value)}
                          name="scheduleType"
                          ref={sessionFocus}
                        >
                          <option value="0">Session Type</option>
                          <option value="2">Individual Session</option>
                          <option value="1">Group Session</option>
                        </select>
                      </div>
                    </div>
                    {scheduleType === "1" && (
                      <div className="col-lg-3">
                        <div className="duration-text">
                          <h3>Max Group Size <span>*</span></h3>
                          <input
                            type="text"
                            value={groupSize}
                            name="groupSize"
                            onChange={(e) => setGroupSize(e.target.value)}
                            placeholder="Enter group size"
                            min="0"
                            ref={grpSizeFocus}
                            required
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-lg-3">
                      <div className="duration-text">
                        <h3> Duration (in minutes) <span>*</span></h3>
                        <input
                          type="text"
                          value={scheduleData.duration}
                          name="duration"
                          onChange={handleInput}
                          placeholder="Duration"
                          ref={durationFocus}
                          onBlur={() => setPopupDuration(true)}
                          min="0"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="duration-text">
                        <h3>Price (in $)<span>*</span></h3>
                        <input
                          type="text"
                          value={scheduleData.price ? "$" + scheduleData.price : scheduleData.price}
                          name="price"
                          onChange={handleInput}
                          onBlur={handleFocus}
                          placeholder="Price"
                          min="0"
                          required
                        />
                      </div>
                    </div>
                  </div>}
                  <div className="schedule-date mt-5 pt-3">
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => { setTabIndex(index); tabIndex !== 2 && setTab3Data([]); tabIndex !== 2 && setTab3(new Date()); }}>
                      <TabList>
                        <Tab>
                          <h3 className="mt-0">Reoccurring booking</h3>
                        </Tab>
                        <Tab>
                          <h3 className="mt-0">Schedule/Edit Single Booking</h3>
                        </Tab>
                        <Tab>
                          <h3 className="mt-0">View my schedule</h3>
                        </Tab>
                      </TabList>
                      <TabPanel>
                        {
                          (scheduleData.duration !== undefined &&
                            scheduleData.duration !== "" &&
                            scheduleData.price !== undefined &&
                            scheduleData.price !== "" &&
                            scheduleType &&
                            selectedResources !== "") ?
                            <>
                              <h4 className="my-3">{payLoad && <> Time zone: {payLoad?.replace("_", " ")}</>}</h4>
                              <div className="col-lg-12 p-0">
                                <div className="row">
                                  <div className="col-lg-8">
                                    <div className="createResourceFilters  createResourceFiltersReoccuringbooking ">
                                      <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6">
                                          <h3>Start Date <span className="star-color">*</span></h3>
                                          <div className="schecule-date-calendarIcon" ref={sdateFocus}>
                                            <DatePicker
                                              className="w-100 searchDate"
                                              id="date"
                                              minDate={new Date()}
                                              selected={scheduleData.startDate}
                                              onChange={(e) => handleDate(e, "start")}
                                              placeholderText="Start Date"
                                              ref={sdate2Focus}
                                            />
                                          </div>
                                        </div>
                                        <div className="end-date col-lg-6 col-md-6 col-sm-6">
                                          <h3>End Date<span className="star-color">*</span></h3>
                                          <div className="schecule-date-calendarIcon" ref={edateFocus}>
                                            <DatePicker ref={edate2Focus}
                                              className="w-100 searchDate"
                                              id="date"
                                              selected={scheduleData.endDate}
                                              onChange={(e) => handleDate(e, "end")}
                                              placeholderText="End Date"
                                              minDate={scheduleData.startDate ? new Date(scheduleData.startDate) : new Date()}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="create-schedule-div  col-lg-8">
                                    <div className="schedule-content">
                                      <ul>
                                        <li>
                                          {monTime.length > 0 &&
                                            monTime.map((data, index) => {
                                              return (
                                                <>
                                                  <div className="content row">
                                                    <div className="col-4 col-lg-4 col-md-4">
                                                      <div className="col-lg-8">
                                                        <h2>
                                                          {index === 0
                                                            ? "Monday"
                                                            : ""}
                                                        </h2>
                                                      </div>
                                                      <div className="col-lg-4 col-md-2 col-sm-2">
                                                        {index === 0 && (
                                                          <label className="toggle-switch ">
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                data?.isEnabled
                                                              }
                                                              onChange={(e) => {
                                                                setMonTime([
                                                                  {
                                                                    start_time:
                                                                      "05:00",
                                                                    end_time: "05:00",
                                                                    isEnabled:
                                                                      e.target
                                                                        .checked,
                                                                  },
                                                                ]);
                                                                setDummy(!dummy);
                                                              }}
                                                            />
                                                            <span className="switch" />
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="col-8 col-lg-8 col-md-8">
                                                      {data?.isEnabled === true ? (
                                                        <>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={15}
                                                              format={"hh:mm a"}
                                                              id={`timepicker${index}`}
                                                              name={`start_time${index}`}
                                                              placeholder="Select Time"
                                                              value={moment(data?.start_time, "h:mm:ss A").format("hh:mm A")}
                                                              min={index !== 0 ? new Date(
                                                                `${moment(
                                                                  new Date().setDate(
                                                                    new Date().getDate() -
                                                                    1
                                                                  )
                                                                ).format(
                                                                  "MM/DD/YYYY"
                                                                )} ${monTime?.[
                                                                  monTime?.length -
                                                                  1
                                                                ]?.end_time
                                                                }`
                                                              ) : new Date(moment().subtract(1, 'days').format("YYYY-MM-DD") + "T05:00:00")
                                                              }
                                                              allowEdit={false}
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "mon",
                                                                  "start"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              id={`timepicker1${index}`}
                                                              name={`end_time${index}`}
                                                              placeholder="Select Time"
                                                              min={
                                                                new Date(
                                                                  `${moment(
                                                                    new Date()
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )} ${data?.start_time
                                                                  }`
                                                                )
                                                              }
                                                              value={moment(data?.end_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "mon",
                                                                  "end"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={15}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}

                                                            />
                                                          </div>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}

                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                      <div
                                                        className="col-lg-2"
                                                      >
                                                        <button
                                                          className="createaddplus"
                                                          disabled={!data?.isEnabled}
                                                          style={{
                                                            backgroundColor:
                                                              !data?.isEnabled &&
                                                              "lightgray",
                                                          }}
                                                          onClick={() => {
                                                            deleteMoreTime(
                                                              "mon",
                                                              index,
                                                              data.id
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </div>
                                                      {index === 0 && (
                                                        <div
                                                          className="col-lg-2"
                                                          onClick={() => {
                                                            data?.isEnabled &&
                                                              addMoreTime("mon");
                                                          }}
                                                        >
                                                          <button
                                                            className="createaddplus"
                                                            disabled={
                                                              !data?.isEnabled
                                                            }
                                                            style={{
                                                              backgroundColor:
                                                                !data?.isEnabled &&
                                                                "lightgray",
                                                            }}
                                                          >
                                                            <i className="fa fa-plus"></i>
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })}
                                        </li>
                                        <li>
                                          {tueTime.length > 0 &&
                                            tueTime.map((data, index) => {
                                              return (
                                                <>
                                                  <div className="content row">
                                                    <div className="col-4 col-lg-4 col-md-4">
                                                      <div className="col-lg-8">
                                                        <h2>
                                                          {index === 0
                                                            ? "Tuesday"
                                                            : ""}
                                                        </h2>
                                                      </div>
                                                      <div className="col-lg-4 col-md-2 col-sm-2">
                                                        {index === 0 && (
                                                          <label className="toggle-switch ">
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                data?.isEnabled
                                                              }
                                                              onChange={(e) => {
                                                                setTueTime([
                                                                  {
                                                                    start_time:
                                                                      "05:00",
                                                                    end_time: "05:00",
                                                                    isEnabled:
                                                                      e.target
                                                                        .checked,
                                                                  },
                                                                ]);
                                                              }}
                                                            />
                                                            <span className="switch" />
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="col-8 col-lg-8 col-md-8">
                                                      {data?.isEnabled === true ? (
                                                        <>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={15}
                                                              format={"hh:mm a"}
                                                              id={`timepicker${index}`}
                                                              name={`start_time${index}`}
                                                              placeholder="Select Time"
                                                              min={index !== 0 ? new Date(
                                                                `${moment(
                                                                  new Date().setDate(
                                                                    new Date().getDate() -
                                                                    1
                                                                  )
                                                                ).format(
                                                                  "MM/DD/YYYY"
                                                                )} ${tueTime?.[
                                                                  tueTime?.length -
                                                                  1
                                                                ]?.end_time
                                                                }`
                                                              ) : new Date(moment().subtract(1, 'days').format("YYYY-MM-DD") + "T05:00:00")
                                                              }
                                                              value={moment(data?.start_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "tue",
                                                                  "start"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              id={`timepicker1${index}`}
                                                              name={`end_time${index}`}
                                                              placeholder="Select Time"
                                                              min={
                                                                new Date(
                                                                  `${moment(
                                                                    new Date()
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )} ${data?.start_time
                                                                  }`
                                                                )
                                                              }
                                                              value={moment(data?.end_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "tue",
                                                                  "end"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                      <div className="col-lg-2">
                                                        <button
                                                          className="createaddplus"
                                                          disabled={!data?.isEnabled}
                                                          style={{
                                                            backgroundColor:
                                                              !data?.isEnabled &&
                                                              "lightgray",
                                                          }}
                                                          onClick={() => {
                                                            deleteMoreTime(
                                                              "tue",
                                                              index,
                                                              data.id
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </div>
                                                      {index === 0 && (
                                                        <div
                                                          className="col-lg-2"
                                                          onClick={() => {
                                                            data?.isEnabled &&
                                                              addMoreTime("tue");
                                                          }}
                                                        >
                                                          <button
                                                            className="createaddplus"
                                                            style={{
                                                              backgroundColor:
                                                                !data?.isEnabled &&
                                                                "lightgray",
                                                            }}
                                                            disabled={
                                                              !data?.isEnabled
                                                            }
                                                          >
                                                            <i className="fa fa-plus"></i>
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })}
                                        </li>
                                        <li>
                                          {wedTime.length > 0 &&
                                            wedTime.map((data, index) => {
                                              return (
                                                <>
                                                  <div className="content row">
                                                    <div className="col-4 col-lg-4 col-md-4">
                                                      <div className="col-lg-8">
                                                        <h2>
                                                          {index === 0
                                                            ? "Wednesday"
                                                            : ""}
                                                        </h2>
                                                      </div>
                                                      <div className="col-lg-4 col-md-2 col-sm-2">
                                                        {index === 0 && (
                                                          <label className="toggle-switch ">
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                data?.isEnabled
                                                              }
                                                              onChange={(e) => {
                                                                setWedTime([
                                                                  {
                                                                    start_time:
                                                                      "05:00",
                                                                    end_time: "05:00",
                                                                    isEnabled:
                                                                      e.target
                                                                        .checked,
                                                                  },
                                                                ]);
                                                              }}
                                                            />
                                                            <span className="switch" />
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="col-8 col-lg-8 col-md-8">
                                                      {data?.isEnabled === true ? (
                                                        <>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={15}
                                                              format={"hh:mm a"}
                                                              id={`timepicker${index}`}
                                                              name={`start_time${index}`}
                                                              placeholder="Select Time"
                                                              min={index !== 0 ? new Date(
                                                                `${moment(
                                                                  new Date().setDate(
                                                                    new Date().getDate() - 1)).format("MM/DD/YYYY"
                                                                    )} ${wedTime?.[
                                                                      wedTime?.length -
                                                                      1
                                                                    ]?.end_time
                                                                }`
                                                              ) : new Date(moment().subtract(1, 'days').format("YYYY-MM-DD") + "T05:00:00")
                                                              }
                                                              value={moment(data?.start_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "wed",
                                                                  "start"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              id={`timepicker1${index}`}
                                                              name={`end_time${index}`}
                                                              placeholder="Select Time"
                                                              value={moment(data?.end_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              min={
                                                                new Date(
                                                                  `${moment(
                                                                    new Date()
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )} ${data?.start_time
                                                                  }`
                                                                )
                                                              }
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "wed",
                                                                  "end"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={30}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                      <div className="col-lg-2">
                                                        <button
                                                          className="createaddplus"
                                                          style={{
                                                            backgroundColor:
                                                              !data?.isEnabled &&
                                                              "lightgray",
                                                          }}
                                                          disabled={!data?.isEnabled}
                                                          onClick={() => {
                                                            deleteMoreTime(
                                                              "wed",
                                                              index,
                                                              data.id
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </div>
                                                      {index === 0 && (
                                                        <div
                                                          className="col-lg-2"
                                                          onClick={() => {
                                                            data?.isEnabled &&
                                                              addMoreTime("wed");
                                                          }}
                                                        >
                                                          <button
                                                            className="createaddplus"
                                                            style={{
                                                              backgroundColor:
                                                                !data?.isEnabled &&
                                                                "lightgray",
                                                            }}
                                                            disabled={
                                                              !data?.isEnabled
                                                            }
                                                          >
                                                            <i className="fa fa-plus"></i>
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })}
                                        </li>
                                        <li>
                                          {thurTime.length > 0 &&
                                            thurTime.map((data, index) => {
                                              return (
                                                <>
                                                  <div className="content row">
                                                    <div className="col-4 col-lg-4 col-md-4">
                                                      <div className="col-lg-8">
                                                        <h2>
                                                          {index === 0
                                                            ? "Thursday"
                                                            : ""}
                                                        </h2>
                                                      </div>
                                                      <div className="col-lg-4 col-md-2 col-sm-2">
                                                        {index === 0 && (
                                                          <label className="toggle-switch ">
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                data?.isEnabled
                                                              }
                                                              onChange={(e) => {
                                                                setThurTime([
                                                                  {
                                                                    start_time:
                                                                      "05:00",
                                                                    end_time: "05:00",
                                                                    isEnabled:
                                                                      e.target
                                                                        .checked,
                                                                  },
                                                                ]);
                                                              }}
                                                            />
                                                            <span className="switch" />
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="col-8 col-lg-8 col-md-8">
                                                      {data?.isEnabled === true ? (
                                                        <>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={15}
                                                              format={"hh:mm a"}
                                                              id={`timepicker${index}`}
                                                              name={`start_time${index}`}
                                                              placeholder="Select Time"
                                                              value={moment(data?.start_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              min={index !== 0 ? new Date(
                                                                `${moment(
                                                                  new Date().setDate(
                                                                    new Date().getDate() -
                                                                    1
                                                                  )
                                                                ).format(
                                                                  "MM/DD/YYYY"
                                                                )} ${thurTime?.[
                                                                  thurTime?.length -
                                                                  1
                                                                ]?.end_time
                                                                }`
                                                              ) : new Date(moment().subtract(1, 'days').format("YYYY-MM-DD") + "T05:00:00")
                                                              }
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "thur",
                                                                  "start"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              id={`timepicker1${index}`}
                                                              name={`end_time${index}`}
                                                              placeholder="Select Time"
                                                              value={moment(data?.end_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              min={
                                                                new Date(
                                                                  `${moment(
                                                                    new Date()
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )} ${data?.start_time
                                                                  }`
                                                                )
                                                              }
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "thur",
                                                                  "end"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={30}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                      <div className="col-lg-2">
                                                        <button
                                                          className="createaddplus"
                                                          style={{
                                                            backgroundColor:
                                                              !data?.isEnabled &&
                                                              "lightgray",
                                                          }}
                                                          disabled={!data?.isEnabled}
                                                          onClick={() => {
                                                            deleteMoreTime(
                                                              "thur",
                                                              index,
                                                              data.id
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </div>
                                                      {index === 0 && (
                                                        <div
                                                          className="col-lg-2"
                                                          onClick={() => {
                                                            data?.isEnabled &&
                                                              addMoreTime("thur");
                                                          }}
                                                        >
                                                          <button
                                                            className="createaddplus"
                                                            style={{
                                                              backgroundColor:
                                                                !data?.isEnabled &&
                                                                "lightgray",
                                                            }}
                                                            disabled={
                                                              !data?.isEnabled
                                                            }
                                                          >
                                                            <i className="fa fa-plus"></i>
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })}
                                        </li>
                                        <li>
                                          {friTime.length > 0 &&
                                            friTime.map((data, index) => {
                                              return (
                                                <>
                                                  <div className="content row">
                                                    <div className="col-4 col-lg-4 col-md-4">
                                                      <div className="col-lg-8">
                                                        <h2>
                                                          {index === 0
                                                            ? "Friday"
                                                            : ""}
                                                        </h2>
                                                      </div>
                                                      <div className="col-lg-4 col-md-2 col-sm-2">
                                                        {index === 0 && (
                                                          <label className="toggle-switch ">
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                data?.isEnabled
                                                              }
                                                              onChange={(e) => {
                                                                setFriTime([
                                                                  {
                                                                    start_time:
                                                                      "05:00",
                                                                    end_time: "05:00",
                                                                    isEnabled:
                                                                      e.target
                                                                        .checked,
                                                                  },
                                                                ]);
                                                              }}
                                                            />
                                                            <span className="switch" />
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="col-8 col-lg-8 col-md-8">
                                                      {data?.isEnabled === true ? (
                                                        <>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={15}
                                                              format={"hh:mm a"}
                                                              id={`timepicker${index}`}
                                                              name={`start_time${index}`}
                                                              placeholder="Select Time"
                                                              value={moment(data?.start_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              min={index !== 0 ? new Date(
                                                                `${moment(
                                                                  new Date().setDate(
                                                                    new Date().getDate() -
                                                                    1
                                                                  )
                                                                ).format(
                                                                  "MM/DD/YYYY"
                                                                )} ${friTime?.[
                                                                  friTime?.length -
                                                                  1
                                                                ]?.end_time
                                                                }`
                                                              ) : new Date(moment().subtract(1, 'days').format("YYYY-MM-DD") + "T05:00:00")
                                                              }
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "fri",
                                                                  "start"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              id={`timepicker1${index}`}
                                                              name={`end_time${index}`}
                                                              value={moment(data?.end_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              placeholder="Select Time"
                                                              min={
                                                                new Date(
                                                                  `${moment(
                                                                    new Date()
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )} ${data?.start_time
                                                                  }`
                                                                )
                                                              }
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "fri",
                                                                  "end"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={30}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                      <div className="col-lg-2">
                                                        <button
                                                          className="createaddplus"
                                                          style={{
                                                            backgroundColor:
                                                              !data?.isEnabled &&
                                                              "lightgray",
                                                          }}
                                                          disabled={!data?.isEnabled}
                                                          onClick={() => {
                                                            deleteMoreTime(
                                                              "fri",
                                                              index,
                                                              data.id
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </div>
                                                      {index === 0 && (
                                                        <div
                                                          className="col-lg-2"
                                                          onClick={() => {
                                                            data?.isEnabled &&
                                                              addMoreTime("fri");
                                                          }}
                                                        >
                                                          <button
                                                            className="createaddplus"
                                                            style={{
                                                              backgroundColor:
                                                                !data?.isEnabled &&
                                                                "lightgray",
                                                            }}
                                                            disabled={
                                                              !data?.isEnabled
                                                            }
                                                          >
                                                            <i className="fa fa-plus"></i>
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );

                                            })}
                                        </li>
                                        <li>
                                          {satTime.length > 0 &&
                                            satTime.map((data, index) => {
                                              return (
                                                <>
                                                  <div className="content row">
                                                    <div className="col-4 col-lg-4 col-md-4">
                                                      <div className="col-lg-8">
                                                        <h2>
                                                          {index === 0
                                                            ? "Saturday"
                                                            : ""}
                                                        </h2>
                                                      </div>
                                                      <div className="col-lg-4 col-md-2 col-sm-2">
                                                        {index === 0 && (
                                                          <label className="toggle-switch ">
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                data?.isEnabled
                                                              }
                                                              onChange={(e) => {
                                                                setSatTime([
                                                                  {
                                                                    start_time:
                                                                      "05:00",
                                                                    end_time: "05:00",
                                                                    isEnabled:
                                                                      e.target
                                                                        .checked,
                                                                  },
                                                                ]);
                                                              }}
                                                            />
                                                            <span className="switch" />
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="col-8 col-lg-8 col-md-8">
                                                      {data?.isEnabled === true ? (
                                                        <>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={15}
                                                              format={"hh:mm a"}
                                                              id={`timepicker${index}`}
                                                              name={`start_time${index}`}
                                                              placeholder="Select Time"
                                                              value={moment(data?.start_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              min={index !== 0 ? new Date(
                                                                `${moment(
                                                                  new Date().setDate(
                                                                    new Date().getDate() -
                                                                    1
                                                                  )
                                                                ).format(
                                                                  "MM/DD/YYYY"
                                                                )} ${satTime?.[
                                                                  satTime?.length -
                                                                  1
                                                                ]?.end_time
                                                                }`
                                                              ) : new Date(moment().subtract(1, 'days').format("YYYY-MM-DD") + "T05:00:00")
                                                              }
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "sat",
                                                                  "start"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              id={`timepicker1${index}`}
                                                              name={`end_time${index}`}
                                                              placeholder="Select Time"
                                                              min={
                                                                new Date(
                                                                  `${moment(
                                                                    new Date()
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )} ${data?.start_time
                                                                  }`
                                                                )
                                                              }
                                                              value={moment(data?.end_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "sat",
                                                                  "end"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={30}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                      <div className="col-lg-2">
                                                        <button
                                                          className="createaddplus"
                                                          style={{
                                                            backgroundColor:
                                                              !data?.isEnabled &&
                                                              "lightgray",
                                                          }}
                                                          disabled={!data?.isEnabled}
                                                          onClick={() => {
                                                            deleteMoreTime(
                                                              "sat",
                                                              index,
                                                              data.id
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </div>
                                                      {index === 0 && (
                                                        <div
                                                          className="col-lg-2"
                                                          onClick={() => {
                                                            data?.isEnabled &&
                                                              addMoreTime("sat");
                                                          }}
                                                        >
                                                          <button
                                                            className="createaddplus"
                                                            style={{
                                                              backgroundColor:
                                                                !data?.isEnabled &&
                                                                "lightgray",
                                                            }}
                                                            disabled={
                                                              !data?.isEnabled
                                                            }
                                                          >
                                                            <i className="fa fa-plus"></i>
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })}
                                        </li>
                                        <li>
                                          {sunTime.length > 0 &&
                                            sunTime.map((data, index) => {
                                              return (
                                                <>
                                                  <div className="content row">
                                                    <div className="col-4 col-lg-4 col-md-4">
                                                      <div className="col-lg-8">
                                                        <h2>
                                                          {index === 0
                                                            ? "Sunday"
                                                            : ""}
                                                        </h2>
                                                      </div>
                                                      <div className="col-lg-4 col-md-2 col-sm-2">
                                                        {index === 0 && (
                                                          <label className="toggle-switch ">
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                data?.isEnabled
                                                              }
                                                              onChange={(e) => {
                                                                setSunTime([
                                                                  {
                                                                    start_time:
                                                                      "05:00",
                                                                    end_time: "05:00",
                                                                    isEnabled:
                                                                      e.target
                                                                        .checked,
                                                                  },
                                                                ]);
                                                              }}
                                                            />
                                                            <span className="switch" />
                                                          </label>
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="col-8 col-lg-8 col-md-8">
                                                      {data?.isEnabled === true ? (
                                                        <>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={15}
                                                              format={"hh:mm a"}
                                                              id={`timepicker${index}`}
                                                              name={`start_time${index}`}
                                                              placeholder="Select Time"
                                                              value={moment(data?.start_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              min={index !== 0 ? new Date(
                                                                `${moment(
                                                                  new Date().setDate(
                                                                    new Date().getDate() -
                                                                    1
                                                                  )
                                                                ).format(
                                                                  "MM/DD/YYYY"
                                                                )} ${sunTime?.[
                                                                  sunTime?.length -
                                                                  1
                                                                ]?.end_time
                                                                }`
                                                              ) : new Date(moment().subtract(1, 'days').format("YYYY-MM-DD") + "T05:00:00")
                                                              }
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "sun",
                                                                  "start"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                          <div className="col-lg-4">
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              id={`timepicker1${index}`}
                                                              name={`end_time${index}`}
                                                              placeholder="Select Time"
                                                              value={moment(data?.end_time, "h:mm:ss A").format("hh:mm A")}
                                                              allowEdit={false}
                                                              min={
                                                                new Date(
                                                                  `${moment(
                                                                    new Date()
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )} ${data?.start_time
                                                                  }`
                                                                )
                                                              }
                                                              onChange={(e) => {
                                                                handleTime(
                                                                  e,
                                                                  index,
                                                                  "sun",
                                                                  "end"
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={30}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                          <div
                                                            className="col-lg-4"
                                                            style={{
                                                              cursor: "not-allowed",
                                                              opacity: "0.5",
                                                            }}
                                                          >
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              placeholder="Select Time"
                                                              style={{
                                                                cursor: "not-allowed",
                                                                pointerEvents: "none",
                                                              }}
                                                              value={""}
                                                              allowEdit={false}
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                      <div
                                                        className="col-lg-2"
                                                      >
                                                        <button
                                                          className="createaddplus"
                                                          style={{
                                                            backgroundColor:
                                                              !data?.isEnabled &&
                                                              "lightgray",
                                                          }}
                                                          disabled={!data?.isEnabled}
                                                          onClick={() => {
                                                            deleteMoreTime(
                                                              "sun",
                                                              index,
                                                              data.id
                                                            );
                                                          }}
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      </div>
                                                      {index === 0 && (
                                                        <div
                                                          className="col-lg-2"
                                                          onClick={() => {
                                                            data?.isEnabled &&
                                                              addMoreTime("sun");
                                                          }}
                                                        >
                                                          <button
                                                            className="createaddplus"
                                                            style={{
                                                              backgroundColor:
                                                                !data?.isEnabled &&
                                                                "lightgray",
                                                            }}
                                                            disabled={
                                                              !data?.isEnabled
                                                            }
                                                          >
                                                            <i className="fa fa-plus"></i>
                                                          </button>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            })}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="clearnone"></div>
                                  <div className="row">
                                    <div className="col-lg-8">
                                      <div className="schedule-button mb-5">
                                        <button onClick={createOngoingSchdeule}>
                                          {" "}
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </> : <div className="black-filed"><strong className="text-center">Please complete the fields above to view the calendar.</strong></div>
                        }
                      </TabPanel>
                      <TabPanel>
                        {
                          (scheduleData.duration !== undefined &&
                            scheduleData.duration !== "" &&
                            scheduleData.price !== undefined &&
                            scheduleData.price !== "" &&
                            scheduleType &&
                            selectedResources !== "") ?
                            <>
                              <h4 className="my-3">Time zone: {payLoad?.replace("_", " ")}</h4>
                              <div className="col-lg-12 sche-dule">
                                <div className="row sche-dule">
                                  <div className="col-lg-8 col-md-12 col-sm-12">
                                    <div className="schedule-date-box">
                                      {
                                        show && <CalendarComponent
                                          id="calendar"
                                          min={new Date()}
                                          onChange={onDateSelect}
                                          value={dateWise}
                                          renderDayCell={disabledDate}
                                        />
                                      }
                                    </div>
                                  </div>
                                  <div className="col-lg-8 col-md-12 col-sm-12">
                                    {isInnerLoader ? (
                                      <ul>
                                        <li>
                                          <div className="">
                                            <FourDotsLoader />
                                          </div>
                                        </li>
                                      </ul>
                                    ) : (
                                      <>
                                        <div className="col-12 mt-2">
                                          <button
                                            className="createaddplus"
                                            onClick={() => addMore("addMore")}
                                          >
                                            Add Time
                                          </button>
                                        </div>
                                        {setsetselectedDateTime?.[0]?.mentoring_session_schedule_times?.length > 0 &&
                                          setsetselectedDateTime?.[0]?.mentoring_session_schedule_times?.[0]?.schedule_start_time
                                          && <div className="time-picker time-pickerscroll">
                                            {setsetselectedDateTime?.[0]
                                              ?.mentoring_session_schedule_times
                                              ?.length > 0 ? (
                                              setsetselectedDateTime?.[0]?.mentoring_session_schedule_times?.map(
                                                (v, index) => (
                                                  <>
                                                    {v?.schedule_start_time && <div className="time-pick4">
                                                      <div className="time-picker-com">
                                                        <div className="row">
                                                          <div className="col-5 col-lg-5 col-md-5 col-sm-5">
                                                            <TimePickerComponent
                                                              step={15}
                                                              format={"hh:mm a"}
                                                              id={`timepicker${index}`}
                                                              name={`start_time${index}`}
                                                              placeholder="Time"
                                                              value={moment(
                                                                v?.schedule_start_time,
                                                                "h:mm:ss A"
                                                              ).format("hh:mm A")}
                                                              min={
                                                                index === 0 ?
                                                                  new Date(
                                                                    `${moment(
                                                                      new Date()
                                                                    ).format(
                                                                      "MM/DD/YYYY"
                                                                    )} 05:00`
                                                                  )
                                                                  :
                                                                  new Date(
                                                                    `${moment(
                                                                      new Date()
                                                                    ).format(
                                                                      "MM/DD/YYYY"
                                                                    )} ${setsetselectedDateTime?.[0]
                                                                      ?.mentoring_session_schedule_times?.[
                                                                      setsetselectedDateTime?.[0]
                                                                        ?.mentoring_session_schedule_times
                                                                        ?.length - 1
                                                                    ]?.schedule_end_time
                                                                    }`
                                                                  )
                                                              }
                                                              allowEdit={false}
                                                              onChange={(e) =>
                                                                handleChange(
                                                                  e,
                                                                  index,
                                                                  "start"
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-5 col-lg-5 col-md-5 col-sm-5">
                                                            <TimePickerComponent
                                                              step={scheduleData.duration ? scheduleData.duration : 60}
                                                              format={"hh:mm a"}
                                                              id={`timepicker1${index}`}
                                                              name={`end_time1${index}`}
                                                              placeholder="Time"
                                                              value={moment(
                                                                v?.schedule_end_time,
                                                                "h:mm:ss a"
                                                              ).format("hh:mm A")}
                                                              min={
                                                                new Date(
                                                                  `${moment(
                                                                    new Date()
                                                                  ).format(
                                                                    "MM/DD/YYYY"
                                                                  )} ${v?.schedule_start_time
                                                                  }`
                                                                )
                                                              }
                                                              allowEdit={false}
                                                              onChange={(e) =>
                                                                handleChange(
                                                                  e,
                                                                  index,
                                                                  "end"
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-2 col-lg-2 col-md-2 col-sm-2 ">
                                                            <button
                                                              className="createaddplus"
                                                              onClick={() =>
                                                                deleteMore(index, v)
                                                              }
                                                            >
                                                              <i className="fa fa-trash"></i>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>}
                                                  </>
                                                )
                                              )
                                            ) : (
                                              <>
                                              </>
                                            )}
                                          </div>}
                                      </>
                                    )}

                                    {setsetselectedDateTime?.[0]?.mentoring_session_schedule_times?.length > 0 && <div className="schedule-button-save">
                                      <button onClick={handleavailability}>
                                        {" "}
                                        Save Schedule
                                      </button>
                                    </div>}
                                  </div>
                                </div>
                              </div></> : <div className="black-filed"><strong className="text-center">Please complete the fields above to view the calendar.</strong></div>
                        }
                      </TabPanel>
                      <TabPanel>
                        <div className="col-lg-12 sche-dules mb-5">
                          <div className="row sche-dule">
                            <div className="col-12 col-lg-8 col-md-12 col-sm-12">
                              <div className="schedule-date-box">
                                {
                                  show && <CalendarComponent
                                    id="calendar"
                                    min={new Date()}
                                    onChange={handle3Tab}
                                    renderDayCell={disabledDate2}
                                    value={tab3}
                                  />
                                }
                              </div>
                            </div>
                            <div className="col-12 col-lg-4 col-md-6 col-sm-12">
                              <div className="create-side-box">
                                {isInnerLoader ? (
                                  <ul>
                                    <li>
                                      <div className="">
                                        <FourDotsLoader />
                                      </div>
                                    </li>
                                  </ul>
                                ) : tab3Data?.length > 0 ? tab3Data?.map(v => (<>
                                  <div className="myschedulerightBox">
                                    <div className="myshedule-content-box-info" >
                                      <h6>{v?.tbl_user_mentoring_session_schedule_times.length > 0 && v?.tbl_user_mentoring_session_schedule?.tbl_user_resource?.resource_title}</h6>
                                    </div>
                                    {v?.tbl_user_mentoring_session_schedule_times?.map((g, index) => (<>
                                      <div className="create-side-text">
                                        <span>{moment(g?.schedule_start_time, "h:mm:ss A").format("hh:mm A")}</span> {/* {" - "} */}
                                        <span>{moment(g?.schedule_end_time, "h:mm:ss A").format("hh:mm A")}</span>
                                        {
                                          g.bookedStatus ? <button onClick={() => navigate(`/sale-history?ref=session&resource=${v?.tbl_user_mentoring_session_schedule?.resource_id}&date=${moment(tab3).format("YYYY-MM-DD")}`)}><i className="fa fa-eye"></i></button> :
                                            <button disabled><i className="fa fa-eye-slash"></i></button>
                                        }
                                        <button onClick={() => { setIpopup(true); setDetail(v) }}><i className="fa fa-info-circles">i</i></button>
                                        <button onClick={() => { deleteMore(index, g); setDetail(v) }}><i className="fa fa-trash"></i></button>
                                      </div></>))
                                    }
                                  </div>
                                </>)) :
                                  <div className="text-center">
                                    <p>No Data Found</p></div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </>
            ) : (
              <h1
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                Please add resources to set your schedule!
              </h1>
            )}
          </div>
        </div>
      </div>
      {iPopup &&
        <div className="sharepopupOuter backgroundOpacity">
          <div className="pop-up-container">
            <button className="close-btn" onClick={() => setIpopup(false)}>
              X
            </button>
            <h2 style={{ textAlign: "center", fontSize: "30px" }}>Schedule Information</h2>
            <div className="bookmoniterdivbox" style={{ float: "none" }}>
              <ul>
                <li>
                  <div className="sprash-name-rt" style={{ width: "100%" }}>
                    <div className="sprash-name-box">
                      <h4> Schedule Type
                      </h4>
                      <p> {detail?.tbl_user_mentoring_session_schedule?.schedule_type === "1" ? "Group Session" : "Individual Session"} </p>
                    </div>
                    <div className="sprash-name-box">
                      <h4> Time Slot
                      </h4>
                      <p> {detail?.tbl_user_mentoring_session_schedule?.duration} minutes </p>
                    </div>
                    <div className="sprash-name-box">
                      <h4> Amount
                      </h4>
                      <p> ${detail?.tbl_user_mentoring_session_schedule?.price?.toFixed(2)} </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {deletedData?.bookedStatus === true && bookStatus === true ? (
            <>
              <Typography
                id="modal-modal-title"
                variant="h2"
                component="h2"
                className="text-left mb-3"
                style={{ color: "#2D3134", fontSize: "24px", fontWeight: "bold" }}
              >
                Cancel Schedule
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                className="text-left"
                style={{ color: "#2D3134", fontSize: "16px" }}
              >
                Reason :
              </Typography>
              <input
                value={removeReason}
                className="reason"
                onChange={(e) => setRemoveReason(e.target.value)}
              />
              <Typography
                id="modal-modal-description"
                sx={{ mt: 3 }}
                className="d-flex justify-content-center gap-4"
              >
                <button className="child-delete" onClick={handleDelete}>Remove </button>
                <button className="child-cancel" onClick={() => {
                  setOpen(false); setRemoveIndex(""); setShowButton(true);
                  setBookStatus(false)
                }}>
                  Cancel
                </button>

              </Typography>
            </>
          ) : (
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
              style={{ color: "#2D3134", fontSize: "23px", lineHeight: "36px" }}
            >
              { deletedData?.bookedStatus === "addMore" || deletedData?.bookedStatus === false ?  
              " Are you sure you want to remove this session? " :
              " Are you sure you want to remove this scheduled session?" 
              }
            </Typography>
          )}
          {showButton === true ?
            <Typography
              id="modal-modal-description"
              sx={{ mt: 3 }}
              className="d-flex justify-content-center gap-4"
            >
              {deletedData?.bookedStatus === false ?
                <button className="child-delete" onClick={handleDelete}>
                  Yes
                </button> : deletedData?.bookedStatus === true ? <button className="child-delete" onClick={handleDelete2}>
                  Yes
                </button> : <button className="child-delete" onClick={handleDelete3}>
                  Yes
                </button> 
              }
              <button className="child-cancel" onClick={() => { setOpen(false); setRemoveIndex(""); setShowButton(true); }}>
                No
              </button>{" "}
            </Typography>
            : ""}
        </Box>
      </Modal>
      <Modal
        open={popupDurationOpen}
        onClose={() => setPopupDurationOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "20px", lineHeight: "36px" }}
          >
            If you change the duration time, then it will remove your selected time accordingly.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={clearTime} >
              Yes
            </button>{" "}
            <button className="child-cancel" onClick={() => { setPopupDurationOpen(false); setPopupDuration(false) }} >
              No
            </button>{" "}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export default Createschedule;
