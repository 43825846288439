import React from "react";
import "./fullDescription.css";
import "../Search.WebAppMate/rating.css";
import { useState, useEffect, useRef } from "react";
import Hearticon from "../../assets/Images/Hearticon.png";
import Mindicon from "../../assets/Images/Mindicon.png";
import Sounicon from "../../assets/Images/Sounicon.png";
import victor10 from "../../assets/Images/Vector10.png";
import soul from "../../assets/Images/la_praying-hands.png";
import strength from "../../assets/Images/icon-park-outline_muscle.png";
import icon from "../../assets/Images/carbon_idea.png";
import Strengthicon from "../../assets/Images/Strengthicon.png";
import { useNavigate, useParams } from "react-router-dom";
import Trigonometry from "../../assets/Images/Vectorman-old.png";
import Reportpopup from "../Search.WebAppMate/Reportpopup";
import {
  makeGetRequest,
  makePostRequest,
  makePostRequestForAll,
} from "../../services/api";
import { URLS } from "../../constants";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import Rating from "@mui/material/Rating";
import Saveresourcespopup from "../Search.WebAppMate/Saveresourcespopup";
import {
  decodeToken,
  handleFileDownload,
  toastifyMessage,
} from "../../utilities/CustomFunctions";
import DeleteRecourse from "./DeleteRecourse";
import PdfViewer from "./PdfViewer";
import ShareRecoursePopUp from "./ShareRecoursePopUp";
import { renderRatingStars } from "../Search.WebAppMate/RenderCardInfo";
import FourDotsLoader from "./FourDoted";
import moment from "moment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import LinkIcon from "@mui/icons-material/Link";
import BookaMentorpopup from "../Search.WebAppMate/BookaMentorpopup";
import Head from "../Layout.WebAppMate/head/Head";
import { Multiselect } from "multiselect-react-dropdown";
import SingleChat from "../Chat.WebAppMate/SingleChat";
import EnrollPopup from "./EnrollPopup";
import { renderDescription } from "../../services/common.services";
import { getOpenGlobalChat } from '../../store/reducers/userReducer';
export const imageUrl = process.env.REACT_APP_IMAGE_URL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 6,
};
/**
 * create components is used for full description page
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const FullDescription = () => {
  const payLoad = decodeToken().userAuth.id;
  const dispatch = useDispatch()
  // const location = useLocation();
  const userData = decodeToken().userAuth;
  const userStore = useSelector((store) => store.user);
  const navigate = useNavigate();
  const { userChildrens } = userStore;
  const childOwner1 = userChildrens?.filter((obj) => obj?.childOwner === 1);
  const param = useParams();
  const [resourceDetails, setResourceDetails] = useState(null);
  const [resourceReviews, setResourceReviews] = useState(null);
  const [resourceContent, setResourceContent] = useState(null);
  const [resourceLink, setResourceLink] = useState(null);
  const [imgVisible, setImgVisible] = useState(false);
  const [show, setShow] = useState({ status: false, text: "" });
  const [error, setError] = useState({
    parent: "",
    comment: "",
  });
  const [openBox, setOpenBox] = useState(false);
  const [showStates, setShowStates] = useState(false);
  const [parentRating, setParentRating] = useState(0);
  const [value, setValue] = useState(false);
  const [childsRating, setChildsRating] = useState([]);
  const [numToShow, setNumToShow] = useState(2);
  const [numToShowReview, setNumToShowReview] = useState(2);
  const [numToShowReviewTest, setNumToShowReviewTest] = useState(2);
  const [spin, setspin] = useState(false);
  const [childRatingChekbox, setChildRatingChekbox] = useState([]);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [resourceReviewType, setResourceReviewType] = useState("user");
  const [gradeRange, setGradeRange] = useState("");
  const [content, setContent] = useState("");
  const [keywords, setKeywords] = useState("");
  const [open, setOpen] = useState(false);
  const [enrollOpen, setEnrollOpen] = useState(false);
  const rankFocus = useRef();
  const commentFocus = useRef();
  const ratingFocus = useRef();
  const [resourceGroupStatus, setResourceGroupStatus] = useState({
    mode: "",
    group_id: "",
    res_groupStatus: "",
  });
  const [userReview, setUserReview] = useState({
    parentRating: null,
    parentComment: "",
    childReviews: [],
  });
  const [reportPopupStatus, setReportPopupStatus] = useState({
    visible: false,
    objectID: null,
  });
  const [savePopupStatus, setSavePopupStatus] = useState({
    visible: false,
    selectedResource: null,
  });
  const [bookaMentorPopupStatus, setBookaMentorPopupStatus] = useState({
    visible: false,
    selectedResource: null,
  });
  const [sharePopupStatus, setSharePopupStatus] = useState({
    visible: false,
    objectID: null,
  });
  const [contentPreview, setContentPreview] = useState({
    imgurl: "",
    imgtype: "",
  });
  const [isVisible, setVisible] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [experience, setExperience] = useState("");
  const [facilities, setFacilities] = useState("");
  const [industryStyle, setIndustryStyle] = useState("");
  const [locationType, setLocationType] = useState("");
  const [funding, setFunding] = useState("");
  const [organization, setOrganization] = useState("");
  const [support, setSupport] = useState("");
  const [googleBook, setGoogleBook] = useState("");
  const [openLink, setOpenLink] = useState(false);
  const [saveLink, setSaveLink] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const [learningPillarData, setLearningPillarData] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(true);
  const [urlStatus, setUrlStatus] = useState(false);
  const scrollRef = useRef(null);

  const userOptions = [
    { value: "user", name: "User" },
    { value: "friend", name: "Friends" },
  ];
  const handleShowLink = (url) => {
    setOpenLink(false);
    window.open(url, "_blank");
  };
  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  // this APi use for get learning pillar
  const getAllLearningPillar = () => {
    makeGetRequest(URLS.getAllLearningPillar, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          let options = [];
          res.data.length > 0 &&
            res.data.forEach((val, i) => {
              options.push({ id: val.id, name: val.name });
            });

          setLearningPillarData(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (isMounted && param?.id) {
      getResourceDetails();
      setTimeout(() => {
        getAllLearningPillar();
        getResourceContent();
        totalView();
        getMentoring();
      }, 900);
    }
    // eslint-disable-next-line
  }, [isMounted]);

  const rated = () => {
    if (parentRating === 0 || parentRating === null) {
      setIsParentChecked(false);
      setError({ parent: false });
    } else {
      setIsParentChecked(true);
    }
  };
  useEffect(() => {
    rated();
    // eslint-disable-next-line
  }, [parentRating]);

  // this function use for chat
  const AddUser = (id, userData) => {
    dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false }))
    makePostRequest(URLS.getMoreInfo, null, { resource_id: param.id }, null)
      .then((res) => { })
      .catch((error) => { });
    makePostRequestForAll(URLS.addChatUser, { chat_with: id })
      .then((res) => {
        if (res.code === 200) {
          dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: 0, pageName: "description", 
            chat_by_block : res?.data?.block
           }))
      
        }
      })
      .catch((e) => {
        console.log("error:::::", e);
      });
  };

  // this function use for get child rate
  const childrated = () => {
    let shareResArr = [];
    childsRating.forEach((val, i) => {
      if (val.rating !== 0 && childRatingChekbox.length !== 0) {
        shareResArr.push(val.child_id);
      }
      setChildRatingChekbox(shareResArr);
    });
  };
  useEffect(() => {
    childrated();
    // eslint-disable-next-line
  }, [childsRating]);

  // this API call for totalview
  const totalView = () => {
    makePostRequest(URLS.totalViewResource, null, { id: param?.id }, null)
      .then((res) => { })
      .catch((error) => {
        console.log("error::", error);
      });
  };

  useEffect(() => {
    let arr = [];
    for (var i = 0; i < childOwner1?.length; i++) {
      let childObj = { child_id: "", rating: 0 };
      arr.push(childObj);
    }
    if (resourceReviewType === "user") {
      setChildsRating(arr);
    }
    // eslint-disable-next-line
  }, [userChildrens, resourceReviewType]);

  useEffect(() => {
    setTimeout(() => {
      isMounted && userChildrens && getResourceReviews();
    }, 500);
    // eslint-disable-next-line
  }, [resourceReviewType, isMounted, userChildrens]);

  // this function is use for scrolldown
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  const handleParentComment = (e) => {
    let str = e.target.value;
    let obj = { ...userReview };
    obj.parentComment = str;
    setUserReview({ ...obj });
  };

  // this function is use for hanlde review
  const submitReviewHandler = (e) => {
    e.preventDefault();
    const errors = {};
    const isValidParent = parentRating && parentRating !== 0;
  const hasValidChildRatings =
    childsRating?.length > 0 &&
    childsRating.some((child) => child.rating && child.rating !== 0);
    if (!isValidParent && !hasValidChildRatings) {
      ratingFocus.current.focus();
      errors.rating = "At least one rating (parent or student) is required.";
    }
  
    if (userReview.parentComment === "") {
      commentFocus.current.focus();
    }
    // If errors exist, set them and return early
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }

    if (value) {
      value && setOpenBox(true);
    } else {
      addRating();
    }
  };

  // this function use for add rating
  const addRating = (e) => {
    setOpenBox(false);
    if (!userReview.parentRating === null || !userReview.parentRating === 0) {
      setError({ parent: true });
    }
    else if(!userReview.parentComment){
      setError({ comment: true });
    }
     else {
      setspin(true);
      let payload = {
        resource_id: param.id,
        user_id: payLoad,
        rating: parentRating,
        message: userReview.parentComment,
        child_review: childsRating.filter((item) => item.child_id !== ""),
      };
      makePostRequest(URLS.addResourceReview, null, payload, null).then(
        (res) => {
          setspin(false);
          if (res.code === 201) {
            getResourceReviews();
            getResourceDetails();
            toastifyMessage(res.message, "success");
            setspin(false);
          } else {
            setspin(false);
            toastifyMessage(res.message, "error");
          }
        }
      );
    }
  };

  // this function use for get content
  const getResourceContent = () => {
    let data = {
      resource_id: param.id,
    };
    makePostRequest(URLS.getSaveResourceContent, null, data, null).then(
      (res) => {
        let resourceData = res.data;
        setResourceContent(resourceData);
        resourceData.forEach((val, i) => {
          if (val.content_thumbnail === "yes") {
            setThumbnail(val.content);
          }
        });
      }
    );
  };

  // this function is use for reviews
  const getResourceReviews = () => {
    let data = {
      resource_id: param.id,
      user_id: payLoad,
      type: resourceReviewType,
    };
    makePostRequest(URLS.getResourceReviews, null, data, null)
      .then((res) => {
        console.log("res::----:", res);
        let resourceData = res?.data;
        setResourceReviews(resourceData);
        res?.data?.reviewsList?.forEach((val) => {
          if (val?.user_id === payLoad && resourceReviewType === "user") {
            setParentRating(val?.rating);
            setIsParentChecked(val?.rating && true);
            setValue(val?.rating && true);
            setUserReview({ parentComment: val?.message });
            let arr = [];
            for (var i = 0; i < childOwner1?.length; i++) {
              let childObj = { child_id: "", rating: 0 };
              arr.push(childObj);
            }
            let checkboxIdArr = [];
            childOwner1?.length > 0 &&
              childOwner1?.forEach((itm, index) => {
                val?.tbl_resource_child_reviews?.length > 0 &&
                  val.tbl_resource_child_reviews.forEach((val, i) => {
                    if (itm?.id === val?.child_id) {
                      const newArray = Array.from(arr);
                      newArray[index].rating = val?.rating;
                      newArray[index].child_id = val?.child_id;
                      setChildsRating(newArray);
                      checkboxIdArr.push(val.child_id);
                    }
                  });
              });
            setChildRatingChekbox(checkboxIdArr);
          }
        });
      })
      .catch((error) => {
        console.log("error:::", error);
      });
  };

  // get Resource Detail by id api calling
  const getResourceDetails = () => {
    setspin(true);
    makePostRequestForAll(URLS.getOneResourceDetails, { id: param.id }).then(
      (res) => {
        console.log("getSaveResourceById:::::", res);
        if (res.code === 200) {
          let resourceData = res.data;
          checkURLExists(resourceData.resource_url);
          const googleBook =
            resourceData && JSON?.parse(res?.data?.googleBookData);
          setGoogleBook(googleBook);
          setResourceDetails(resourceData);
          setResourceLink(resourceData.tbl_resource_urls);
          let grades = "";
          let keywords = "";
          resourceData?.algolia[0]?.grade?.forEach((element, index) => {
            grades += element;
            if (index < resourceData?.algolia[0]?.grade?.length - 1) {
              grades += ", ";
            }
          });
          resourceData?.algolia[0]?.keywords?.forEach((element, index) => {
            keywords += element;
            if (index < resourceData?.algolia[0]?.keywords?.length - 1) {
              keywords += ", ";
            }
          });
          if (resourceData?.resource_type === 3) {
            let Exper_ience = "";
            resourceData?.algolia[0]?.experience_type?.forEach(
              (element, index) => {
                Exper_ience += element;
                if (
                  index <
                  resourceData?.algolia[0]?.experience_type?.length - 1
                ) {
                  Exper_ience += ", ";
                }
              }
            );
            setExperience(Exper_ience);
          }
          if (resourceData?.resource_type === 7) {
            let facil_ities = "";
            resourceData?.algolia[0]?.facility_type?.forEach(
              (element, index) => {
                facil_ities += element;
                if (
                  index <
                  resourceData?.algolia[0]?.facility_type?.length - 1
                ) {
                  facil_ities += ", ";
                }
              }
            );
            setFacilities(facil_ities);
          }
          if (resourceData?.resource_type === 6) {
            let fund_ing = "";
            resourceData?.algolia[0]?.funding_type?.forEach(
              (element, index) => {
                fund_ing += element;
                if (
                  index <
                  resourceData?.algolia[0]?.funding_type?.length - 1
                ) {
                  fund_ing += ", ";
                }
              }
            );
            setFunding(fund_ing);
          }
          if (resourceData?.resource_type === 2) {
            let content = "";
            let style = "";
            resourceData?.algolia[0]?.content_type?.forEach(
              (element, index) => {
                content += element;
                if (
                  index <
                  resourceData?.algolia[0]?.content_type?.length - 1
                ) {
                  content += ", ";
                }
              }
            );
            resourceData?.algolia[0]?.instructional_style?.forEach(
              (element, index) => {
                style += element + " ";
                if (
                  index <
                  resourceData?.algolia[0]?.instructional_style?.length - 1
                ) {
                  style += ", ";
                }
              }
            );
            setContent(content);
            setIndustryStyle(style);
          }
          if (resourceData?.resource_type === 1) {
            let organiz_ation = "";
            resourceData?.algolia[0]?.organisation_type?.forEach(
              (element, index) => {
                organiz_ation += element + " ";
                if (
                  index <
                  resourceData?.algolia[0]?.organisation_type?.length - 1
                ) {
                  organiz_ation += ", ";
                }
              }
            );
            setOrganization(organiz_ation);
          }
          if (
            resourceData?.resource_type === 4 ||
            resourceData?.resource_type === 5
          ) {
            let Suppo_rt = "";
            resourceData?.algolia[0]?.support_type?.forEach(
              (element, index) => {
                Suppo_rt += element + " ";
                if (
                  index <
                  resourceData?.algolia[0]?.support_type?.length - 1
                ) {
                  Suppo_rt += ", ";
                }
              }
            );
            setSupport(Suppo_rt);
          }
          let location = "";
          resourceData?.algolia[0]?.location_type?.forEach((element, index) => {
            location += element + " ";
            if (index < resourceData?.algolia[0]?.location_type?.length - 1) {
              location += ", ";
            }
          });
          setResourceGroupStatus({
            mode: res.data?.resource_mode,
            group_id: res.data?.group_resource_id,
            res_groupStatus: res.data?.resource_type_status,
          });
          setLocationType(location);
          setGradeRange(grades);
          setKeywords(keywords);
          setspin(false);
        } else {
          setShow({ status: true, text: res.message });
        }
      }
    );
  };

  const bottomRef = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const passKey = urlParams.get("passKey");

  useEffect(() => {
    if (passKey === "pass") scrollToBottom();
  });

  // this function use for check Mentoring session data
  const getMentoring = () => {
    makePostRequest(URLS.getOneResourceMentoring, null, { id: param.id }, null)
      .then((res) => {
        setSessionData(res.data);
      })
      .catch((error) => {
        console.log("error::", error);
      });
  };

  useEffect(() => {
    document.body.classList.add("fulldescription");
    return () => {
      document.body.classList.remove("fulldescription");
    };
  }, []);

  // this functions are use for rating
  const handleChildRatings = (event, newValue, index, id) => {
    if (childsRating && childsRating?.length > 0) {
      childsRating.forEach((val, i) => {
        if (val.child_id === id || (i === index && val.child_id !== id)) {
          const newArray = Array.from(childsRating);
          newArray[i] = {
            ...val,
            rating: newValue,
            child_id: val.child_id === id ? "" : id,
          };
          setChildsRating(newArray);
        }
      });
    } else {
      let arr = [];
      for (let i = 0; i < childOwner1?.length; i++) {
        if (i === index) {
          let childObj = { child_id: id, rating: newValue };
          arr.push(childObj);
        } else {
          let childObj = { child_id: "", rating: 0 };
          arr.push(childObj);
        }
      }
      setChildsRating(arr);
    }
  };
  const handleChildRatingsCheckboxes = (e) => {
    if (e.target.checked === true) {
      setChildRatingChekbox([...childRatingChekbox, e.target.value]);
    } else if (e.target.checked === false) {
      let freshArray = childRatingChekbox.filter(
        (val) => val !== e.target.value
      );
      setChildRatingChekbox([...freshArray]);
      childsRating?.length > 0 &&
        childsRating.forEach((val, i) => {
          if (val.child_id === parseInt(e.target.value)) {
            const newArray = Array.from(childsRating);
            newArray[i].rating = 0;
            newArray[i].child_id = "";
            setChildsRating(newArray);
          }
        });
    }
  };
  const handleParentCheckbox = (e) => {
    setError({ parent: false });
    setIsParentChecked(!isParentChecked);
    if (e.target.checked === false) {
      setParentRating(0);
      // setChildsRating([]);
      // setChildRatingChekbox([]);
    }else{
      setParentRating(5);
    }
  };
  // end--------
  // this function is use for open report pop
  const handleResourceReportOpen = (data) => {
    setReportPopupStatus({
      visible: true,
      objectID: data.id,
    });
  };
  // this function is use for open savepop
  const handleResourceSaveOpen = (data) => {
    setSavePopupStatus({
      visible: true,
      selectedResource: data,
    });
  };
  const handleBookaMentorOpen = (data, flag) => {
    setBookaMentorPopupStatus({
      visible: true,
      selectedResource: { data: data },
      flag: flag,
    });
  };
  const handleResourceShareOpen = (data) => {
    setSharePopupStatus({
      visible: true,
      objectID: data.id,
    });
  };
  //show Image/video content  preview on click of preview button
  const showContentPreview = (e, imgurl, imgname) => {
    e.preventDefault();
    // let imgType = imgname?.split(".");
    setContentPreview({
      ...contentPreview,
      imgurl: imgurl,
      imgtype: imgname,
    });
    setVisible(true);
  };
  const handleSchedule = (e) => {
    e.preventDefault();
    let data = {
      id: resourceDetails?.id,
    };
    makePostRequest(URLS.addEvent, null, data, null).then((res) => {
      setScheduleOpen(false);
      navigate(`/save-page/${res?.data?.id}?form=details`);
    });
  };

  const renderValue = (key, value) => {
    if (Array.isArray(value)) {
      return <div></div>;
    } else if (typeof value === "object") {
      return <div></div>;
    } else {
      return (
        <p>
          <strong>{key}:</strong> {value}
        </p>
      );
    }
  };

  const handleImageError = () => {
    setImageLoaded(false);
  };

  function checkURLExists(url) {
    console.log("Url::", url);
    try {
      return fetch(url)
        .then((response) => {
          console.log("response", response, response.ok);
          setUrlStatus(true);
          return response.ok;
        })
        .catch((error) => {
          setUrlStatus(false);
          console.error("Error checking URL:", error);
          return false;
        });
    } catch (error) {
      setUrlStatus(false);
      console.log("error::", error);
    }
  }
  // useEffect(() => {
  //   // This effect runs whenever location (URL) changes so chat window not open again when you come back on this page again
  //   dispatch(getOpenGlobalChat(
  //     {chatOpen : false, userDetail : "", userMapId: "", senderId: "", group: "", pageName : "" })) // Reset or clear the userstore data here
  // }, [location.pathname]);
  return (
    <>
      {spin && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
         <Head
        title="Resource Detail"
        content="Explore a wealth of educational resources on MatchED, from printed and digital books to robust curricula, online learning platforms, and supportive communities. Find schools, clubs, tutors, coaches, and parent/teacher support to fuel your learning journey. Discover the keys to knowledge and growth with our diverse range of educational resources."
        name="Resource Detail" ></Head>
      {enrollOpen && (
        <EnrollPopup close={() => setEnrollOpen(false)} id={param.id} />
      )}
      {reportPopupStatus.objectID && reportPopupStatus.visible ? (
        <Reportpopup
          data={reportPopupStatus}
          handleResourceReportOpen={handleResourceReportOpen}
          handleResourceReportClose={() =>
            setReportPopupStatus({ visible: false, objectID: null })
          }
        />
      ) : savePopupStatus.selectedResource && savePopupStatus.visible ? (
        <Saveresourcespopup
          data={savePopupStatus}
          handleResourceReportOpen={handleResourceReportOpen}
          handleResourceSaveClose={() =>
            setSavePopupStatus({ visible: false, selectedResource: null })
          }
        />
      ) : bookaMentorPopupStatus.selectedResource &&
        bookaMentorPopupStatus.visible ? (
        <BookaMentorpopup
          data={bookaMentorPopupStatus}
          handleResourceReportOpen={handleResourceReportOpen}
          handleResourceSaveClose={() =>
            setBookaMentorPopupStatus({
              visible: false,
              selectedResource: null,
            })
          }
        />
      ) : sharePopupStatus.objectID && sharePopupStatus.visible ? (
        <ShareRecoursePopUp
          data={sharePopupStatus}
          handleResourceReportOpen={handleResourceShareOpen}
          handleResourceShareClose={() =>
            setSharePopupStatus({ visible: false, selectedResource: null })
          }
        />
      ) : (
        <>
          {resourceDetails && (
            <div className="col-lg-10">
              <div className="fulldecartion-div">
                <div className="fulldecartion-div-lt">
                  <h1 className="text_capital">
                    {" "}
                    {resourceDetails.resource_title}{" "}
                  </h1>
                  <div className="resourceabouttextdatainfo square scrollbar-dusty-grass square thin">
                    {renderDescription(resourceDetails?.description)}
                  </div>
                  <div></div>
                  {resourceDetails?.define_address && (
                    <p>
                      <b>Address:</b> {resourceDetails?.define_address}
                    </p>
                  )}
                  <RenderLearningPillars
                    resourceDetails={resourceDetails}
                    pillar={learningPillarData}
                  />
                  {resourceReviews?.friend?.length > 0 && (
                    <p className="text_capital">
                      {resourceReviews &&
                        resourceReviews?.friend[0]?.tbl_user?.firstName +
                        " " +
                        resourceReviews?.friend[0]?.tbl_user?.lastName +
                        " "}{" "}
                      {resourceReviews?.totalFriend > 1 && (
                        <>
                          and{" "}
                          <span className="frients">
                            {resourceReviews?.totalFriend - 1} other friends
                          </span>
                        </>
                      )}{" "}
                      reviewed this resource.
                    </p>
                  )}
                  <div
                    className="ratesiconding"
                    onClick={() => {
                      scrollToBottom();
                    }}
                  >
                    <p>
                      {resourceReviews?.total_review !== 0
                        ? Number(resourceReviews?.total_review).toFixed(1)
                        : "0.0"}{" "}
                      {renderRatingStars(resourceReviews?.total_review)}
                      <span> ({resourceDetails.total_reviews})</span>
                      <span> See all reviews</span>
                    </p>
                  </div>
                  <div className="fundingcsspublic">
                    {resourceDetails?.public_funding === "yes" && (
                      <button type="button" className="publicfunding">
                        <span>
                          <i className="fa fa-check"></i>
                        </span>
                        eligible for public funding
                      </button>
                    )}
                    {resourceDetails?.algolia[0]?.resource_funding_states?.length >
                      0 && (
                        <a
                          href="#!"
                          className="approvedcss"
                          onClick={() => setShowStates(!showStates)}
                        >
                          <span>
                            <i
                              className="fa fa-exclamation"
                              aria-hidden="true"
                            ></i>
                          </span>
                          Approved states
                        </a>
                       )}
                    {showStates && (
                      <div id="itemListstyle" className="aprovStateList">
                        <ul>
                          {resourceDetails?.algolia[0]
                            ?.resource_funding_states &&
                            resourceDetails?.algolia[0]?.resource_funding_states?.length >
                            0 &&
                            resourceDetails?.algolia[0]?.resource_funding_states.map(
                              (state, index) => {
                                return (
                                  <li key={index}>
                                    <p>{state}</p>
                                  </li>
                                );
                              }
                            )}
                        </ul>
                      </div>
                    )}
                  </div>
                  {resourceDetails?.purchase_price > 0 && (
                    <p>
                      <b>Price:</b> $
                      {resourceDetails?.purchase_price?.toFixed(2)}{" "}
                    </p>
                  )}
                  <div className="full-desupperset">
                    {Array.isArray(resourceContent) &&
                      resourceContent?.length > 0 && (
                        <h2 className="mb-3">Content </h2>
                      )}
                    <div className="linkIconOuter">
                      {resourceContent?.map((content, index) => {
                        const isDeleted = content.status === "deleted";
                        const isImage = [
                          ".png",
                          "png",
                          ".jpg",
                          "jpg",
                          ".jpeg",
                          "jpeg",
                        ].includes(content.file_type);
                        const isPdf = [".pdf", "pdf"].includes(
                          content.file_type
                        );
                        const isVideo = [
                          ".mp4",
                          "mp4",
                          ".mpeg",
                          "mpeg",
                          ".mov",
                          "mov",
                        ].includes(content.file_type);
                        const isAudio = [".mp3", "mp3"].includes(
                          content.file_type
                        );
                        const fileName = content?.file_path?.split("/")?.pop();
                        const renderContent = () => {
                          if (isImage)
                            return (
                              <img
                                src={content.content}
                                className="image_pre"
                                alt="upload"
                                loading="lazy"
                              />
                            );
                          if (isPdf) return <PictureAsPdfIcon />;
                          if (isVideo)
                            return (
                              <video width="6%" height="25">
                                <source
                                  src={content.content}
                                  type={`video/${content.file_type.replace(
                                    ".",
                                    ""
                                  )}`}
                                />
                              </video>
                            );
                          if (isAudio) return <AudioFileIcon />;
                        };
                        const handlePreviewClick = (e) => {
                          showContentPreview(
                            e,
                            content.content,
                            content.file_type
                          );
                          window.scrollTo({ bottom: 0, behavior: "smooth" });
                          // document.body.classList.toggle(
                          //   "removescroll",
                          //   document.body.classList.contains("fulldescription")
                          //);
                        };
                        return (
                          (content.content_thumbnail === "no" && !isDeleted) && (
                            <div className="examplecontent" key={index}>
                              <div className="row">
                                <div className="col-lg-10 examplemoblits">
                                  <div className="examplecontentinner">
                                    <h2>
                                      <span>
                                        <span className="px-2 deletespanvid">
                                          {renderContent()}
                                        </span>
                                        {fileName}
                                        <div className="buttonprevDeletnew">
                                          <button
                                            className="preview"
                                            onClick={handlePreviewClick}
                                          >
                                            Preview
                                          </button>
                                          <button
                                            className="download"
                                            onClick={() => handleFileDownload(content.content, fileName, setspin)}
                                          >
                                            Download
                                          </button>
                                        </div>
                                      </span>
                                    </h2>
                                  </div>
                                </div>
                                <div className="col-lg-2 examplemoblits">
                                  <div className="examplecontentinner mt-2">
                                    <div className="buttonprevdown">
                                      {isDeleted && <h4>Deleted</h4>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                  <div className="linkIconOuter">
                    {resourceLink?.map((val, index) => {
                      return (
                        <>
                          <div className=" examplecontent" key={index}>
                            <div className="d-flex gap-4 py-2">
                              <span className="">
                                <LinkIcon />
                              </span>
                              <p
                                style={{ cursor: "pointer", margin: "0" }}
                                onClick={() => {
                                  setOpenLink(true);
                                  setSaveLink(val?.link);
                                }}
                              >
                                {val.link.substring(0, 93)}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  {!googleBook && resourceDetails?.isbn && (
                    <p>
                      <strong>ISBN:</strong> {resourceDetails?.isbn}
                    </p>
                  )}
                  {googleBook &&
                    resourceDetails.is_third_party === "googlebook" && (
                      <div className="gooogle-book mt-4">
                        <div className="">
                          <h1>Book Information</h1>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            {resourceDetails?.isbn ? (
                              <p>
                                <strong>ISBN:</strong> {resourceDetails?.isbn}
                              </p>
                            ) : (
                              <>
                                {googleBook?.industryIdentifiers?.[0]
                                  ?.identifier && (
                                    <p>
                                      <strong>ISBN:</strong>{" "}
                                      {
                                        googleBook?.industryIdentifiers[0]
                                          .identifier
                                      }
                                    </p>
                                  )}
                              </>
                            )}
                            {googleBook?.publishedDate && (
                              <p>
                                <strong>Published:</strong>{" "}
                                {googleBook?.publishedDate}
                              </p>
                            )}
                            {(googleBook?.publisher || googleBook?.brand) && (
                              <p>
                                <strong>Publisher:</strong>{" "}
                                {googleBook?.publisher || googleBook?.brand}
                              </p>
                            )}
                            {googleBook?.authors > 0 && (
                              <p>
                                <strong>Author:</strong>{" "}
                                {googleBook?.authors.join(", ")}
                              </p>
                            )}
                          </div>
                          <div className="col-6">
                            {googleBook?.pageCount && (
                              <p>
                                <strong>Page count:</strong>{" "}
                                {googleBook?.pageCount}
                              </p>
                            )}
                            {googleBook?.language && (
                              <p>
                                <strong>Language:</strong>{" "}
                                {googleBook?.language}
                              </p>
                            )}
                            {googleBook?.printType && (
                              <p>
                                <strong>Format:</strong> {googleBook?.printType}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  {googleBook &&
                    resourceDetails.is_third_party === "amazon" && (
                      <div className="gooogle-book mt-4">
                        {Object.entries(googleBook).length > 0 && (
                          <>
                            <div className="">
                              <h1>Book Information</h1>
                            </div>
                            {Object.entries(googleBook).map(([key, value]) => (
                              <div key={key}>
                                {key && value && renderValue(key, value)}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  {googleBook && resourceDetails.is_third_party === "cj" && (
                    <div className="gooogle-book mt-4">
                      <div className="">
                        <h1>Book Information</h1>
                      </div>
                      {googleBook?.advertiserName && (
                        <p>
                          <strong>Purchase Location :</strong>{" "}
                          {googleBook?.advertiserName}
                        </p>
                      )}
                      {googleBook?.brand && (
                        <p>
                          <strong>Publisher:</strong> {googleBook?.brand}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="fulldecartion-div-rt">
                  <div
                    className="fulldecartionrightImgBox"
                    onClick={(e) => {
                      resourceDetails.resource_url &&
                        (urlStatus || resourceDetails?.resource_type === 2)
                        ? window.open(resourceDetails.resource_url, "_blank")
                        : setImgVisible(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {thumbnail ? (
                      <img
                        className="img-fluid"
                        src={thumbnail}
                        alt="Book Cover"
                        loading="lazy"
                      />
                    ) : resourceDetails?.imageLink ? (
                      // &&
                      //  !resourceDetails?.imageLink.includes("booksamillion.com")
                      <>
                        {imageLoaded ? (
                          <img
                            className="img-fluid"
                            src={resourceDetails?.imageLink}
                            alt="Book Cover"
                            loading="lazy"
                            onError={handleImageError}
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src={Trigonometry}
                            alt="Book Cover"
                            loading="lazy"
                          />
                        )}
                      </>
                    ) : resourceDetails?.resource_thumbnail_image ? (
                      <img
                        className="img-fluid"
                        src={
                          imageUrl + resourceDetails?.resource_thumbnail_image
                        }
                        alt="thumbnail "
                        loading="lazy"
                      />
                    ) : (
                      <img
                        className="img-fluid"
                        src={Trigonometry}
                        alt="Book Cover"
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="aboutresourcesdiv">
                <div className="row ">
                  <div className="col-lg-9">
                    <div className="aboutresourcesdiv-lt">
                      <div className="my-2">
                        {resourceDetails?.short_description && (
                          <h1>
                            {" "}
                            {resourceDetails?.short_description &&
                              "Short description"}{" "}
                          </h1>
                        )}
                        {resourceDetails?.short_description && (
                          <div className="resourceabouttextdatainfo square scrollbar-dusty-grass square thin">
                            <p>{resourceDetails?.short_description} </p>
                          </div>
                        )}
                      </div>
                      {resourceDetails?.about_resource !== "<p></p>" && (
                        <h1>
                          {" "}
                          {resourceDetails?.about_resource &&
                            "About this resource"}{" "}
                        </h1>
                      )}
                      {resourceDetails?.about_resource !== "<p></p>" && (
                        <div className="resourceabouttextdatainfo square scrollbar-dusty-grass square thin">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: resourceDetails?.about_resource,
                            }}
                          ></p>
                        </div>
                      )}
                      {resourceDetails?.resource_requirement !== "<p></p>" &&
                        resourceDetails?.resource_requirement && (
                          <>
                            <h1 className="mt-3">
                              {" "}
                              {resourceDetails?.resource_requirement &&
                                "Requirements/Tools"}{" "}
                            </h1>
                            <div className="resourceabouttextdatainfo square scrollbar-dusty-grass square thin">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: resourceDetails?.resource_requirement,
                                }}
                              ></p>
                            </div>
                            <br />
                          </>
                        )}
                      {resourceDetails?.about_instructor !== "<p></p>" && (
                        <>
                          <h1>
                            {" "}
                            {resourceDetails?.about_instructor &&
                              "About the Instructor"}{" "}
                          </h1>
                          <div className="resourceabouttextdatainfo square scrollbar-dusty-grass square thin">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: resourceDetails?.about_instructor,
                              }}
                            ></p>
                          </div>
                        </>
                      )}
                      <div className="accordinstart">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Description
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse type-res"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <form>
                                  <div className="row px-2">
                                    <div className="col-lg-6">
                                      {resourceDetails?.algolia[0]
                                        ?.resource_type && (
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Type of Resource
                                            </label>
                                            <div className="clearfix"></div>
                                            <div className="w-100">
                                              <span className="resourceTypeFeild form-control">
                                                {
                                                  resourceDetails?.algolia[0]
                                                    ?.resource_type
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                    {resourceDetails?.resource_type === 3 &&
                                      experience && (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Type of Experience
                                            </label>
                                            <div className="clearfix"></div>
                                            <div className="w-100">
                                              <span className="resourceTypeFeild form-control">
                                                {experience}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {resourceDetails?.resource_type === 7 &&
                                      facilities && (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Type of Facilities
                                            </label>
                                            <div className="clearfix"></div>
                                            <div className="w-100">
                                              <span className="resourceTypeFeild form-control">
                                                {facilities}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                    {resourceDetails?.resource_type === 6 &&
                                      funding && (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Type of Funding
                                            </label>
                                            <div className="clearfix"></div>
                                            <div className="w-100">
                                              <span className="resourceTypeFeild form-control">
                                                {funding}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {resourceDetails?.resource_type === 1 &&
                                      organization && (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Type of Organization
                                            </label>
                                            <div className="clearfix"></div>
                                            <div className="w-100">
                                              <span className="resourceTypeFeild form-control">
                                                {organization}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {(resourceDetails?.resource_type === 5 ||
                                      resourceDetails?.resource_type === 4) &&
                                      support && (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Type of Support
                                            </label>
                                            <div className="clearfix"></div>
                                            <div className="w-100">
                                              <span className="resourceTypeFeild form-control">
                                                {support}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {resourceDetails?.resource_type === 2 &&
                                      content && (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Type of Content
                                            </label>
                                            <div className="clearfix"></div>
                                            <div className="w-100">
                                              <span className="resourceTypeFeild form-control">
                                                {content}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {gradeRange && (
                                      <div className="col-lg-6">
                                        <div className="mb-3">
                                          <label className="form-label">
                                            Grade Range
                                          </label>

                                          <div className="clearfix"></div>
                                          <div className="w-100">
                                            <span className="resourceTypeFeild form-control">
                                              {gradeRange}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {resourceDetails?.algolia[0]?.timeframe
                                      ?.length > 0 && (
                                        <div className="col-lg-6">
                                          <div className="mb-3">
                                            <label className="form-label">
                                              Time Frame
                                            </label>
                                            <div className="clearfix"></div>
                                            <div className="w-100">
                                              <span className="resourceTypeFeild form-control">
                                                {
                                                  resourceDetails?.algolia[0]
                                                    ?.timeframe[0]
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {locationType?.length > 0 && (
                                      <div className="col-lg-6">
                                        <div className="mb-3">
                                          <label className="form-label">
                                            Location Types
                                          </label>
                                          <div className="clearfix"></div>
                                          <div className="w-100">
                                            <span className="resourceTypeFeild form-control">
                                              {locationType}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {resourceDetails?.start_date && (
                                      <div className="col-lg-6">
                                        <div className="mb-3">
                                          <label className="form-label">
                                            Date Range
                                          </label>
                                          <div className="clearfix"></div>
                                          <div className="w-100"></div>
                                          <span className="resourceTypeFeild form-control">
                                            {moment(
                                              resourceDetails?.start_date
                                            ).format("MM-DD-YYYY") +
                                              " to " +
                                              moment(
                                                resourceDetails?.end_date
                                              ).format("MM-DD-YYYY")}{" "}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {resourceDetails?.start_time && (
                                      <div className="col-lg-6">
                                        <div className="mb-3">
                                          <label className="form-label">
                                            Available times
                                          </label>
                                          <div className="clearfix"></div>
                                          <div className="w-100"></div>
                                          <span className="resourceTypeFeild form-control">
                                            {resourceDetails?.start_time +
                                              " to " +
                                              resourceDetails?.end_time}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {industryStyle?.length > 0 && (
                                    <div className="row px-2">
                                      <div className="col-lg-12">
                                        <div className="mb-3">
                                          <label className="form-label">
                                            Instructional Style
                                          </label>
                                          <div className="clearfix"></div>
                                          <div className="w-100">
                                            <span className="resourceTypeFeild form-control">
                                              {industryStyle}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {keywords && (
                                    <div className="row px-2">
                                      <div className="col-lg-12">
                                        <div className="mb-3">
                                          <label className="form-label">
                                            Keywords
                                          </label>
                                          <div className="clearfix"></div>
                                          <div className="w-100">
                                            <span className="resourceTypeFeild form-control">
                                              {keywords}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <>
                        <div className="leavfeedbackreviews">
                          <h1> Leave a Review </h1>
                          <h4>Rating </h4>
                          <div className="checkccc" ref={rankFocus}>
                            <ul className="unstyled centered">
                              <li>
                                <input
                                  className="styled-checkboxd"
                                  id="styled-checkbox-0"
                                  type="checkbox"
                                  checked={isParentChecked ? true : false}
                                  value={isParentChecked}
                                  onChange={(e) => {
                                    handleParentCheckbox(e);
                                  }}
                                />
                                <label
                                  className="text-capitalize px-1"
                                  htmlFor="styled-checkbox-0 d-flex "
                                >
                                  {userData?.firstName}
                                  </label>
                                  <span className="rankingStar px-1 ">
                                    <Rating
                                      defaultChecked={true}
                                      name="Rating Label0"
                                      value={parentRating}
                                      onChange={(event, newValue) => {
                                        setParentRating(newValue);
                                      }}
                                      onClick={rated}
                                    />
                                  </span>
                               
                              </li>
                              {childOwner1 &&
                                childOwner1?.length > 0 &&
                                childOwner1.map((val, i) => {
                                  return (
                                    <li key={i + 1}>
                                      <input
                                        className="styled-checkbox"
                                        id={`styled-checkbox-${i + 1}`}
                                        type="checkbox"
                                        value={val.id}
                                        ref={ratingFocus}
                                        checked={
                                          childsRating?.length > 0 && childRatingChekbox &&
                                            childRatingChekbox?.length > 0 &&
                                            (childRatingChekbox.includes(
                                              val.id
                                            ) ||
                                              childRatingChekbox.includes(
                                                val.id.toString()
                                              ))
                                          ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleChildRatingsCheckboxes(e)
                                        }
                                      />
                                      <label
                                        className="text-capitalize px-1"
                                        htmlFor={`styled-checkbox-${i + 1}`}
                                      >
                                        {val.firstName}
                                        </label>
                                        <span className="rankingStar px-1 ">
                                          <Rating
                                            value={
                                              childsRating &&
                                                childsRating?.length > 0
                                                ? childsRating[i].rating
                                                : 0
                                            }
                                            name={`Rating Label${i + 1}`}
                                            onChange={(event, newValue) => {
                                              handleChildRatings(
                                                event,
                                                newValue,
                                                i,
                                                val.id
                                              );
                                            }}
                                          />
                                        </span>
                                     
                                    </li>
                                  );
                                })}
                            </ul>
                            <span className="error-span">
<span className="error-span">
  {error?.rating && error.rating}
</span>

                          </span>
                          </div>
                          <h4>
                            {" "}
                            Comments <span className="start-color">*</span>
                          </h4>
                          <textarea
                            className="form-control"
                            rows="4"
                            onChange={handleParentComment}
                            value={userReview.parentComment}
                            ref={commentFocus}
                          ></textarea>
                          <span className="error-span">
                            {!userReview.parentComment &&
                              error &&
                              error.comment &&
                              "Comment is required"}
                          </span>
                          <div className="public-btn-save-p pb-0 mb-1">
                            <button
                              style={{ placeSelf: "center" }}
                              onClick={submitReviewHandler}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </>

                      <div className="reviewssection" id="goto">
                        <div ref={bottomRef} />
                        <div className="reviewssectionlt" id="scrollableDiv">
                          <h2>
                            Reviews
                            <span>
                              {/* {resourceDetails.rating !== 0 ? Number(resourceDetails.rating).toFixed(1)  : "0.0"} */}
                              {resourceReviews?.total_review !== 0
                                ? Number(resourceReviews?.total_review).toFixed(
                                  1
                                )
                                : "0.0"}{" "}
                              {renderRatingStars(resourceReviews?.total_review)}{" "}
                              ({resourceDetails.total_reviews})
                            </span>
                          </h2>
                        </div>

                        <div className="reviewsdroptt">
                          <div className="wrapper">
                            <label htmlFor="country">Reviews by:</label>
                          </div>

                          <Multiselect
                            className="form-select w-100 float-start saveResourceSelectss"
                            options={userOptions}
                            onSelect={(e) => {
                              e?.map((val, i) =>
                                setResourceReviewType(val.value)
                              );
                            }}
                            displayValue="name"
                            showCheckbox
                            showArrow={true}
                            selectedValues={
                              resourceReviewType
                                ? [
                                  userOptions.find(
                                    (item) =>
                                      item.value === resourceReviewType
                                  ),
                                ]
                                : []
                            }
                          />
                        </div>
                        {resourceReviews?.reviewsList?.length > 0 &&
                          resourceReviews?.reviewsList
                            ?.slice(0, numToShow)
                            .map((review, index) => {
                              return (
                                <div className="reviewssectionrtcv" key={index}>
                                  <div className="reviewssectionrtimg">
                                    <img
                                      className="img-fluid"
                                      src={
                                        review?.tbl_user?.photo
                                          ? imageUrl + review?.tbl_user?.photo
                                          : ""
                                      }
                                      alt="user"
                                    />
                                  </div>
                                  <div className="reviewssectionrtcontet">
                                    <h2>
                                      {review?.tbl_user?.firstName +
                                        " " +
                                        review?.tbl_user?.lastName}
                                    </h2>
                                    <div className="reviewlistcnt">
                                      <ul>
                                        <li>
                                          <span>
                                            {review.rating !== 0
                                              ? Number(review.rating).toFixed(1)
                                              : "0.0"}
                                          </span>
                                          {renderRatingStars(review?.rating)}
                                        </li>
                                        {numToShowReviewTest === index ? (
                                          <>
                                            {Array.isArray(
                                              review?.tbl_resource_child_reviews
                                            ) &&
                                              review.tbl_resource_child_reviews
                                                ?.length > 0 &&
                                              review.tbl_resource_child_reviews
                                                ?.slice(0, numToShowReview)
                                                .map((childReview, i) => {
                                                  return (
                                                    <li
                                                      key={i}
                                                      className="text_capital"
                                                    >
                                                      {childReview?.tbl_child
                                                        .firstName +
                                                        " " +
                                                        childReview?.tbl_child
                                                          .lastName}
                                                      <span className="px-1">
                                                        {childReview.rating !==
                                                          0
                                                          ? Number(
                                                            childReview.rating
                                                          ).toFixed(1)
                                                          : "0.0"}
                                                      </span>
                                                      {renderRatingStars(
                                                        childReview.rating
                                                      )}
                                                      <span></span>
                                                    </li>
                                                  );
                                                })}
                                          </>
                                        ) : (
                                          <>
                                            {Array.isArray(
                                              review?.tbl_resource_child_reviews
                                            ) &&
                                              review.tbl_resource_child_reviews
                                                ?.length > 0 &&
                                              review.tbl_resource_child_reviews
                                                ?.slice(0, 2)
                                                .map((childReview, i) => {
                                                  return (
                                                    <li
                                                      className="text_capital"
                                                      key={i}
                                                    >
                                                      {childReview?.tbl_child
                                                        .firstName +
                                                        " " +
                                                        childReview?.tbl_child
                                                          .lastName}
                                                      <span className="px-1">
                                                        {childReview.rating !==
                                                          0
                                                          ? Number(
                                                            childReview.rating
                                                          ).toFixed(1)
                                                          : "0.0"}
                                                      </span>
                                                      {renderRatingStars(
                                                        childReview?.rating
                                                      )}
                                                      <span></span>
                                                    </li>
                                                  );
                                                })}
                                          </>
                                        )}
                                        <div className="viewmorerating">
                                          <p
                                            onClick={() => {
                                              setNumToShowReviewTest(index);
                                              setNumToShowReview(
                                                review
                                                  .tbl_resource_child_reviews
                                                  ?.length
                                              );
                                            }}
                                            className=""
                                          >
                                            {review.tbl_resource_child_reviews
                                              ?.length !== numToShowReview &&
                                              review.tbl_resource_child_reviews
                                                ?.length > 2 &&
                                              "Show More view"}
                                          </p>
                                        </div>
                                      </ul>
                                    </div>
                                    <div className="rattingReviewdatainfo square scrollbar-dusty-grass square thin">
                                      <p> {review.message}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        <div
                          className="shwmorebttun"
                          onClick={() =>
                            setNumToShow(resourceReviews?.reviewsList?.length)
                          }
                        >
                          {resourceReviews?.reviewsList?.length !== numToShow &&
                            resourceReviews?.reviewsList?.length > 2 &&
                            "Show More Reviews"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {payLoad !== parseInt(resourceDetails?.user_id) && (
                    <div className="col-lg-3">
                      <div className="aboutresourcesdiv-rt">
                        {resourceDetails?.purchase === false ? (
                          <>
                            {(resourceDetails?.affiliate_purchase_button ===
                              1 &&
                              resourceDetails?.purchase_from_me === "no" &&
                              resourceDetails?.resource_url &&
                              resourceDetails?.resource_type !== 1 &&
                              resourceDetails?.resource_type !== 3 &&
                              (resourceDetails?.resource_type !== 7 ||
                                resourceDetails?.resource_type !== 4 ||
                                resourceDetails?.resource_type !== 5))
                                 ||
                              (resourceDetails?.is_chat_gpt === "yes" &&
                                resourceDetails.resource_url &&
                                (urlStatus ||
                                  resourceDetails?.resource_type === 2) &&
                                resourceDetails?.resource_type !== 1 &&
                                resourceDetails?.resource_type !== 3 &&
                                (resourceDetails?.resource_type !== 7 ||
                                  resourceDetails?.resource_type !== 4 ||
                                  resourceDetails?.resource_type !== 5)) ? (
                              <a
                                href={resourceDetails.resource_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="vieworder-button purchaseviewOrderinfo"
                              >
                                Purchase
                                <div className="facustomeI">
                                  i
                                  <div className="tootlippillerinfo">
                                    <p>
                                      <strong className="mb-2 pb-1 border-bottom">
                                        Affiliate Disclaimer
                                      </strong>
                                    </p>
                                    <p>
                                      To maintain our platform's affordability
                                      for our valued Members, we've established
                                      partnerships with several trusted
                                      affiliates. These partners grant MatchED
                                      commissions when our members make
                                      qualifying product purchases through their
                                      links. When you choose to buy through
                                      these links, you contribute to the
                                      enhancement and accessibility of MatchED,
                                      enabling us to reach more student’s. We
                                      sincerely appreciate your support!(Please
                                      refer to our{" "}
                                      <a
                                        href="https://www.termsfeed.com/live/ab1d0db4-d3bf-4bbe-872a-aa7acee6ae5a"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        referrerPolicy="no-referrer"
                                      >
                                        Disclaimer
                                      </a>{" "}
                                      for further information.)
                                      <br />
                                    </p>
                                    <strong style={{ textAlign: "left" }}>
                                      #CommissionsEarned
                                    </strong>
                                  </div>
                                </div>
                              </a>
                            ) : (
                              <>
                                {resourceDetails?.is_chat_gpt === "yes" &&
                                  resourceDetails.resource_url &&
                                  (resourceDetails?.resource_type === 1 ||
                                    resourceDetails?.resource_type === 3 ||
                                    resourceDetails?.resource_type === 7 ||
                                    resourceDetails?.resource_type === 4 ||
                                    resourceDetails?.resource_type === 5) ? (
                                  <a
                                    href={resourceDetails.resource_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="vieworder-button purchaseviewOrderinfo"
                                  >
                                    View Website
                                  </a>
                                ) : (
                                  <>
                                    {resourceDetails?.purchase_price &&
                                      resourceDetails?.affiliate_purchase_button ===
                                      1 &&
                                      resourceDetails?.purchase_from_me ===
                                      "yes" &&
                                      sessionData.length <= 0 ? (
                                      <>
                                        <a
                                          href={`/purchase-resource/${resourceDetails.id}`}
                                          className="vieworder-button"
                                        >
                                          Purchase
                                        </a>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        <button
                          onClick={() =>
                            handleResourceShareOpen(resourceDetails)
                          }
                          className="vieworder-button"
                        >
                          Share
                        </button>
                        <button
                          onClick={() =>
                            handleResourceSaveOpen(resourceDetails)
                          }
                          className="vieworder-button"
                        >
                          Save
                        </button>
                        {sessionData?.length &&
                          resourceDetails?.affiliate_purchase_button === 1 ? (
                          <>
                            {sessionData.filter((session) => session.price > 0)
                              .length > 0 && (
                                <button
                                  className="vieworder-button"
                                  onClick={() =>
                                    handleBookaMentorOpen(sessionData, false)
                                  }
                                >
                                  Purchase/Schedule
                                </button>
                              )}
                          </>
                        ) : (
                          ""
                        )}
                        {
                        // (
                          resourceDetails?.resource_type === 3
                        //  ||
                        //   resourceDetails?.resource_type === 4 ||
                        //   resourceDetails?.resource_type === 5 ||    condition is jus for resource type is 3 not for others
                        //   resourceDetails?.resource_type === 7) 
                          && (
                            <button
                              className="vieworder-button"
                              onClick={() => setScheduleOpen(true)}
                            >
                              Re-Post this Event
                            </button>
                          )}
                        {sessionData?.length > 0 &&
                          resourceDetails?.affiliate_signup_button === 1 && (
                            <>
                              {sessionData.filter(
                                (session) => session.price <= 0
                              ).length > 0 && (
                                  <button
                                    onClick={() =>
                                      handleBookaMentorOpen(sessionData, true)
                                    }
                                    className="vieworder-button"
                                  >
                                    Sign Up
                                  </button>
                                )}
                            </>
                          )}
                        {resourceDetails?.affiliate_enroll_button === 1 && (
                          <button
                            onClick={() => setEnrollOpen(true)}
                            className="vieworder-button"
                          >
                            Enroll
                          </button>
                        )}
                        {resourceDetails?.affiliate_add_info_button === 1 && (
                          <button
                            onClick={() => {
                              AddUser(resourceDetails?.chat_user_id,{
                                name:
                                  resourceDetails?.chat_user?.firstname +
                                  " " +
                                  resourceDetails?.chat_user?.lastName,
                                image:
                                  imageUrl + resourceDetails?.tbl_user?.photo,
                              });
                            }}
                            className="vieworder-button"
                          >
                            Get More Info
                          </button>
                        )}
                      </div>
                      <button
                        onClick={() =>
                          handleResourceReportOpen(resourceDetails)
                        }
                        className="report-button reportsuggestChanges purchaseviewOrderinfo"
                      >
                        Report/Suggest Changes
                        <div className="facustomeI">
                          i
                          <div className="tootlippillerinfo">
                            <p>
                              Our MatchED community appreciates recommendations
                              to improve our resource database. Please report
                              any issues and suggest additional information that
                              would improve this resource information for future
                              Members. Thank you!
                            </p>
                          </div>
                        </div>
                      </button>
                    </div>
                  )}
                  {payLoad === parseInt(resourceDetails?.user_id) && (
                    <div className="col-lg-3">
                      <div className="aboutresourcesdiv-rt">
                        {resourceDetails?.resource_mode === "save" && (
                          <button
                            onClick={() => navigate(`/save-page/${param.id}`)}
                            className="vieworder-button"
                          >
                            Edit Resource
                          </button>
                        )}
                        {resourceDetails?.resource_mode === "serve" && (
                          <button
                            onClick={() => navigate(`/serve/${param.id}`)}
                            className="vieworder-button"
                          >
                            Edit Resource
                          </button>
                        )}

                        <button
                          onClick={() => setOpen(true)}
                          className="vieworder-button"
                        >
                          Delete Resource
                        </button>
                        <button
                          onClick={() =>
                            handleResourceShareOpen(resourceDetails)
                          }
                          className="vieworder-button"
                        >
                          Share
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div ref={scrollRef} />
            </div>
          )}
        </>
      )}
      {isVisible && (
        <div className="sharepopupOuter">
          <div className="save-pop-up-container-inner">
            <div className="pop-up-container">
              <span
                className="close-btn"
                onClick={() => {
                  setVisible(false);
                  if (document.body.classList.contains("removescroll")) {
                    document.body.classList.remove("removescroll");
                  }
                }}
              >
                <i className="fa fa-close closecs"></i>
              </span>
              <h3>Preview </h3>
              <div className="input-popup2">
                {contentPreview.imgurl &&
                  (contentPreview.imgtype === ".png" ||
                    contentPreview.imgtype === "png" ||
                    contentPreview.imgtype === ".jpg" ||
                    contentPreview.imgtype === "jpg" ||
                    contentPreview.imgtype === ".jpeg" ||
                    contentPreview.imgtype === "jpeg") && (
                    <img
                      src={contentPreview.imgurl}
                      width={400}
                      heigth={200}
                      className="img_preview"
                      alt="contentPreview"
                      loading="lazy"
                    />
                  )}
                {contentPreview.imgurl &&
                  (contentPreview.imgtype === ".mp4" ||
                    contentPreview.imgtype === "mp4" ||
                    contentPreview.imgtype === ".mpeg" ||
                    contentPreview.imgtype === "mpeg") && (
                    <video
                      width="550"
                      height="340"
                      controls
                      controlsList="nodownload"
                    >
                      <source
                        src={contentPreview.imgurl}
                        type={`video/${contentPreview.imgtype.replace(
                          ".",
                          ""
                        )}`}
                      ></source>
                    </video>
                  )}
                {contentPreview.imgurl &&
                  (contentPreview.imgtype === ".mov" ||
                    contentPreview.imgtype === "mov" ||
                    contentPreview?.imgtype === ".quicktime" ||
                    contentPreview?.imgtype === "quicktime") && (
                    <video
                      src={contentPreview.imgurl}
                      alt="preview"
                      width="550"
                      hieght="340"
                      controls
                    />
                  )}
                {contentPreview.imgurl &&
                  (contentPreview.imgtype === ".mp3" ||
                    contentPreview.imgtype === "mp3") && (
                    <>
                      <audio controls>
                        <source
                          src={contentPreview.imgurl}
                          type={`audio/${contentPreview.imgtype.replace(
                            ".",
                            ""
                          )}`}
                        ></source>
                      </audio>
                    </>
                  )}
                {contentPreview.imgurl &&
                  (contentPreview.imgtype === ".pdf" ||
                    contentPreview.imgtype === "pdf") && (
                    <PdfViewer pdfurl={contentPreview.imgurl} />
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
      {imgVisible && (
        <div className="sharepopupOuter">
          <div className="save-pop-up-container-inner">
            <div className="pop-up-container">
              <span
                className="close-btn"
                onClick={() => {
                  setImgVisible(false);
                }}
              >
                <i className="fa fa-close closecs"></i>
              </span>
              <div className="input-popup2">
                {thumbnail ? (
                  <img
                    className="img-fluid"
                    src={thumbnail}
                    alt="thumbnail"
                    loading="lazy"
                    width="100%"
                    height="100%"
                  />
                ) : resourceDetails?.imageLink ? (
                  <img
                    className="img-fluid"
                    src={resourceDetails?.imageLink}
                    alt="thumbnail"
                    loading="lazy"
                    width="100%"
                    height="100%"
                  />
                ) : resourceDetails?.resource_thumbnail_image ? (
                  <img
                    className="img-fluid"
                    alt="thumbnail"
                    src={imageUrl + resourceDetails?.resource_thumbnail_image}
                    loading="lazy"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={Trigonometry}
                    alt="Trigonometry"
                    loading="lazy"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {(resourceDetails?.is_deleted === "yes" || show.status) && (
        <Modal
          open={true}
          onClose={false}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
              style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
            >
              {show.text ? show.text : "This resource is deleted"}
            </Typography>
          </Box>
        </Modal>
      )}
      <DeleteRecourse
        open={open}
        handleClose={() => setOpen(false)}
        id={param.id}
        resource_mode={resourceGroupStatus}
      />
      {value && (
        <Modal
          open={openBox}
          onClose={() => setOpenBox(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
              style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
            >
              This data has already been rated. Would you like to edit the
              rating?
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 3 }}
              className="d-flex justify-content-center gap-4"
            >
              <button className="child-delete" onClick={(e) => addRating(e)}>
                Yes
              </button>
              <button
                className="child-cancel"
                onClick={() => setOpenBox(false)}
              >
                No
              </button>
            </Typography>
          </Box>
        </Modal>
      )}
      <Modal
        open={openLink}
        onClose={() => setOpenLink(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "20px", lineHeight: "36px" }}
          >
            This is an unverified external link, and it has not been endorsed or
            verified by MATCHED. If you have concerns or doubts about its
            legitimacy, we strongly recommend clicking "Cancel" if you do not
            wish to proceed. Your online safety and security are of utmost
            importance.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button
              className="child-delete"
              onClick={() => {
                handleShowLink(saveLink);
              }}
            >
              Continue
            </button>
            <button className="child-cancel" onClick={() => setOpenLink(false)}>
              Cancel
            </button>{" "}
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={scheduleOpen}
        onClose={() => setScheduleOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Do you wish to clone this resource, add your own edits, and repost
            it as a new resource?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={handleSchedule}>
              Yes
            </button>
            <button
              className="child-cancel"
              onClick={() => setScheduleOpen(false)}
            >
              No
            </button>
          </Typography>
        </Box>
      </Modal>
      {userStore?.globalOpenChat?.chatOpen && userStore?.globalOpenChat?.pageName === "description" &&
      (
        <SingleChat
          groupName={userStore?.globalOpenChat?.userDetail}
          chatClose={() => dispatch(getOpenGlobalChat({
            chatOpen : false, userDetail : "", userMapId: "", senderId: "", group:"", pageName: ""
             }))}
          userMapId={userStore?.globalOpenChat?.userMapId}
          senderId={userStore?.globalOpenChat?.senderId}
          group={userStore?.globalOpenChat?.group}
          statuspage={"description"}
          getAllUser={() => { }}
        />
      )}
    </>
  );
};
const RenderLearningPillars = ({ resourceDetails, pillar }) => {
  let dataList = resourceDetails?.algolia[0]?.learning_pillars;
  const idSet = new Set(dataList.map((item) => item));
  const mergedArray = pillar.map((item) => ({
    ...item,
    visible: idSet.has(item.name),
  }));

  return (
    <div className="hearicontop">
      <ul>
        {mergedArray?.map((data, index) => {
          switch (data?.id) {
            case 2:
              return (
                <li
                  key={index}
                  style={{ color: `${data.visible ? "#265472" : "#86898b"}` }}
                >
                  <span>
                    <img
                      className="img-fluid"
                      src={data.visible ? Hearticon : victor10}
                      alt="heart"
                      loading="lazy"
                    />
                  </span>
                  <span>Heart</span>
                </li>
              );
            case 3:
              return (
                <li
                  key={index}
                  style={{ color: `${data.visible ? "#AAC0CE" : "#86898b"}` }}
                >
                  <span>
                    <img
                      className="img-fluid"
                      src={data.visible ? Strengthicon : strength}
                      alt="strength"
                      loading="lazy"
                    />
                  </span>
                  <span>Strength</span>
                </li>
              );
            case 1:
              return (
                <li
                  key={index}
                  style={{ color: `${data.visible ? "#F19357" : "#86898b"}` }}
                >
                  <span>
                    <img
                      className="img-fluid"
                      src={data.visible ? Mindicon : icon}
                      alt="mind"
                      loading="lazy"
                    />
                  </span>
                  <span> Mind</span>
                </li>
              );
            case 4:
              return (
                <li
                  key={index}
                  style={{ color: `${data.visible ? "#F0B285" : "#86898b"}` }}
                >
                  <span>
                    <img
                      className="img-fluid"
                      src={data.visible ? Sounicon : soul}
                      alt="soul"
                      loading="lazy"
                    />
                  </span>
                  <span>Soul</span>
                </li>
              );
            default:
              return null;
          }
        })}
      </ul>
    </div>
  );
};
export default FullDescription;
