import React, { useEffect, useState } from "react";
import "../Search.WebAppMate/reportpopup.css";
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import { URLS } from "../../constants";
import { makePostRequestForAll } from "../../services/api";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";

/**
 * create components is used for friend list
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

function PermissionSharepopup(props) {
  let user_id = decodeToken().userAuth.id;
  const [sharedChild, setSharedChild] = useState([]);
  const [getPermission, setGetPermission] = useState([]);
  const [spin, setSpin] = useState(false)

  //  this function is use for get all request and friend list
  useEffect(() => {
    setSpin(true)
    makePostRequestForAll(URLS.getChildWithPermission, {
      parent_id: user_id,
      share_with_user_id: props.userId,
    }).then((res) => {
      const permssion = res.data.filter(obj => obj.shared === false)
      setGetPermission(permssion);
      let arr = [];
      for (var i = 0; i < res.data.length; i++) {
        if (res?.data[i]?.shared === true) {
          arr.push(res.data[i].child_id.toString())
        }
      }
      setSharedChild(arr);
      setSpin(false)
    })
      .catch((error) => {
        setSpin(false)
        console.log("error", error);
      });
  }, [props.userId,user_id]);

  // this function is use for hanlde chnage
  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "sharedChild") {
      if (e.target.checked) {
        setSharedChild([...sharedChild, value]);
      } else {
        setSharedChild([...sharedChild].filter((val) => val !== value));
      }
    }
  };


  //  this function is use for chnage status of premission
  const statusOfPermision = () => {
    setSpin(true)
    const child = [...new Set(sharedChild)];
    const payload = {
      shared_child_id: child,
      child_parent_id: user_id,
      share_with_user_id: props.userId,
      status: "accept",
      remove_child_id: []
    };
    makePostRequestForAll(URLS.shareChildProfilePermission, payload)
      .then((res) => {
        if (res.code === 200) {
          toastifyMessage(res.message, "success");
          props.data()
          setSpin(false)
        }
      })
      .catch((error) => {
        setSpin(false)
        toastifyMessage("something went wrong", "error");
      });
  };
  return (
    <>
      <div className="sharepopupOuter backgroundOpacity">
        {spin ? (
          <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        ) : (
          <div className="pop-up-container">
            <button className="close-btn" onClick={props.data}>
              X
            </button>
            <h3 className="text-center"> Share Permissions </h3>
            <div className="input-popup1">
              <h4 className="pop-h1 text-center">{getPermission.length > 0 && `Allow ${props.userName} to view the following profile(s):`} </h4>
            </div>
            <div className="innerBoxList sharescrollfix">
              <ul>
                {
                  getPermission.length > 0 ? getPermission.map((val) => {
                    return (
                      <>
                        <li className="d-flex gap-4">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="sharedChild"
                            onChange={handleInput}
                            value={val.child_id}
                          />
                          <label>{val.child_name}</label>
                        </li>
                      </>
                    )
                  })
                    : <h4 className="text-center mb-5" style={{ fontSize: "20px" }}>You have already shared all your student's permissions.</h4>}
              </ul>
            </div>
            <div className="save-cancel-btn my-3">
              <div className="savee">
                <button onClick={statusOfPermision}>save</button>
              </div>&nbsp;&nbsp;&nbsp;
              <div className="cancel-butn">
                <button onClick={props.data}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  );
}
export default PermissionSharepopup;
