import React, { useState, useEffect } from "react";
import Logo from '../../assets/Images/Logo.png';
import './header.css';
import { Link } from "react-router-dom";
// /*
//      *  Header function to display the header
//      *  @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 10-5-2023
//      * /
function Header1() {
	const [scroll, setScroll] = useState(false);
	const [timeFrameActive1, setTimeFrameActive1] = useState(false);
	const [timeFrameActive2, setTimeFrameActive2] = useState(false);
	const [timeFrameActive3, setTimeFrameActive3] = useState(false);

	// handle buttons
	const handleTimeFrame = (value, index) => {
		if (index === "1") {
			setTimeFrameActive1(!timeFrameActive1);
			setTimeFrameActive2(false);
			setTimeFrameActive3(false);
		} else if (index === "2") {
			setTimeFrameActive1(false);
			setTimeFrameActive2(!timeFrameActive2);
			setTimeFrameActive3(false);
		}else if (index === "3") {
			setTimeFrameActive1(false);
			setTimeFrameActive2(false);
			setTimeFrameActive3(!timeFrameActive3);
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 30);
		});
	}, []);

	return (
		<div className={`headerdelete ${scroll ? "headerfix" : "headerscroll"}`}>
			<div className="container-fluid">
				<div className="row">
					<div className="col-4 col-lg-3">
						<Link to="/" className="Imglogo image-url">
							<img className="img-fluid" src={Logo} alt="MatchED Community" width="100%" height="100%" />
						</Link>
					</div>
					<div className="col-lg-6 hidden-xs">
					</div>
					<div className="col-8 col-lg-3">
						<div className="header-account">
						</div>
						<div className="helpcenter-header">
							{/* <h4><a href="https://matchedcommunity.zendesk.com/hc/en-us" target="_blank">Help Center</a></h4> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Header1;