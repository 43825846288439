import React, { useEffect, useRef, useState } from "react";
import "./search.css";
import { Multiselect } from "multiselect-react-dropdown";
import Mindicon from "../../assets/Images/Mindicon.png";
import Sounicon from "../../assets/Images/Sounicon.png";
import Hearticon from "../../assets/Images/Hearticon.png";
import ph22 from "../../assets/Images/ph22.png";
import image2 from "../../assets/Images/image2.png";
import searchbottom from "../../assets/Images/searchbottom.jpg";
import searchleft from "../../assets/Images/searchleft.png"
import vic1 from "../../assets/Images/vic1.png";
import searchright from "../../assets/Images/searchright.jpg"
import searchtops from "../../assets/Images/searchtops.jpg"
import vector4 from "../../assets/Images/vector4.png"
import vectorr from "../../assets/Images/vectorr.png";
import vector3 from "../../assets/Images/vector3.png";
import srimg5 from "../../assets/Images/sr-img5.png";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { renderDescription, toSeoUrl } from "../../services/common.services"
import Head from "../Layout.WebAppMate/head/Head"
import Strengthicon from "../../assets/Images/Strengthicon.png";
import {
  saveSearchResults,
  setSelectedTimeframe,
  setViewableSearchResults,
  setSelectedLearningPillars,
  setSelectedFacilityType,
  setSelectedGrade,
  setSelectedResourceTypeFilterOptions,
  setSelectedOrganizationType,
  setSelectedResourceType,
  setSelectedExperienceType,
  setSelectedLocationType,
  setSelectedGroupFor,
  setSelectedChildFor,
  setSelectedFriendFor,
  setSelectedCustomerType,
  setSelectedTypeOfSupport,
  setSelectedFundingType,
  setSelectedInstructionStyle,
  setSelectedTypeOfContent,
  setSelectedDualEnroll,
  setSelectedAccredited,
  getFilterOptions,
  clearChatgptSearch,
} from "../../store/reducers/searchReducer";
import { useDispatch, useSelector } from "react-redux";
import SearchService from "../../services/searchService";
import { capitalizeWords, toastifyMessage } from "../../services/utilities";
import CardMenuButton from "./CardButtonMenu";
import Reportpopup from "./Reportpopup";
import Saveresourcespopup from "./Saveresourcespopup";
import moment from "moment";
import {
  imageUrl,
  makePostRequest,
  makeGetRequest,
  makePostRequestcancle,
} from "../../services/api";
import { URLS } from "../../constants";
import RenderCardInfo, { openRouteInNewTab, renderRatingStars } from "./RenderCardInfo";
import "./rating.css";
import LocationPopUp from "./LocationPopUp";
import ShareRecoursePopUp from "../Common.WebAppMate/ShareRecoursePopUp";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getChatgptCount, saveUserChildrens, getSearchAlgoliaCount } from "../../store/reducers/userReducer";
import { decodeToken, style } from "../../utilities/CustomFunctions";
import { viewResult } from "../ViewProfile.WebAppMate/Child_profile_page";
import axios from "axios";
function Searchpage() {
  const SearchResultItam = 24;
  const userData = decodeToken().userAuth;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let searchStore = useSelector((state) => state.search);
  const userStore = useSelector((state) => state.user);
  const {
    filterOptions,
    selectedFilters,
    selectedFilterOptions,
    searchResults,
    viewableSearchResults,
    searchChatgpt
  } = searchStore;
  const { resource_type, learning_pillars, friend_for, group_for, km } =
    filterOptions;
  const { selectedResourceType, selectedResourceTypeFilterOptions } =
    selectedFilterOptions;
  const {
    selectedGradesOptions,
    selectedExperienceTypeOptions,
    selectedSharedByOptions,
    selectedTimeframeTypeOptions,
    selectedInstructionStyleOptions,
    selectedFacilityTypeOptions,
    selectedFundingTypeOptions,
    selectedOrganizationTypeOptions,
    selectedLocationTypeOptions,
    selectedCustomerTypeOptions,
    selectedTypeOfSupportOptions,
    selectedTypeOfContentOptions,
    selecteddDualEnrollmentResource,
    selectedAccreditedResource,
  } = selectedResourceTypeFilterOptions;
  const {
    selectedLearningPillars,
    selectedResourceTypeFilter,
    selectedChildFor,
    selectedFriendFor,
    selectedGroupFor,
  } = selectedFilters;
  const {
    selectedGrades,
    selectedExperienceType,
    selectedTimeframe,
    selectedInstructionStyle,
    selectedFacilityType,
    selectedFundingType,
    selectedOrganizationType,
    selectedLocationType,
    selectedCustomerType,
    selectedTypeOfSupport,
    selectedTypeOfContent,
    selectedDual,
    selectedAccredited,
  } = selectedResourceTypeFilter;
  let resource_type_data = [];
  const [childFor, setChildFor] = useState([])
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [searchDataPage, setSearchDataPage] = useState(1);
  const [isLoadMoreVisible, setIsLoadMoreVisible] = useState(true);
  const [uniqueChatgpt, setUniqueChatgpt] = useState([])
  const [addressType, setAddressType] = useState("Address");
  const [zip_code_status, setzip_code_status]=  useState(false);
  const [reportPopupStatus, setReportPopupStatus] = useState({
    visible: false,
    objectID: null,
  });
  const [savePopupStatus, setSavePopupStatus] = useState({
    visible: false,
    selectedResource: null,
  });
  const [sharePopupStatus, setSharePopupStatus] = useState({
    visible: false,
    objectID: null,
  });
  const [locatonStatus, setLocatonStatus] = useState(false);
  const [chatGPTLoader, setChatGPTLoader] = useState(false);
  const [queryStatus, setQueryStatus] = useState("")
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [distanceFromLocation, setDistanceFromLocation] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [keywordsData, setKeywordsData] = useState("");
  const [resource, setResorce] = useState("");
  const [resourceId, setResourceId] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [locationPermission, setLocationPermission] = useState("prompt");
  const [deniedError, setDeniedError] = useState("");
  const [showContent, setShowContent] = useState(false);
  const [dummySelect, setDummySelect] = useState([]);
  const [childStyle, setChildStyle] = useState([])
  const [isMounted, setIsMounted] = useState(false);
  const [userType, setUserType] = useState([]);
  const [planStatus, setPlanStatus] = useState("");
  const sharedByOptions = [
    ...(resourceId !== "group" ?
      [
        { value: "global", name: "Global Search" },
        { value: "Community", name: "All MatchED Community" },
        { value: "local", name: "Local MatchED Community" },
        { value: "friend", name: "Friends" },
        { value: "group", name: "Groups" }
      ]
      : [
        { value: "1", name: "Local MatchED Community" },
        { value: "2", name: "Friend" },
        { value: "3", name: "Group" } // these options used if we select group from resource type
      ]
    ),


  ]
  const zipOptions = [{ name: "Address", value: "Address" }, { name: "Zip Code", value: "Zipcode" }]
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const autoCompleteRef2 = useRef();
  const inputRef2 = useRef();
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ msg: "", status: "", type: "" })
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [dataFound, setDataFound] = useState(false);
  const [defaultGrede, setDefaultGrede] = useState([]);
  const [zipcode, setZipCode] = useState('');
  const [shareBy, setShareBy] = useState("global");
  const [shareSelected, setShareSelected] = useState("")
  const [accredited, setAccredited] = useState([{ id: "Accredited", value: "" }])
  const [dualSelected, setDualSelected] = useState([{ id: "Dual Enrollment", value: "" }])
  const [downSpin, setDownSpin] = useState("");
  const [friendData, setFriendData] = useState([]);
  const [groupData, setgroupData] = useState([]);
  const [dummy, setDummy] = useState([]);
  const [chatgptData, setChatgptData] = useState([]);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [chatgptDataCount, setChatgptDataCount] = useState(0);
  // this function is sort bthe grade
  const sortedGrades = [...selectedGradesOptions].sort((a, b) => a.id - b.id);

  // This is used for if resource id === "group" then we will not select anything in share by otherwise in all
  // cases global search selected by default
  useEffect(() => {
    if (resourceId === "group") {
      setShareSelected([])
    } else {
      setShareSelected([{ value: "global", name: "Global Search" }])
    }

  }, [resourceId])
  // this function use for friends and group
  useEffect(() => {
    if (friend_for.length > 0) {
      const data = [{ name: "All", id: "all" }]
      setFriendData([...data, ...friend_for])
    }
    if (group_for.length > 0) {
      const data = [{ name: "All", id: "all" }]
      setgroupData([...data, ...group_for])
    }

  }, [friend_for, group_for])

  useEffect(() => {
    if (shareBy === "friend") {
      dispatch(setSelectedFriendFor([{ name: "All", id: "all" }]))
      dispatch(setSelectedGroupFor([]))
    }
    if (shareBy === "group") {
      dispatch(setSelectedGroupFor([{ name: "All", id: "all" }]))
      dispatch(setSelectedFriendFor([]))
    }

  }, [shareBy])

  // whenever you changing resource type automatically gloabl search selected and "global" value added in state
  useEffect(() => {
    setShareBy("global")
  }, [resourceId])

  useEffect(() => {
    const uniqueItems = new Set();
    const defaulArr = []
    const result = []
    // userStore?.userChildrens?.forEach((obj) => {
      childFor?.forEach((obj) => {
      if (selectedChildFor.includes(obj.id)) {
        defaulArr.push({ id: obj.grade_id, name: obj.grade_name })
      }
    })
    for (const item of defaulArr) {
      if (item.id !== null && !uniqueItems.has(item.id)) {
        uniqueItems.add(item.id);
        result.push(item);
      }
    }
    dispatch(setSelectedGrade(result))
    setDefaultGrede(result)
  }, [selectedChildFor])

  // this function use for location permission
  const getGoogleAddressStreetOne = () => {
    if (window.google && window.google.maps && window.google.maps.places) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          ...{
            componentRestrictions: { country: ["US", "IND"] },
            fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: false,
            types: [],
          }, disablePoweredByContainer: true
        }
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();

        let zipcode;
        let addressComponents;
        if (place?.address_components) {
          setLatitude(place.geometry.location.lat());
          setLongitude(place.geometry.location.lng());
          place.address_components.forEach((component) => {
            if (component.types.includes("postal_code")) {
              zipcode = component.long_name;
            }
          });
          setZipCode(zipcode);
          addressComponents = place.address_components;
          let fullAddress = "";
          addressComponents.forEach((component) => {
            fullAddress += component.long_name + ", ";
          });
          fullAddress = fullAddress.slice(0, -2);
          setAddress(fullAddress);
        } else {
          addressComponents = [];

        }

      });
    } else {
      console.error("Google Maps API not available");
    }
  };
  
  useEffect(() => {
    let role = "";
    if (userData?.tbl_users_roles?.length > 0) {
      userData?.tbl_users_roles?.forEach((val, index) => {
        role += val.tbl_user_role.role_name;
        if (index < userData?.tbl_users_roles?.length - 1) {
          role += " or ";
        }
      });
    }
    setUserType(role)
  })
  const getGoogleAddressStreetOne2 = () => {
    if (window.google && window.google.maps && window.google.maps.places) {
      autoCompleteRef2.current = new window.google.maps.places.Autocomplete(
        inputRef2.current,
        {
          ...{
            componentRestrictions: { country: ["US", "IND"] },
            fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: false,
            types: ["postal_code"],
          }, disablePoweredByContainer: true
        }
      );
      autoCompleteRef2?.current?.addListener("place_changed", async function () {
        const place = await autoCompleteRef2?.current?.getPlace();
        let fullAddress = "";
        let zipcode;
        if (place?.address_components) {
        setLatitude(place?.geometry.location.lat());
        setLongitude(place?.geometry.location.lng());

        place?.address_components?.forEach((component) => {
          if (component.types?.includes("postal_code")) {
            zipcode = component.long_name;
          }
        });
        setZipCode(zipcode);
        const addressComponents = place.address_components;
      
        addressComponents.forEach((component) => {
          fullAddress += component.long_name + ", ";
        });
        fullAddress = fullAddress.slice(0, -2);
        setAddress(fullAddress);
      }
      });
    } else {
      console.error("Google Maps API not available");
    }
  };
  useEffect(() => {
    if (showContent) {
      if (
        resourceId === "1" ||
        resourceId === "7" ||
        resourceId === "6" ||
        resourceId === "3" ||
        resourceId === "5" ||
        resourceId === "4" ||
        resourceId === "group" // this is used for new type of resource (Which is Group)
      ) {
        if (userData?.address && userData?.zipcode) {
          setAddress(userData?.address)
          setZipCode(userData?.zipcode)
          setLatitude(userData?.latitude);
          setLongitude(userData?.longitude);
          getGoogleAddressStreetOne()
          getGoogleAddressStreetOne2()
        } else {
          locatioPremission();
        }
      }
    }
  }, [showContent, addressType])
  // start location & dispatch-------------------------
  useEffect(() => {
    dispatch(setSelectedOrganizationType([]))
    dispatch(setSelectedLocationType([]))
    dispatch(setSelectedGroupFor([]))
    dispatch(setSelectedChildFor([]))
    dispatch(setSelectedFriendFor([]))
    dispatch(setSelectedCustomerType([]))
    dispatch(setSelectedTypeOfSupport([]))
    dispatch(setSelectedFundingType([]))
    dispatch(setSelectedInstructionStyle([]))
    dispatch(setSelectedTypeOfContent([]))
    dispatch(setSelectedDualEnroll(""))
    dispatch(setSelectedAccredited(""))
    dispatch(setSelectedTimeframe([]))
    dispatch(setSelectedLearningPillars([]))
    dispatch(setSelectedFacilityType([]));
    dispatch(setSelectedGrade([]));
    dispatch(setSelectedExperienceType([]));
    setSelectedEndDate("");
    setSelectedStartDate("");

    if (navigator.permissions && navigator.permissions.query) {
      if (locationPermission === "prompt") {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permission) => {
            setLocationPermission(permission.state);
          });
      }
      if (locationPermission === "granted") {
        setLocationPermission("granted");
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error(error);
          }
        );
      } else if (locationPermission === "denied") {
        setDeniedError(
          "To set a new location, please enable location access in your device settings."
        );
      }
    } else {
      console.log("Permissions API not supported in this environment");
    }

  }, [selectedResourceType]);
  const locatioPremission = () => {
    if (deniedError) {
      setLocatonStatus(true);
    }
    else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          const geocoder = new window.google.maps.Geocoder();
          const latlng = { lat: latitude, lng: longitude };
          geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === "OK" && results.length > 0) {
              const address = results[0].formatted_address;
              setAddress(address);
            } else {
              console.error("Geocoder failed due to:", status);
            }
          });
        },
        (error) => {
          console.error(error.message);
        }
      );
    }
    getGoogleAddressStreetOne();
  };

  // ------------------end location
  if (resource_type) {
    if (Object.keys(resource_type).length) {
      let data = Object.keys(resource_type).map((item) => {
        return {
          name: capitalizeWords(item.replaceAll("_", " "))?.slice(0, -1),
          value: item?.slice(0, -1),
          id: item?.charAt(item.length - 1)
        };
      });
      // Here added new resource type in dropdown(Which is Group)
      const grp = [{ name: "Group", value: "group", id: "group" }]
      resource_type_data = [...data, ...grp] || [];
    }
  }
  const handleZipcode = (selectedList) => {
    if (selectedList.length > 0) {
      const lastSelected = selectedList[selectedList.length - 1];
      setAddressType(lastSelected.value);
    }
  };

  const getChild = () => {
    var payload = {
      resource_id: "",
    };
    makePostRequest(URLS.getChildList, null, payload, null).then((res) => {
      console.log("getChildList", res)
      dispatch(saveUserChildrens(res.data));
      dispatch(setViewableSearchResults([]));
      const filteredChildren = res.data?.filter((obj) => obj.childOwner === 1);
      const child = filteredChildren?.map((obj) => ({
        id: obj.id,
        grade_id: obj?.grade_id,
        grade_name: obj?.grade_name,
        name: obj.firstName + " " + obj.lastName,
        specialLearning: obj.special_learning.map((sl) => sl.tbl_special_learning_course.name),
        education_backgrounds: obj.tbl_child_education_backgrounds.map((sl) => sl.tbl_educational_course.name),
        instructionalStyles: obj.tbl_child_instructional_styles.map((sl) => sl.tbl_instructional_style.name),
        learning_style: obj.tbl_child_learning_styles.map((sl) => sl.tbl_learning_style.name),
      }));
      setChildFor(child)
    });
  }
  useEffect(() => {
    if (isMounted) {
      getChild()
      getSubscriptionPlanList();
      localStorage.removeItem("chatgptmsg")
      if (Object.keys(resource_type).length) {
      } else {
        dispatch(getFilterOptions());
      }
    }
    document.body.classList.add("searchpage");
    window.scrollTo({ top: 0, behavior: "smooth" });
    return () => {
      document.body.classList.remove("searchpage");
    };
  }, [isMounted]);


  useEffect(() => {
    setIsMounted(true);
    return () => {
      cancelRequest()
      setIsMounted(false);
    };
  }, []);
  // get data from chatgpt
  const getResultsFromChatGPT = async (query, types, grade, keywords
    // , start_date, end_date
  ) => {
    // console.log("start end", start_date, end_date)
    console.log("gradeschat", grade)
    if (window.location.pathname === "/search-page") {
      var returnData = false
      setDownSpin(true);
      let retryCount = 4;
      while (retryCount > 0) {
        let source = null;
        source = axios.CancelToken.source();
        setCancelTokenSource(source);
        try {
          setDownSpin(true);
          let responses = await SearchService.searchChatGPT2(query, source.token);
          console.log("chatgpt responses", responses);
          if (typeof responses === "object") {
            dispatch(getSearchAlgoliaCount({ chatGpt: parseInt(localStorage.getItem("chatgptCount")) + 1 }))
            localStorage.setItem("chatgptCount", parseInt(localStorage.getItem("chatgptCount")) + 1);
            console.log("responses::", responses)
            addChatGptData(responses, types, grade, keywords
              // ,start_date, end_date
            )
            setDownSpin(false);
            return;
          } else if (responses === false) {
            returnData = true
            break;
          } else {
            retryCount--;
          }
        } catch (error) {
          console.error("error", error);
          retryCount--;
        }
      }
      setDownSpin(false);
      return returnData
    }
  };

  const addChatGptData = async (data, types, grade, keywords
    // , start_date, end_date
  ) => {
    const experiencArr = [];
    selectedExperienceType.forEach((val) => {
      experiencArr.push(val.name)
    })
    const facilityArr = [];
    selectedFacilityType.forEach((val) => {
      facilityArr.push(val.name)
    })
    const organizationArr = [];
    selectedOrganizationType.forEach((val) => {
      organizationArr.push(val.name)
    })

    function getLocation(locationObj) {
      if (typeof locationObj === 'string') {
        return locationObj;
      } else if (typeof locationObj === 'object') {
        return `${locationObj.address}, ${locationObj.city}, ${locationObj.state} ${locationObj.zipcode}` || `${locationObj.Address}, ${locationObj.ZipCode}`;
      } else {
        return '';
      }
    }
    let urlData = [];
    data?.map((val) => {
      Object.keys(val).forEach(key => {
        if (key.endsWith('URL') || key.endsWith('url') || key.endsWith('Website') || key.endsWith('website')) {
          // websiteUrl = data[key];
          urlData.push(val[key])
        }
      });
    })
    console.log("urlData", urlData)
    let source = null;
    source = axios.CancelToken.source();
    setCancelTokenSource(source);
    let resultsFound;
    let payload = []
    data?.forEach((val, i) => {
      if (val.Name || val.name || val.EventName || val.eventName || val.event_name) {
        payload.push({
          saveId: parseInt(localStorage.getItem("chatGptId")),
          resource_type: selectedResourceType.replaceAll(" ", "_") || "",
          title: val.Name || val.name || val.EventName || val.eventName || val.event_name,
          description: (val.description ? val.description : val.Description) || val.brief_description,
          purchase_link: urlData[i] || "",
          about_author: val?.aboutAuthor || val?.about_author || val?.aboutauthor || val?.author?.name || "",
          short_description: val.longDescription || val.long_description || val.longdescription || "",
          author: val.author?.bio || val.author || val?.Author || "",
          keywords: [keywords],
          grades: grade || [],
          organisation_id: (resourceId === "1" && [types]) || [],
          experience_id: (resourceId === "3" && [types]) || [],
          facility_id: (resourceId === "7" && [types]) || [],
          support_id: ((resourceId === "4" || resourceId === "5") && [types]) || [], // 5 also go in this one
          latitude: zip_code_status === true ? latitude  : "",
          longitude: zip_code_status === true ? longitude : "",
          define_address: (val.Address ? getLocation(val.Address) : getLocation(val?.Location)) || getLocation(val?.address) || getLocation(val?.location),
          location_type: selectedLocationType || [],
          customer_type: selectedCustomerType || [],
          learning_pillars: selectedLearningPillars || [],
          // "start_date":start_date,
          //   "end_date": end_date
        })
      }
    })
    console.log("payload", payload)
    if (window.location.pathname === "/search-page") {
      try {
        setDownSpin(true);
        const res = await makePostRequestcancle(URLS.createChatGptResource, null, payload, null, source.token);
        console.log("res", res)
        if (res === true) {
          resultsFound = true;
        } else {
          if (res.code === 201 && shareBy === "global") {
            setChatgptData((current) => [
              ...current,
              ...res?.data,
            ])
          } else {
            // if (parseInt(localStorage.getItem("chatgptDataCount")) < SearchResultItam) {
            // newChatGPT(false)
            // }
          }
        }
      } catch (error) {
        console.log("error::", error)
      }
    } else {

    }
    return resultsFound
  }

  const getResultsFromChatGPT2 = async (query, types, grade, id, keyword) => {
    if (window.location.pathname === "/search-page") {
      let returnData = false
      let staticObject = {
        "title": keywordsData,
      };
      addChatGptCJ(staticObject, types, grade, id, true, 100, keyword)
      setDownSpin(true);
      let retryCount = 4;
      while (retryCount > 0) {

        let source = null;
        source = axios.CancelToken.source();
        setCancelTokenSource(source);
        try {
          setDownSpin(true);
          let responses = await SearchService.searchChatGPT2(query, source.token);
          console.log("responses", responses)
          if (typeof responses === "object") {
            let nofilate = []
            dispatch(getSearchAlgoliaCount({ chatGpt: parseInt(localStorage.getItem("chatgptCount")) + 1 }))
            localStorage.setItem("chatgptCount", parseInt(localStorage.getItem("chatgptCount")) + 1)
            let data = { ...responses };
            let breakPoint = false
            for (const key in responses) {
              if (typeof responses[key] === "object") {
                if (Array.isArray(responses[key])) {
                  const innerArr = responses[key];
                  for (let i = 0; i < innerArr.length; i++) {
                    const element = innerArr[i];
                    element.isChatGPT = true;
                    data = { ...element };
                    if (data.title || data?.Title) {
                      const resultsFound = await addChatGptCJ(data, types, grade, id, true, 65, keyword);
                      if (resultsFound === true) {
                        breakPoint = true;
                        returnData = true
                        break;

                      }
                      else if (resultsFound.length > 0) {
                        nofilate.push(resultsFound)
                      }
                    }
                  }
                } else {
                  const element = responses[key];
                  element.isChatGPT = true;
                  data = { ...element };
                  if (data.title || data?.Title) {
                    const resultsFound = await addChatGptCJ(data, types, grade, id, true, 65, keyword);
                    if (resultsFound === true) {
                      breakPoint = true
                      returnData = true
                      break;
                    }
                    else if (resultsFound.length > 0) {
                      nofilate.push(resultsFound)
                    }
                  }
                }
              }
            }
            if (nofilate.length > 0 && breakPoint === false) {
              for (var n = 0; n < nofilate.length; n++) {
                const resultsFound = await NonAfilate(nofilate[n]);
                if (resultsFound === true) {
                  returnData = true
                  break;

                }
              }
            }
            setDownSpin(false);
            return returnData;
          } else if (responses === false) {
            returnData = true
            break;
          } else {
            retryCount--;
          }
        } catch (error) {
          console.error("error", error);
          retryCount--;
        }
      }
      setDownSpin(false);
      return returnData
    }
  };
  // get data from CJ
  const addChatGptCJ = async (data, type, grade, id, status, pr, keyword) => {
    let source = null;
    source = axios.CancelToken.source();
    setCancelTokenSource(source);
    let resultsFound = [];
    if (parseInt(localStorage.getItem("chatgptDataCount")) < SearchResultItam && window.location.pathname === "/search-page") {
      const payload = [
        {
          "saveId": parseInt(localStorage.getItem("chatGptId")),
          "resource_type": "Curriculum_Content",
          "title": data.title || data?.Title,
          "keywords": [keyword],
          "grades": grade || [],
          "resource_contents": [type],
          "organisation_id": [],
          "experience_id": [],
          "funding_id": [],
          "facility_id": [],
          "support_id": [],
          "latitude": latitude || '',
          "longitude": longitude || '',
          "define_address": null,
          "timeframe": selectedTimeframe || [],
          "instruction_style": selectedInstructionStyle || [],
          "location_type": [],
          "customer_type": [],
          "learning_pillars": selectedLearningPillars || [],
          "purchase_link": data?.purchase_link || "",
          "child_id": id || [],
          "total_percentage": pr
        }
      ]
      try {
        setDownSpin(true)
        console.log("CJ payload::", payload)
        const res = await makePostRequestcancle(URLS.CreateChatGptCJ, null, payload, null, source.token)
        console.log("Cj backend res::", res)
        if (res === true) {
          resultsFound = true;
        } else {
          if (res.code === 201 && shareBy === "global") {
            setChatgptData((current) => [
              ...current,
              ...res?.data,
            ])
          }
          if (res.data.length <= 0 || status) {
            setDownSpin(true)
            const response = await makePostRequestcancle(URLS.CreateChatGptAmazon, null, payload, null, source.token)
            console.log("Amazon backend res::", response)
            if (response === true) {
              resultsFound = true;
            } else {
              if (response.code === 201 && response?.data?.length > 0  && shareBy === "global") {
                setChatgptData((current) => [

                  ...current,
                  ...response?.data,
                ])
              } else if (response?.data?.length <= 0) {
                resultsFound = payload
              }
            }

          }
        }
      } catch (error) {
        console.log("error::", error)
      }
      return resultsFound;
    } else {
      return true;
    }

  }

  const NonAfilate = async (payLoad) => {
    if (parseInt(localStorage.getItem("chatgptDataCount")) < SearchResultItam && window.location.pathname === "/search-page") {
      let source = null;
      source = axios.CancelToken.source();
      setCancelTokenSource(source);
      let resultsFound = false;
      try {
        setDownSpin(true)
        const onCJ = await makePostRequestcancle(URLS.CreateChatGptnonCJ, null, payLoad, null, source.token)
        console.log("nonCJ backend res::", onCJ)
        if (onCJ === true) {
          resultsFound = true;
        } else {
          if (onCJ.code === 201 && shareBy === "global") {
            setChatgptData((current) => [

              ...current,
              ...onCJ?.data,
            ])
          }
        }

      } catch (error) {
        console.log("error", error)
      }
      return resultsFound;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (resourceId === "2") {
      const uniqueRecordsMap = new Map();
      chatgptData.forEach(obj => {
        if (obj?.saveId === parseInt(localStorage.getItem("chatGptId")) && obj?.resource_type === parseInt(resourceId)) {
          const title = obj.resource_title;
          const isbn = obj.isbn
          const compoundKey = title + (isbn ? '|' + isbn : '');
          if (!uniqueRecordsMap.has(compoundKey)) {
            uniqueRecordsMap.set(compoundKey, obj);
          }
        }
      });
      const uniqueRecordsArray = Array.from(uniqueRecordsMap.values());
      if (uniqueRecordsArray.length !== 0) {
        setUniqueChatgpt(uniqueRecordsArray)
      }
    } else {
      const uniqueIds = new Set();
      const data = chatgptData.filter(item => {
        const id = item.id || parseInt(item.objectID);
        if (!uniqueIds.has(id)) {
          uniqueIds.add(id);
          return true;
        }
        return false;
      });
      if (data.length !== 0) {
        setUniqueChatgpt(data)
      }
    }
    const searchAlgoliaCount = userStore?.searchAlgoliaCount?.chatGpt || 0;
    console.log("searchAlgoliaCount", searchAlgoliaCount)
    if ((downSpin === false && queryStatus && (searchAlgoliaCount < 5)) ||
      (downSpin === false && (searchAlgoliaCount < 5) && (resourceId === "7" || resourceId === "1" || resourceId === "3"|| resourceId === "4" || resourceId === "5"))) {
      if ((searchAlgoliaCount >= 1 && chatgptData?.length < 2 && resourceId === "2")) {
        setChatGPTLoader(false)
        setDownSpin(false)
        cancelRequest();
        localStorage.setItem("chatgptmsg", "We have not yet found any results for your search.")
      }
      else if (chatgptDataCount < SearchResultItam && (userData.family_faith_background === "Very Important" && userData.faith_affiliation) && shareBy === "global") {
        console.log("11111111111111111111111111")
        setDownSpin(true)
        if (queryStatus === "AND") {
          newChatGPT(true)
        } else {
          newChatGPT(false)
        }
      } else if (chatgptDataCount < SearchResultItam && searchAlgoliaCount < 5 && shareBy === "global") {
        console.log("222222222222222222")
        setDownSpin(true)
        newChatGPT(false)
      } else if (chatgptDataCount >= SearchResultItam && userStore?.searchAlgoliaCount?.chatGpt >= 5) {
        setDownSpin(false)
      }
    }
  }, [chatgptData, downSpin])
  useEffect(() => {
    const uniqueDummyMap = new Map();
    dummy.forEach(obj => {
      const key = obj.id || parseInt(obj.objectID);
      if (!uniqueDummyMap.has(key)) {
        uniqueDummyMap.set(key, obj);
      }
    });
    const uniqueDummyArray = Array.from(uniqueDummyMap.values()).slice(0, 24);
    const filteredUniqueChatgpt = uniqueChatgpt.filter(obj => {
      const key = obj.id || parseInt(obj.objectID);
      return !uniqueDummyMap.has(key);
    });
    const uniqueChatgptMap = new Map();
    filteredUniqueChatgpt.forEach(obj => {
      const key = obj.id || parseInt(obj.objectID);
      if (!uniqueChatgptMap.has(key)) {
        uniqueChatgptMap.set(key, obj);
      }
    });
    const uniqueChatgptArray = Array.from(uniqueChatgptMap.values()).slice(0, SearchResultItam);
    const combinedArray = [...uniqueDummyArray, ...uniqueChatgptArray];
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem("chatgptDataCount", combinedArray.length - dummy.length);
    } else {
      console.error('localStorage is not available');
    }
    (combinedArray.length - dummy.length <= SearchResultItam) && setChatgptDataCount(combinedArray.length - dummy.length)
    dispatch(saveSearchResults(combinedArray));

    const viewableResults = combinedArray.slice(0, searchDataPage * itemsPerPage);
    // if (shareBy === "global"){
    dispatch(setViewableSearchResults(viewableResults));
    // }
    if (combinedArray.length - dummy.length >= SearchResultItam) {
      setIsLoadMoreVisible(false);
    }
  }, [dummy, uniqueChatgpt, searchDataPage, itemsPerPage, saveSearchResults, getChatgptCount]);


  // this function is use for fetch number of result
  const showMoreResults = () => {
    const combinedArray = [...dummy, ...uniqueChatgpt];
    const uniqueRecordsSet = new Set(combinedArray.map(obj => obj.id || obj.objectID));
    const uniqueRecordsArray = Array.from(uniqueRecordsSet).map(key => {
      return combinedArray.find(obj => obj.id === key || obj.objectID === key);
    });
    if (uniqueRecordsArray.length === viewableSearchResults.length && uniqueChatgpt < SearchResultItam && shareBy === "global") {
      // If queryStatus is "OR", call newChatGPT with false, otherwise with true
      newChatGPT(queryStatus !== "OR");
      setIsLoadMoreVisible(true);
    } else if (uniqueRecordsArray.length === viewableSearchResults.length && uniqueChatgpt >= SearchResultItam) {
      setIsLoadMoreVisible(false);
    }
    setSearchDataPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    if (viewableSearchResults.length > 0) {
      setChatGPTLoader(false)
    }
    if ((dummy.length === viewableSearchResults.length && shareBy !== "global") ||
      (dummy.length === viewableSearchResults.length && (resourceId === "4" || resourceId === "5" || resourceId === "6")) ||
      ((resourceId === "1" || resourceId === "2" || resourceId === "3" || resourceId === "7") && shareBy === "global" && chatgptDataCount >= SearchResultItam)) {
      setIsLoadMoreVisible(false);
    } else if (chatgptDataCount < SearchResultItam && shareBy === "global") {
      setIsLoadMoreVisible(true);
    }
  }, [viewableSearchResults])

  useEffect(() => {
    if (searchResults?.length <= searchDataPage * itemsPerPage) {
      dispatch(setViewableSearchResults(searchResults));
    } else {
      setIsLoadMoreVisible(true);
      let data = searchResults?.slice(0, searchDataPage * itemsPerPage);
      dispatch(setViewableSearchResults(data));
    }
  }, [searchDataPage, itemsPerPage]);

  // this function is use for select resource type
  const resourceTypeHandler = (e) => {
    if (e.length > 0) {
      const lastSelected = e[e.length - 1];
      setResorce(lastSelected.value);
      setResourceId(lastSelected.id);
      dispatch(setSelectedResourceType(lastSelected.value));
      let data = resource_type[lastSelected.value + lastSelected.id];
      dispatch(setSelectedResourceTypeFilterOptions(data));
    }
  };
  // this functio use for handle friend data
  const handleFriend = (e) => {
    let arr = [];
    e.length > 0 &&
      e.forEach((val, i) => {
        arr.push(val.id);
      });
    if ((arr.includes("all") && arr[arr.length - 1] === "all" && arr.length > 1) || (arr.length === 1 && arr.includes("all"))) {
      dispatch(setSelectedFriendFor([{ name: "All", id: "all" }]))
    } else {
      dispatch(setSelectedFriendFor(e.filter(item => item.id !== "all")))

    }
  }
  //Time frame options function
  const TimeFrame = (e) =>{
    const arr = []
    e.map((val) => {
      arr.push({id : val?.id , name : val?.value})
      dispatch(setSelectedTimeframe(arr))

    })
  }
  // this functio use for handle group data
  const handleGroup = (e) => {
    let arr = [];
    e.length > 0 &&
      e.forEach((val, i) => {
        arr.push(val.id);
      });
    if ((arr.includes("all") && arr[arr.length - 1] === "all" && arr.length > 1) || (arr.length === 1 && arr.includes("all"))) {
      dispatch(setSelectedGroupFor([{ name: "All", id: "all" }]))
    } else {
      dispatch(setSelectedGroupFor(e.filter(item => item.id !== "all")))
    }
  }

  useEffect(() => {
    if (Object.keys(resource_type).length > 0) {
      var data = []
      Object.keys(resource_type).map((item) => {
        if (item?.charAt(item.length - 1) == 2) {
          data.push({
            name: capitalizeWords(item.replaceAll("_", " "))?.slice(0, -1),
            value: item?.slice(0, -1),
            id: item?.charAt(item.length - 1)
          })
        }
      });
      setDummySelect(data)
    }
  }, [resource_type])

  useEffect(() => {
    if (dummySelect.length > 0) {
      resourceTypeHandler(dummySelect)
    }
  }, [dummySelect])

  useEffect(() => {
    if ((keywordsData && selectedResourceType?.length !== 0) && (selectedTypeOfContent?.length !== 0 ||
      selectedFacilityType?.length !== 0 || selectedFundingType.length !== 0 || selectedExperienceType.length !== 0
      || selectedOrganizationType.length !== 0 || selectedTypeOfSupport.length !== 0)) {
      setShowContent(true)
    }
    else if (keywordsData && resourceId === "group") {
      setShowContent(true)  // this is used for display address box in case of resource type Group
    } else {
      setShowContent(false)
    }
  }, [keywordsData, selectedTypeOfContent, selectedResourceType, selectedFacilityType, selectedExperienceType,
    selectedFundingType, selectedOrganizationType, selectedTypeOfSupport])

  // this function use for cancel
  const cancelRequest = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled by user.');
      setChatgptDataCount(0)
    }
  };
  // this function is use for get the status of subscription
  const hitSearchHandler = (status) => {
    if (status === true){
      setzip_code_status(true)
      
    }else{
      setzip_code_status(false)
    }
    // this is used for if resource type Group then we redirect this search to main group page(on /yourgroup page) 
    //  and sending all necessary data to group page by which that page dropdown and location will fill up autometically 
    // on load of page
    if (resourceId === "group") {
      navigate(`/yourgroup`, {
        state: {
          keyword: keywordsData, status: "search",
          distanceFromLocation: distanceFromLocation ? distanceFromLocation : km?.setting_value,
          address: address, Grade: selectedGrades, shareWith: shareSelected
        }
      })
    } else {
      dispatch(getSearchAlgoliaCount({ chatGpt: 0 }))
      cancelRequest()
      dispatch(setViewableSearchResults([]))
      setDummy([]);
      setChatgptData([]);
      setUniqueChatgpt([]);
      if (
        subscriptionPlan?.count !== 0 && subscriptionPlan?.count >= parseInt(subscriptionPlan?.total_count?.setting_value) &&
        subscriptionPlan?.data?.tbl_subscription_purchase_history
          ?.tbl_subscription_plan?.search_type === "limited"
      ) {
        setOpen(true);
        setAlertMessage({ "msg": 'You have reached the maximum number of searches allowed for your current plan. If you wish to upgrade your plan, please click "Continue."', "status": status, type: "all" })
      }
      else if (subscriptionPlan?.count !== 0 && subscriptionPlan?.count / parseInt(subscriptionPlan?.total_count?.setting_value) * 100 >= 80 &&
        subscriptionPlan?.data?.tbl_subscription_purchase_history
          ?.tbl_subscription_plan?.search_type === "limited") {
        setOpen(true);
        setAlertMessage({ "msg": `You have reached ${(subscriptionPlan?.count / parseInt(subscriptionPlan?.total_count?.setting_value) * 100).toFixed(2)}% of your search limit and have ${parseInt(subscriptionPlan?.total_count?.setting_value) - subscriptionPlan?.count} searches left .Would you like to upgrade your account?`, "status": status, type: "search" })
      }
      else {
        algoliaHit(status)
      }
    }
  };
  // this function use for get filter query paramater
  const algoliaHit = async (status) => {
    localStorage.removeItem("chatgptmsg")
    var a = 0;
    setOpen(false)
    let params = {};
    if (status || shareBy === "local") {
      if (
        resourceId === "1" || resourceId === "7" || resourceId === "6" || resourceId === "3" ||
        resourceId === "5" || resourceId === "2" ||
        resourceId === "4") {
        params.locationFilters = {
          lat: latitude,
          lng: longitude,
          radius: distanceFromLocation ? distanceFromLocation : km?.setting_value,
        };
      }
    }
    let filters = {};
    let keyword = {};
    let chatgpt = {};
    if (planStatus === true) {
      a++
      toastifyMessage("Subscription plan does not exist.", "error");
      return;
    }
    if (resourceId?.length) {
      filters.resource_type_id = resourceId || "";
    } else {
      a++;
      toastifyMessage("Please select resource type.", "error");
      return;
    }
    if (keywordsData) {
      chatgpt.keywords = keywordsData
      if (userType?.includes("Homeschool Teacher") || userType?.includes("ESA Parent")) {
        keyword.keywords = keywordsData + " " + "homeschool";
      } else {
        keyword.keywords = keywordsData || "";
      }
    } else {
      a++
      toastifyMessage("Please enter keyword.", "error");
      return;
    }
    if (selectedLearningPillars?.length) {
      chatgpt.selectedLearningPillars = selectedLearningPillars
      filters.learning_pillars = selectedLearningPillars || "";
    }
    if (selectedExperienceType?.length && resourceId === "3") {
      const arr = [];
      chatgpt.selectedExperienceType = selectedExperienceType
      selectedExperienceType.forEach((val) => {
        arr.push(val.name)
      })
      filters.experience_type = arr || "";
    } else if (resourceId === "3") {
      a++
      toastifyMessage("Please select experience type.", "error");
      return;
    }
    if (selectedTypeOfSupport?.length && (resourceId === "4" || resourceId === "5")) {
      const arr = [];
      chatgpt.selectedTypeOfSupport = selectedTypeOfSupport
      selectedTypeOfSupport.forEach((val) => {
        arr.push(val.name)
      })
      filters.support_type = arr || "";
    } else if (resourceId === "4" || resourceId === "5") {
      a++
      toastifyMessage("Please select support type.", "error");
      return;
    }
    if (selectedFriendFor?.length && shareBy === "friend") {
      if (selectedFriendFor.includes("all")) {
        filters.friend_for = friend_for.map(item => item.id)
      }
      else {
        filters.friend_for = selectedFriendFor || "";
      }
    }
    if (selectedGroupFor?.length && shareBy === "group") {
      if (selectedGroupFor.includes("all")) {
        filters.group_for = group_for.map(item => item.id)
      }
      else {
        filters.group_for = selectedGroupFor || "";
      }
    }
    if (selectedTypeOfContent?.length) {
      const arr = [];
      chatgpt.selectedTypeOfContent = selectedTypeOfContent
      selectedTypeOfContent.forEach((val) => {
        arr.push(val.name)
      })
      filters.content_type = arr || "";
    } else if (resourceId === "2") {
      a++
      toastifyMessage("Please select content type.", "error");
      return;
    }
    if (selectedFacilityType?.length) {
      const arr = [];
      chatgpt.selectedFacilityType = selectedFacilityType
      selectedFacilityType.forEach((val) => {
        arr.push(val.name)
      })
      filters.facility_type = arr || "";
    } else if (resourceId === "7") {
      a++
      toastifyMessage("Please select facility type.", "error");
      return;
    }
    if (selectedFundingType?.length) {
      const arr = [];
      selectedFundingType.forEach((val) => {
        arr.push(val.name)
      })
      filters.funding_type = arr || "";
    } else if (resourceId === "6") {
      a++
      toastifyMessage("Please select funding type.", "error");
      return;
    }
    if (selectedChildFor?.length > 0 && childStyle.length > 0) {
      chatgpt.selectedChildFor = selectedChildFor
      let style = Array.from(new Set(childStyle.flatMap(person => person.specialLearning)))
      if (style.length > 0) {
        filters.child_special = style
      }
    }
    if (selectedOrganizationType?.length) {
      const arr = [];
      chatgpt.selectedOrganizationType = selectedOrganizationType
      selectedOrganizationType.forEach((val) => {
        arr.push(val.name)
      })
      filters.organisation_type = arr || "";
    } else if (resourceId === "1") {
      a++
      toastifyMessage("Please select organization type.", "error");
      return;
    }
    if (selectedTypeOfSupport?.length) {
      const arr = [];
      selectedTypeOfSupport.forEach((val) => {
        arr.push(val.name)
      })
      filters.support_type = arr || "";
    } else if (resourceId === "4" || resourceId === "5") {
      a++
      toastifyMessage("Please select support type.", "error");
      return;
    }
    if (selectedGrades?.length) {
      chatgpt.selectedGrades = selectedGrades
      filters.grade = selectedGrades || "";
    }
    if (selectedLocationType?.length) {
      filters.location_type = selectedLocationType || "";
    }
    if (selectedStartDate) {
      let date_start = moment(moment(selectedStartDate).format("YYYY-MM-DD") + "T00:00:00")
      const utcString_start = date_start.utc(userData.timezone).unix();
      filters.start_date = utcString_start || "";
      // chatgpt.start_date =  moment(selectedStartDate).format("YYYY-MM-DD")
    }
    if (selectedEndDate) {
      let date_start = moment(moment(selectedEndDate).format("YYYY-MM-DD") + "T00:00:00")
      const utcString_start = date_start.utc(userData.timezone).unix();
      filters.end_date = utcString_start || "" ;
      // chatgpt.end_date = moment(selectedEndDate).format("YYYY-MM-DD")
    }
    if (selectedTimeframe?.length) {
      filters.timeframe = selectedTimeframe || "";
    }
    if (selectedInstructionStyle?.length) {
      filters.instructional_style = selectedInstructionStyle || "";
    }
    if (selectedCustomerType?.length) {
      filters.customer_type = selectedCustomerType || "";
    }
    if (selectedDual?.length) {
      filters.dual_enrollment_resource = selectedDual || "";
    }
    if (selectedAccredited?.length) {
      filters.accredited_resource = selectedAccredited || "";
    }
    if ((userData.faith_affiliation && userData.faith_affiliation !== undefined) && userData.family_faith_background === "Very Important") {
      filters.faith_affiliation = userData.faith_affiliation || "";
    }
   

    dispatch(clearChatgptSearch(chatgpt))
    if (a === 0) {
      setChatGPTLoader(true);
      if ((shareBy === "global" && (resourceId === "1" || resourceId === "7" || resourceId === "3" || resourceId === "4" || resourceId === "5"))) {
        for (let i = 0; i < 1; i++) {
          newChatGPT(false, chatgpt);
        }
      }
      let results;
      let source = null;
      source = axios.CancelToken.source();
      setCancelTokenSource(source);
      SearchService.makeDynamicQuery(filters, params, keyword, userData.family_faith_background, userData.child_education_character_value, source.token, keywordsData, 
        status === true ? zipcode : "")
        .then((queryResults) => {
          results = queryResults;
          const promises = [];
          if (shareBy === "group") {
            const groupArr = shareBy === "group" ? (selectedGroupFor.includes("all") ? [] : selectedGroupFor || []) : [];
            const friendArr = shareBy === "friend" ? (selectedFriendFor.includes("all") ? [] : selectedGroupFor || []) : [];
            const data = {
              "shared_by": shareBy,
              "friends": friendArr,
              "groups": groupArr,
            };
            promises.push(makePostRequest(URLS.getDataFromFriendAndGroup, null, data, null)
              .then((res) => {
                const filteredResults = results.filter(result => res.data.includes(parseInt(result.objectID)));
                results = filteredResults;
              })
              .catch((error) => {
                console.log("error::", error);
              }));
          }
          promises.push(makeGetRequest(URLS.getsearchrecord, null, null, null)
            .then((res) => {
              const arr = res?.data?.share.map(val => val.resource_id) || [];
              const save = res?.data?.save.map(val => val.id) || [];
              const dump = results.map(result => {
                const isShared = arr.includes(parseInt(result.objectID));
                const isSaved = save.includes(parseInt(result.objectID));
                const viewableByFriends = isShared ? "yes" : "no";
                const saveStatus = isSaved ? "yes" : "no";
                return {
                  ...result,
                  viewable_by_my_friends: viewableByFriends,
                  save: saveStatus
                };
              });
              setDummy(dump);
              getUserSearch(dump, chatgpt);
            })
            .catch((error) => {
              console.log("res:::", error);
            }));
          return Promise.all(promises);
        })
        .then(() => {
          if (Array.isArray(results) && (resourceId === "2" || resourceId === "1" || resourceId === "7" || resourceId === "3" || resourceId === "4" || resourceId === "5" ) && shareBy === "global") {
            setChatGPTLoader(true);
          } else {
            setChatGPTLoader(false);
          }
          //This is used for display message if no data found after search
          if (results?.length <= 0 && (shareBy === "friend" || shareBy === "Community" || shareBy === "local" || shareBy === "group") &&
            (resourceId === "2" || resourceId === "1" || resourceId === "7" || resourceId === "3" || resourceId === "4" || resourceId === "5" || resourceId === "6")) {
            setDataFound(true);
          }
          if (results?.length <= 0 && (shareBy === "global" || shareBy === "friend" || shareBy === "Community" || shareBy === "local" || shareBy === "group") && (resourceId === "6")) {
            setDataFound(true);   //This is used for display message if no data found after search
          }
          else if (results.length > 1) {
            setChatGPTLoader(false);
          }
        });
    }
  }
  //  this function is use for chatgpt query
  const newChatGPT = async (status, query) => {

    let chatgpt = query || searchChatgpt
    // if (shareBy === "global"){
    // console.log("chatgpt", chatgpt)
    var query;
    if (resourceId === "2") {
      let user_role = "";
      if (userData?.tbl_users_roles?.length > 0) {
        userData?.tbl_users_roles?.forEach((val, index) => {
          user_role += val.tbl_user_role.role_name;
          if (index < userData?.tbl_users_roles?.length - 1) {
            user_role += " or ";
          }
        });
      }
      let breakPoint = false
      if (chatgpt?.selectedTypeOfContent?.length > 0) {
        for (var k = 0; k < chatgpt?.selectedTypeOfContent?.length; k++) {
          let content_type = chatgpt?.selectedTypeOfContent[k].name;
          if (chatgpt?.selectedGrades && chatgpt?.selectedGrades?.length > 0) {
            let usegrade = []
            for (var g = 0; g < chatgpt?.selectedGrades?.length; g++) {
              if (chatgpt?.selectedChildFor?.length > 0) {
                for (const obj of userStore?.userChildrens || []) {
                  if (chatgpt?.selectedChildFor.includes(obj.id) && obj.grade_name === chatgpt?.selectedGrades[g]) {
                    const specialLearningList = Array.from(new Set(
                      childStyle
                        .filter(person => person.id === obj.id) // Filter based on the id you want to match
                        .flatMap(person => person.specialLearning)
                    ));
                    let style = "";
                    if (specialLearningList?.length > 0) {
                      specialLearningList.forEach((val, index) => {
                        style += val;
                        if (index < specialLearningList.length - 1) {
                          style += " or ";
                        }
                      });
                    }
                    if (status) {
                      usegrade.push(chatgpt?.selectedGrades[g])
                      query = `I'm looking for [${chatgpt?.keywords}] resources suitable for [${chatgpt?.selectedGrades[g]}] students with [${style}]. Can you provide a list of [CURRICULUM CONTENT] such as [${content_type}], including titles, brief descriptions in JSON format? Additionally, please ensure that either ${(userData.family_faith_background === "Very Important" && userData.faith_affiliation) ? `with a [${userData.faith_affiliation}] background` : ""} or [${user_role}] is applicable.`
                      setQueryStatus("OR")
                      const res = await getResultsFromChatGPT2(query, content_type, [chatgpt?.selectedGrades[g]], [obj.id], chatgpt?.keywords);
                      if (res === true) {
                        breakPoint = true
                        break;
                      }
                    } else {
                      usegrade.push(chatgpt?.selectedGrades[g])
                      query = `I'm looking for [${chatgpt?.keywords}] resources for [${chatgpt?.selectedGrades[g]}] students with [${style}] ${(userData.family_faith_background === "Very Important" && userData.faith_affiliation) ? `with a [${userData.faith_affiliation}] background` : ""}. Can you provide a list of [CURRICULUM CONTENT] such as [${content_type}] that is useful for [${user_role}], including titles, brief descriptions in JSON format?`
                      setQueryStatus("AND")
                      const res = await getResultsFromChatGPT2(query, content_type, [chatgpt?.selectedGrades[g]], [obj.id], chatgpt?.keywords);
                      if (res === true) {
                        breakPoint = true
                        break;
                      }

                    }
                  }
                }
              }
              else {
                if (status) {
                  usegrade.push(chatgpt?.selectedGrades[g])
                  query = `I'm looking for [${chatgpt?.keywords}] resources suitable for [${chatgpt?.selectedGrades[g]}] students. Can you provide a list of [CURRICULUM CONTENT] such as [${content_type}], including titles, brief descriptions in JSON format? Additionally, please ensure that either ${(userData.family_faith_background === "Very Important" && userData.faith_affiliation) ? `with a [${userData.faith_affiliation}] background` : ""} or [${user_role}] is applicable.`
                  setQueryStatus("OR")
                  const res = await getResultsFromChatGPT2(query, chatgpt?.content_type, [chatgpt?.selectedGrades[g]], [], chatgpt?.keywords);
                  if (res === true) {
                    breakPoint = true
                    break;
                  }
                } else {
                  usegrade.push(chatgpt?.selectedGrades[g])
                  query = `I'm looking for [${chatgpt?.keywords}] resources for [${chatgpt?.selectedGrades[g]}] ${(userData.family_faith_background === "Very Important" && userData.faith_affiliation) ? `with a [${userData.faith_affiliation}] background` : ""}. Can you provide a list of [CURRICULUM CONTENT] such as [${content_type}] that is useful for [${user_role}], including titles, brief descriptions in JSON format?`
                  setQueryStatus("AND")
                  const res = await getResultsFromChatGPT2(query, content_type, [chatgpt?.selectedGrades[g]], [], chatgpt?.keywords);
                  if (res === true) {
                    breakPoint = true
                    break;
                  }
                }
              }
            }
            if (breakPoint === false) {
              const other = selectedGrades.filter(grade => !usegrade.includes(grade));
              for (var otherGrade = 0; otherGrade < other?.length; otherGrade++) {
                if (status) {
                  query = `I'm looking for [${chatgpt?.keywords}] resources suitable for [${other[otherGrade]}] students. Can you provide a list of [CURRICULUM CONTENT] such as [${content_type}], including titles, brief descriptions in JSON format? Additionally, please ensure that either ${(userData.family_faith_background === "Very Important" && userData.faith_affiliation) ? `with a [${userData.faith_affiliation}] background` : ""} or [${user_role}] is applicable.`
                  setQueryStatus("OR")
                  const res = await getResultsFromChatGPT2(query, content_type, [other[otherGrade]], [], chatgpt?.keywords);
                  console.log("res::", res)
                  if (res === true) {
                    break;
                  }

                } else {
                  query = `I'm looking for [${chatgpt?.keywords}] resources for [${other[otherGrade]}] ${(userData.family_faith_background === "Very Important" && userData.faith_affiliation) ? `with a [${userData.faith_affiliation}] background` : ""}. Can you provide a list of [CURRICULUM CONTENT] such as [${content_type}] that is useful for [${user_role}], including titles, brief descriptions in JSON format? `
                  setQueryStatus("AND")
                  const res = await getResultsFromChatGPT2(query, content_type, [other[otherGrade]], [], chatgpt?.keywords);
                  console.log("res::", res)
                  if (res === true) {
                    break;
                  }
                }
              }
            } else {
              break;
            }
          } else {
            if (status) {
              query = `I'm looking for [${chatgpt?.keywords}] resources. Can you provide a list of [CURRICULUM CONTENT] such as [${content_type}], including titles, brief descriptions in JSON format? Additionally, please ensure that either ${(userData.family_faith_background === "Very Important" && userData.faith_affiliation) ? `with a [${userData.faith_affiliation}] background` : ""} or [${user_role}] is applicable.`
              setQueryStatus("OR")
              const res = await getResultsFromChatGPT2(query, content_type, [], [], chatgpt?.keywords);
              console.log("res::", res)
              if (res === true) {
                break;
              }
            } else {
              query = `I'm looking for [${chatgpt?.keywords}] resources ${(userData.family_faith_background === "Very Important" && userData.faith_affiliation) ? `with a [${userData.faith_affiliation}] background` : ""}. Can you provide a list of [CURRICULUM CONTENT] such as [${content_type}] that is useful for [${user_role}], including titles, brief descriptions in JSON format?`
              setQueryStatus("AND")
              const res = await getResultsFromChatGPT2(query, content_type, [], [], chatgpt?.keywords);
              console.log("res::", res)
              if (res === true) {
                break;
              }
            }
          }
        }
      }
    }
    else if (resourceId === "3") {
      console.log("chatgpt?.selectedGrades", chatgpt?.selectedGrades)
      for (var j = 0; j < chatgpt?.selectedExperienceType?.length; j++) {
        let experience_type = chatgpt?.selectedExperienceType[j].name;
        let all_grade = "";
        let gradess = [];
        if (chatgpt?.selectedGrades && chatgpt?.selectedGrades.length > 0) {
          chatgpt?.selectedGrades?.forEach((val, index) => {
             all_grade += val;
            if (index < chatgpt?.selectedGrades?.length - 1) {
              all_grade += " or ";
            }
          });
          for (var g = 0; g < chatgpt?.selectedGrades?.length; g++) {
            gradess?.push(chatgpt?.selectedGrades[g]);
          }
            query = `I'm looking for a list of "${chatgpt?.keywords}" ${selectedResourceType} ,such as ${experience_type} located around the ${zipcode ? zipcode : address ? address : userData?.zipcode} Zip Code ,for ${all_grade} student. Can you provide a list of ${experience_type} ,including name, location, address, and secure official websites URL (HTTPS) for each ${experience_type}, descriptions in JSON format ?`
           // for (var g = 0; g < chatgpt?.selectedGrades?.length; g++) {
           const res = await getResultsFromChatGPT(query, experience_type, gradess, chatgpt?.keywords, chatgpt?.start_date, chatgpt?.end_date);
           if (res === true) {
              break;
            }
          // }
        }
        else {
          query = `I'm looking for a list of "${chatgpt?.keywords}" ${selectedResourceType} ,such as ${experience_type} located around the ${zipcode ? zipcode : address ? address : userData?.zipcode} Zip Code. Can you provide a list of ${experience_type} ,including name, location, address, and secure official websites URL (HTTPS) for each ${experience_type}, descriptions in JSON format ?`
           const res = await getResultsFromChatGPT(query, experience_type, [], chatgpt?.keywords, chatgpt?.start_date, chatgpt?.end_date);
           if (res === true) {
            break;
          }
        }
      }
    }
    else if (resourceId === "7" || resourceId === "1") {
      let type = []
      if (chatgpt?.selectedOrganizationType?.length) {
        type = chatgpt?.selectedOrganizationType
      } else {
        type = chatgpt?.selectedFacilityType
      }
      if (type?.length > 0) {
        for (var j = 0; j < type?.length; j++) {
          let types = type[j].name;
          if (chatgpt?.selectedGrades && chatgpt?.selectedGrades.length > 0) {
            let all_grade = "";
            let gradess = [];
          chatgpt?.selectedGrades?.forEach((val, index) => {
             all_grade += val;
            if (index < chatgpt?.selectedGrades?.length - 1) {
              all_grade += " or ";
            }
          });
          for (var g = 0; g < chatgpt?.selectedGrades?.length; g++) {
            gradess?.push(chatgpt?.selectedGrades[g]);
          }
            // for (var g = 0; g < chatgpt?.selectedGrades?.length; g++) {
              query = `Generate a list of ${types} for ${all_grade} students  ${selectedLocationType?.includes("In Person") || selectedLocationType?.length === 0 ? `,located around the ${zipcode ? zipcode : address ? address : userData?.zipcode} zip code area` : ""} with a focus on "${chatgpt?.keywords}" .Include the name, location, address, brief description, and secure official websites URL (HTTPS) for each ${types} in JSON format.`
              const res = await getResultsFromChatGPT(query, types, gradess, chatgpt?.keywords);
              if (res === true) {
                break;
              // }
            }
          }
          else {
            query = `Generate a list of ${types} ${selectedLocationType?.includes("In Person") || selectedLocationType?.length === 0 ? `located around the ${zipcode ? zipcode : address ? address : userData?.zipcode} zip code area`:""} with a focus on "${chatgpt?.keywords}".Include the name, location, address, brief description, and secure official websites URL (HTTPS) for each ${types} in JSON format.`
            const res = await getResultsFromChatGPT(query, types, [], chatgpt?.keywords);
            if (res === true) {
              break;
            }
          }
        }
      }

    }  else if ((resourceId === "4" || resourceId === "5")) {
      // console.log("hi")
      // console.log("chatgpt", chatgpt)
      for (var j = 0; j < chatgpt?.selectedTypeOfSupport?.length; j++) {
        let support_type = chatgpt?.selectedTypeOfSupport[j].name;
        if (chatgpt?.selectedGrades && chatgpt?.selectedGrades.length > 0) {
          let all_grade = "";
          let gradess = [];
          chatgpt?.selectedGrades?.forEach((val, index) => {
             all_grade += val;
            if (index < chatgpt?.selectedGrades?.length - 1) {
              all_grade += " or ";
            }
          });
          for (var g = 0; g < chatgpt?.selectedGrades?.length; g++) {
            gradess?.push(chatgpt?.selectedGrades[g]);
          }
          // for (var g = 0; g < chatgpt?.selectedGrades?.length; g++) {
            query = `I'm looking for a list of ${selectedResourceType}  ${support_type}, with a focus on '${chatgpt?.keywords}' ${selectedLocationType?.includes("In Person") || selectedLocationType?.length === 0 ? `located around the ${zipcode ? zipcode : address ? address : userData?.zipcode} Zip Code ,` : ""}for ${all_grade} student. Can you provide a list of ${support_type} ,including name, location, address, and secure official websites URL (HTTPS) for each ${support_type}, descriptions in JSON format ?`

           const res = await getResultsFromChatGPT(query, support_type, gradess, chatgpt?.keywords, chatgpt?.start_date, chatgpt?.end_date);
            if (res === true) {
              break;
            }
          // }
        }
        else {
          query = `I'm looking for a list of ${support_type} for a ${selectedResourceType} ${selectedLocationType?.includes("In Person") || selectedLocationType?.length === 0 ? `around zip code ${zipcode ? zipcode : address ? address : userData?.zipcode} ` :""} with focus on ${chatgpt?.keywords}. Could you please furnish a list of such  ${selectedResourceType}? Each entry should encompass the name, location, address, and secure official website URL (HTTPS), along with descriptions, all formatted in JSON.`
           const res = await getResultsFromChatGPT(query, support_type, [], chatgpt?.keywords, chatgpt?.start_date, chatgpt?.end_date);
          if (res === true) {
            break;
          }
        }
      }

    }else {
      setDataFound(true)
    }
  // }else{
  // // setDataFound(true)

  // setDownSpin(false)
  // }
  }
  //get usersearch
  const getUserSearch = (res, chatgpt) => {
    let data = {};
    if (keywordsData) {
      data.keyword = keywordsData;
    }
    if (resourceId) {
      data.resource_type = resourceId
    }
    if (selectedLearningPillars?.length) {
      data.learning_pillar = selectedLearningPillars;
    }
    if (selectedGrades?.length) {
      data.grade_id = selectedGrades;
    }
    if (selectedTimeframe?.length) {
      data.time_frame = selectedTimeframe;
    }
    if (selectedCustomerType?.length) {
      data.customer_type = selectedCustomerType;
    }
    if (latitude) {
      data.latitude = latitude;
    }
    if (longitude) {
      data.longitude = longitude;
    }
    if (selectedTypeOfSupport?.length) {
      data.support_type = selectedTypeOfSupport;
    }
    if (selectedOrganizationType?.length) {
      data.organization_type = selectedOrganizationType;
    }
    if (selectedExperienceType?.length) {
      data.experience_type = selectedExperienceType;
    }
    if (selectedFundingType?.length) {
      data.funding_type = selectedFundingType;
    }
    if (selectedFacilityType?.length) {
      data.facility_type = selectedFacilityType;
    }
    if (selectedLocationType?.length) {
      data.location_type = selectedLocationType;
    }
    if (selectedDual?.length) {
      data.dual_enrolment = selectedDual[0];
    }
    if (selectedAccredited?.length) {
      data.accredited = selectedAccredited[0];
    }
    if (selectedInstructionStyle?.length) {
      data.instruction_style = selectedInstructionStyle;
    }
    if (selectedTypeOfContent?.length) {
      data.content_type = selectedTypeOfContent;
    }
    if (selectedChildFor?.length) {
      data.child_id = selectedChildFor
    }
    const obj = [];
    const resource_id = [];
    const resource_title = [];
    res?.forEach((val, index) => {
      obj.push({
        title: val?.resource_title,
        id: parseInt(val?.objectID),
      });
      resource_id.push(parseInt(val?.objectID))
      resource_title.push(val?.resource_title)
    })
    // if (selectedStartDate) {
    //   let date_start = moment(selectedStartDate).format("YYYY-MM-DD")
    //   // const utcString_start = date_start.utc(userData.timezone).unix();
    //   // filters.start_date = utcString_start || "";
    //   data.start_date = date_start
    // }
    // if (selectedEndDate) {
    //   let date_end = moment(selectedEndDate).format("YYYY-MM-DD")
    //   // const utcString_start = date_start.utc(userData.timezone).unix();
    //   // filters.end_date = utcString_start || "" ;
    //   data.start_date = date_end
    // }
    data.all_respose = obj
    data.resource_id_respose = resource_id
    data.resource_title_respose = resource_title
    

    let source = null;
    source = axios.CancelToken.source();
    setCancelTokenSource(source);
    makePostRequestcancle(URLS.getUserSearch, null, data, null, source.token)
      .then((res) => {
        if (res.code === 200) {
          localStorage.setItem("chatGptId", res?.data?.id)
          if ((shareBy === "global" && (resourceId === "2"))) {
            for (let i = 0; i < 1; i++) {
              newChatGPT(false, chatgpt);
            }
          }
          getSubscriptionPlanList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //api call to get subscription plan data
  const getSubscriptionPlanList = () => {
    makeGetRequest(URLS.getUserSubscriptionPlan, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setSubscriptionPlan(res?.data);
        } else {
          setPlanStatus(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderExperienceCard = (data, index) => {
    return (
      <div className="col-sm-4 col-md-4 col-lg-4" key={index}>
        <div className="card-container-exp">
          <div className="img-icon">
            {
              data?.resource_thumbnail_image ?
                <div className="resource-img" onClick={() =>
                  openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
                }><img src={imageUrl + data?.resource_thumbnail_image} className="resource-image2" alt="matched resource" />
                </div>
                : <>
                  {
                    (data?.imageLink && !data?.imageLink.includes("booksamillion.com")) ? <div className="resource-img" onClick={() =>
                      openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
                    }><img src={data?.imageLink} className="resource-image2" alt="matched " />
                    </div> : <>
                      {parseInt(data.resource_type_id) === 7 && (
                        <div className="img-Facilities" onClick={() =>
                          openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
                        }>
                          <img src={image2} className="curriculum-image2" alt="Facility " />
                        </div>
                      )}
                      {parseInt(data.resource_type_id) === 6 && (
                        <div className="img-funding" onClick={() =>
                          openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
                        }>
                          <img src={srimg5} className="curriculum-image2" alt="Funding " />
                        </div>
                      )}
                      {parseInt(data.resource_type_id) === 3 && (
                        <div className="img-div1" onClick={() =>
                          openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
                        }>
                          <img src={ph22} className="curriculum-image2" alt="Experiences" />
                        </div>
                      )}
                      {parseInt(data.resource_type_id) === 4 && (
                        <div className="img-div4" onClick={() =>
                          openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
                        }>
                          <img src={vic1} className="student-image1" alt="Student Support " />
                        </div>
                      )}
                      {parseInt(data.resource_type_id) === 2 && (
                        <div className="img-div2" onClick={() =>
                          openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
                        }>
                          <img src={vectorr} className="curriculum-image1" alt="Curriculum Content" />
                        </div>
                      )}
                      {parseInt(data.resource_type_id) === 1 && (
                        <div className="images-organization" onClick={() =>
                          openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
                        }>
                          <img src={vector4} className="student-image1" alt="Organization" />
                        </div>
                      )}
                      {parseInt(data.resource_type_id) === 5 && (
                        <div className="img-div3" onClick={() =>
                          openRouteInNewTab(`/full-description/${data.objectID}/${toSeoUrl(data?.resource_title)}`)
                        }>
                          <img src={vector3} className="curriculum-image3" alt="Teacher Parent Support" />
                        </div>
                      )}
                    </>
                  }
                </>
            }
            <span>
              <span>
                <CardMenuButton
                  data={data}
                  handleResourceReportOpen={(data) =>
                    setReportPopupStatus({
                      visible: true,
                      objectID: data.objectID,
                    })
                  }
                  handleResourceReportClose={() =>
                    setReportPopupStatus({
                      visible: false,
                      selectedResource: null,
                    })
                  }
                  handleResourceSaveClose={() =>
                    setSavePopupStatus({
                      visible: false,
                      selectedResource: null,
                    })
                  }
                  handleResourceSaveOpen={(data) =>
                    setSavePopupStatus({
                      visible: true,
                      selectedResource: data,
                    })
                  }
                  handleResourceShareOpen={(data) =>
                    setSharePopupStatus({
                      visible: true,
                      objectID: data.objectID,
                    })
                  }
                  handleResourceShareClose={() =>
                    setSharePopupStatus({
                      visible: false,
                      selectedResource: null,
                    })
                  }
                />
              </span>
            </span>
          </div>
          <RenderCardInfo data={data} type={resourceId} />
        </div>
      </div>
    );
  };
  // this function is use for chatgpt card
  const renderChatGPTCard = (data, index) => {
    return (
      <div className="col-sm-4 col-md-4 col-lg-4" key={index}>
        <div className="card-container-exp">
          <div className="img-icon">
            {
              data?.resource_thumbnail_image ?
                <div className="resource-img" onClick={() =>
                  openRouteInNewTab(`/full-description/${data.id}/${toSeoUrl(data?.resource_title)}`)
                }><img src={imageUrl + data?.resource_thumbnail_image} className="resource-image2" alt="matched resource" />
                </div>
                : <>
                  {
                    (data?.imageLink && !data?.imageLink.includes("booksamillion.com")) ? <div className="resource-img" onClick={() =>
                      openRouteInNewTab(`/full-description/${data.id}/${toSeoUrl(data?.resource_title)}`)
                    }><img src={data?.imageLink} className="resource-image2" alt="matched " />
                    </div> : <>
                      <div className="img-div1" onClick={() =>
                        openRouteInNewTab(`/full-description/${data.id}/${toSeoUrl(data?.resource_title)}`)
                      }>
                        <img src={ph22} className="curriculum-image2" alt="RESOURE" />
                      </div>
                    </>
                  }
                </>
            }
            <span>
              <span>
                <CardMenuButton
                  data={data}
                  handleResourceReportOpen={(data) =>
                    setReportPopupStatus({
                      visible: true,
                      selectedResource: data,
                    })
                  }
                  handleResourceReportClose={() =>
                    setReportPopupStatus({
                      visible: false,
                      selectedResource: null,
                    })
                  }
                  handleResourceSaveClose={() =>
                    setSavePopupStatus({
                      visible: false,
                      selectedResource: null,
                    })
                  }
                  handleResourceSaveOpen={(data) =>
                    setSavePopupStatus({
                      visible: true,
                      selectedResource: data,
                    })
                  }
                  handleResourceShareOpen={(data) =>
                    setSharePopupStatus({
                      visible: true,
                      objectID: data.id,
                    })
                  }
                  handleResourceShareClose={() =>
                    setSharePopupStatus({
                      visible: false,
                      selectedResource: null,
                    })
                  }
                />
              </span>
            </span>
          </div>
          <div>
            <div>
              <h3
                onClick={() =>
                  openRouteInNewTab(`/full-description/${data?.id}/${toSeoUrl(data?.resource_title)}`)
                }
              >
                {data.resource_title ? data.resource_title : data.resource_title}
              </h3>
              <p className="mb-1">{data?.author_name && <>Publisher: {data?.author_name}</>}</p>
              <div className="box-adjustment">
                <p
                  className="description"
                  onClick={() =>
                    openRouteInNewTab(`/full-description/${data?.id}/${toSeoUrl(data?.resource_title)}`)
                  }
                >
                  {renderDescription(data.description ? data.description : data.Description)}
                </p>
                <p>{data.define_address ? <><b>Address:</b> {data.define_address}</> : ""}</p>
                <div
                  className="text-curriculum11"
                  onClick={() =>
                    openRouteInNewTab(`/full-description/${data?.id}/${toSeoUrl(data?.resource_title)}`)
                  }
                >
                  {Array.isArray(selectedLearningPillars) &&
                    selectedLearningPillars?.map((item, i) => {
                      switch (item) {
                        case "Heart":
                          return (
                            <span key={i} style={{ color: "#265472" }}>
                              <img className="img-fluid" src={Hearticon} alt="heart" />
                              Heart
                            </span>
                          );
                        case "Strength":
                          return (
                            <span key={i} style={{ color: "#AAC0CE" }}>
                              <img className="img-fluid" src={Strengthicon} alt="strength" />
                              Strength
                            </span>
                          );
                        case "Mind":
                          return (
                            <span key={i} style={{ color: "#F19357" }}>
                              <img className="img-fluid" src={Mindicon} alt="mind" /> Mind
                            </span>
                          );
                        case "Soul":
                          return (
                            <span key={i} style={{ color: "#F0B285" }}>
                              <img className="img-fluid" src={Sounicon} alt="soul" /> Soul
                            </span>
                          );
                        default:
                          return null;
                      }
                    })}
                </div>
              </div>
            </div>
            <div
              onClick={() => openRouteInNewTab(`/full-description/${data?.id}/${toSeoUrl(data?.resource_title)}`)}
            >
              <span>0.0{" "}</span>
              {renderRatingStars(data.rating)} {" "}
              <span> ({data.total_reviews !== 0 ? data.total_reviews : "0"})</span>
              <span className="reviews"
              >See all reviews </span>
            </div>
            <div className="bookmark">
              <span>
                {data?.resource_save_for === "yes" ? (
                  <i className="fa fa-bookmark"></i>
                ) : (
                  <i className="fa fa-bookmark-o"></i>
                )}
              </span>
            </div>
          </div>
        </div>
      </div >
    );
  };
  return (
    <>
      <Head title="Search"
        content="Resources: Printed or digital books, curriculum, online learning, experiences, schools, clubs, tutors, coaches, parent/teacher support.
     Global Search: Consolidated search across multiple platforms.
     Customized: Personalized results sorted by learning preferences.
     Filtered Search: See what your Friends and Groups have used and reviewed"
        name="Global Search of Resources" ></Head>
      {reportPopupStatus.objectID && reportPopupStatus.visible && (
        <Reportpopup
          data={reportPopupStatus}
          handleResourceReportOpen={(data) =>
            setReportPopupStatus({ visible: true, selectedResource: data })
          }
          handleResourceReportClose={() =>
            setReportPopupStatus({ visible: false, objectID: null })
          }
        />
      )}
      {savePopupStatus.selectedResource && savePopupStatus.visible && (
        <Saveresourcespopup
          data={savePopupStatus}
          handleResourceSaveOpen={(data) =>
            setSavePopupStatus({ visible: true, selectedResource: data })
          }
          handleResourceSaveClose={() =>
            setSavePopupStatus({ visible: false, selectedResource: null })
          }
        />
      )}
      {sharePopupStatus.objectID && sharePopupStatus.visible && (
        <ShareRecoursePopUp
          data={sharePopupStatus}
          handleResourceShareOpen={(data) =>
            setSharePopupStatus({ visible: true, objectID: data.objectID })
          }
          handleResourceShareClose={() =>
            setSharePopupStatus({ visible: false, selectedResource: null })
          }
        />
      )}
      {locatonStatus && (
        <LocationPopUp
          handleResourceSaveClose={() => setLocatonStatus(false)}
        />
      )}
      <div className="col-lg-10">
        <div className="sermargin">
          <div className="search-bar-div">

            <div className="input-search-div">
              <label>Keywords<span className="starr">*</span></label>
              <input
                type="text"
                className="border-0 keywordtextsearch"
                placeholder="Keywords"
                onChange={(e) => setKeywordsData(e.target.value)}
                value={keywordsData}

              />
            </div>
            {resource_type_data && (
              <div className="input-search-div">
                <label>Resource Type<span className="starr">*</span></label>
                <Multiselect
                  displayValue="name"
                  onKeyPressFn={() => { }}
                  onRemove={resourceTypeHandler}
                  onSearch={function noRefCheck() { }}
                  onSelect={resourceTypeHandler}
                  options={resource_type_data}
                  showCheckbox
                  placeholder="Resource Type"
                  selectedValues={resourceId ? [resource_type_data.find(item => item.id === resourceId)] : []}

                />
              </div>
            )}
            {
              resource && <>
                {selectedExperienceTypeOptions &&
                  resourceId === "3" && (
                    <div className="input-search-div">
                      <label>Experience Type<span className="starr">*</span></label>
                      <Multiselect
                        displayValue="name"
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={(e) => dispatch(setSelectedExperienceType(e))}
                        onSearch={function noRefCheck() { }}
                        onSelect={(e) => {
                          if (selectedExperienceType.length < 2) {
                            dispatch(setSelectedExperienceType(e))
                          } else {
                            e.pop()
                            toastifyMessage("You can only select up to 2 types of experience for each search.", "error");
                          }
                        }
                        }
                        options={selectedExperienceTypeOptions}
                        showCheckbox
                        placeholder="Experience Type"
                        selectedValues={selectedExperienceType}

                      />
                    </div>
                  )}
                {(selectedTypeOfContentOptions &&
                  resourceId === "2") && (
                    <div className="input-search-div">
                      <label>Content Type<span className="starr">*</span></label>
                      <Multiselect
                        displayValue="name"
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={(e) =>
                          dispatch(setSelectedTypeOfContent(e))
                        }
                        onSearch={function noRefCheck() { }}
                        onSelect={(e) => {
                          if (selectedTypeOfContent.length < 2) {
                            dispatch(setSelectedTypeOfContent(e))
                          } else {
                            e.pop()
                            toastifyMessage("You can only select up to 2 types of content for each search.", "error");
                          }
                        }
                        }
                        options={selectedTypeOfContentOptions}
                        showCheckbox
                        placeholder="Content Type"
                        selectedValues={selectedTypeOfContent}
                      />
                    </div>
                  )}
                {selectedFacilityTypeOptions &&
                  resourceId === "7" && (
                    <div className="input-search-div">
                      <label>Facility Type<span className="starr">*</span></label>
                      <Multiselect
                        displayValue="name"
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={(e) =>
                          dispatch(setSelectedFacilityType(e))
                        }
                        onSearch={function noRefCheck() { }}
                        onSelect={(e) => {
                          if (selectedFacilityType.length < 2) {
                            dispatch(setSelectedFacilityType(e))
                          } else {
                            e.pop()
                            toastifyMessage("You can only select up to 2 types of facility for each search.", "error");
                          }
                        }
                        }
                        options={selectedFacilityTypeOptions}
                        showCheckbox
                        placeholder="Facility Type"
                        selectedValues={selectedFacilityType}
                      />
                    </div>
                  )}
                {selectedFundingTypeOptions &&
                  resourceId === "6" && (
                    <div className="input-search-div">
                      <label>Funding Type<span className="starr">*</span></label>
                      <Multiselect
                        displayValue="name"
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={(e) =>
                          dispatch(setSelectedFundingType(e))
                        }
                        onSearch={function noRefCheck() { }}
                        onSelect={(e) => {
                          if (selectedFundingType.length < 2) {
                            dispatch(setSelectedFundingType(e))
                          } else {
                            e.pop()
                            toastifyMessage("You can only select up to 2 types of funding for each search.", "error");
                          }
                        }
                        }
                        options={selectedFundingTypeOptions}
                        showCheckbox
                        placeholder="Funding Type"
                        selectedValues={selectedFundingType}
                      />
                    </div>
                  )}
                {selectedTypeOfSupportOptions &&
                  (resourceId === "4" || resourceId === "5") && (
                    <div className="input-search-div">
                      <label>Support Type<span className="starr">*</span></label>
                      <Multiselect
                        displayValue="name"
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={(e) =>
                          dispatch(setSelectedTypeOfSupport(e))
                        }
                        onSearch={function noRefCheck() { }}
                        onSelect={(e) => {
                          if (selectedTypeOfSupport.length < 2) {
                            dispatch(setSelectedTypeOfSupport(e))
                          } else {
                            e.pop()
                            toastifyMessage("You can only select up to 2 types of support for each search.", "error");
                          }
                        }
                        }
                        options={selectedTypeOfSupportOptions}
                        showCheckbox
                        placeholder="Support Type"
                        selectedValues={selectedTypeOfSupport}
                      />
                    </div>
                  )}
                {selectedOrganizationTypeOptions &&
                  resourceId === "1" && (
                    <div className="input-search-div">
                      <label>Organization Type<span className="starr">*</span></label>
                      <Multiselect
                        displayValue="name"
                        onKeyPressFn={function noRefCheck() { }}
                        onRemove={(e) =>
                          dispatch(setSelectedOrganizationType(e))
                        }
                        onSearch={function noRefCheck() { }}
                        onSelect={(e) => {
                          if (selectedOrganizationType.length < 2) {
                            dispatch(setSelectedOrganizationType(e))
                          } else {
                            e.pop()
                            toastifyMessage("You can only select up to 2 types of organization for each search.", "error");
                          }
                        }
                        }
                        options={selectedOrganizationTypeOptions}
                        showCheckbox
                        placeholder="Organization Type"
                        selectedValues={selectedOrganizationType}
                      />
                    </div>
                  )}
              </>
            }
            {/* This dropdown open if select Group from resource type and this used for group search */}
            {(selectedGradesOptions && resourceId === "group") && (
              <div className="input-search-div graderangeselectdiv">
                <label>Grade Range</label>
                <Multiselect
                  displayValue="name"
                  onKeyPressFn={function noRefCheck() { }}
                  onRemove={(e) => dispatch(setSelectedGrade(e))}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => dispatch(setSelectedGrade(e))}
                  options={sortedGrades}
                  showCheckbox
                  placeholder="Grade Range"
                  selectedValues={defaultGrede}
                />
              </div>
            )}
            {/*  if select Group from resource type then shared with dropdown open for group search otherwise
                          in all cases Who is this for? dropdown will open */}
            {childFor && resourceId !== "group" ? (
              <div className={`input-search-div rightborder-0 sharewithscroll ${(selectedChildFor.length >1)? "selectedChildForaddScroll" : "selectedChildForRemoveScroll"}`}>
                <label>Who is this for?</label>
                <Multiselect
                  displayValue="name"
                  onKeyPressFn={function noRefCheck() { }}
                  onRemove={(e) => { dispatch(setSelectedChildFor(e)); setChildStyle(e) }}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => { dispatch(setSelectedChildFor(e)); setChildStyle(e) }}
                  options={childFor}
                  showCheckbox
                  placeholder="Who is this for?"
                />
              </div>
            ) :
              <div className="input-search-div rightborder-0 sharewithscroll">
                <label>Shared With</label>
                <Multiselect
                  displayValue="name"
                  onKeyPressFn={() => { }}
                  onRemove={(e) => {
                    if (e.length > 0) {
                      const lastSelected = e[e.length - 1];
                      setShareBy(lastSelected.value);
                      setShareSelected([lastSelected])
                    }
                  }}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => {
                    if (e.length > 0) {
                      const lastSelected = e[e.length - 1];
                      setShareBy(lastSelected.value);
                      setShareSelected([lastSelected])
                    }
                  }}
                  options={sharedByOptions}
                  showCheckbox
                  placeholder="Shared With"
                  selectedValues={shareSelected}
                />
              </div>
            }
            <button
              onClick={() => { setQueryStatus(""); localStorage.setItem("chatgptCount", 0); localStorage.setItem("chatgptmsg", ""); hitSearchHandler(false) }}
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              {(showContent && resource) && (
                <>
                  {/* if resource type group in that case we will not showing this content */}
                  {resourceId !== "group" &&
                    <div className="searchres-div">
                      <div className="search-exp-sec-div">
                        <h3>Refine Search Results    <div className="facustomeI"> i
                          <div className="tootlippillerinfo">
                            <ul>
                              <li>
                                <strong>Heart (Character)</strong>
                                <p></p>
                              </li>
                              <li>
                                <strong>Mind (Academics)</strong>
                                <p></p>
                              </li>
                              <li>
                                <strong>Soul (Faith)</strong>
                                <p></p>
                              </li>
                              <li>
                                <strong>Strength (Health and Physical Fitness)</strong>
                                <p></p>
                              </li>
                            </ul>

                          </div>
                        </div></h3>
                        <div className="searchResultInnerBox">
                          {learning_pillars && (
                            <div className={`select-exp-div ${(selectedLearningPillars.length > 1) ? "removeScrollLearningPiller" : "addScrollLearningPiller"}`}>
                              <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(e) => dispatch(setSelectedLearningPillars(e))}
                                onSearch={function noRefCheck() { }}
                                onSelect={(e) => dispatch(setSelectedLearningPillars(e))}
                                options={learning_pillars}
                                showCheckbox
                                placeholder="Learning Pillars"
                              />
                            </div>
                          )}
                         
                         
						  
						  
                          {(selectedGradesOptions) && (
                            <div className={`select-exp-div ${(selectedGrades.length > 1) ? "removeScrollselectedGrade" : "addScrollselectedGrade"}`}>
                              <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(e) => dispatch(setSelectedGrade(e))}
                                onSearch={function noRefCheck() { }}
                                onSelect={(e) => dispatch(setSelectedGrade(e))}
                                options={sortedGrades}
                                showCheckbox
                                placeholder="Grade"
                                selectedValues={defaultGrede}
                              />
                            </div>
                          )}
			{  resourceId === "4" || resourceId === "5" ? "" : <>			  
                           {selectedLocationTypeOptions && (
                            <div className={`select-exp-div ${(selectedLocationType.length > 0) ? "removeScrollselectedLocation" : "addScrollselectedLocation"}`}>
                              <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(e) =>
                                  dispatch(setSelectedLocationType(e))
                                }
                                onSearch={function noRefCheck() { }}
                                onSelect={(e) =>
                                  dispatch(setSelectedLocationType(e))
                                }
                                options={selectedLocationTypeOptions}
                                showCheckbox
                                placeholder="Location Type"
                              />
                            </div>
                          )}
						  
				</> }		  
						  
                          {(selectedSharedByOptions) && (
                            <div className="select-exp-div select-exp-sharedBy">
                              <Multiselect
                                displayValue="name"
                                onKeyPressFn={() => { }}
                                onRemove={(e) => {
                                  if (e.length > 0) {
                                    const lastSelected = e[e.length - 1];
                                    setShareBy(lastSelected.value);
                                    setShareSelected([lastSelected])
                                  }
                                }}
                                onSearch={function noRefCheck() { }}
                                onSelect={(e) => {
                                  if (e.length > 0) {
                                    const lastSelected = e[e.length - 1];
                                    setShareBy(lastSelected.value);
                                    setShareSelected([lastSelected])
                                  }
                                }}
                                options={sharedByOptions}
                                showCheckbox
                                placeholder="Shared By"
                                selectedValues={shareSelected}
                              />
                            </div>
                          )}
						 {
                        (resourceId === "2" ||
                          resourceId === "3" ||
                          resourceId === "5" ||
                          resourceId === "4") &&
                        <>
                          {shareBy === "friend" && friendData && (
                            <div className="select-exp-div searchfriendlistview">
                              <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(e) =>
                                  dispatch(setSelectedFriendFor(e))
                                }
                                onSearch={function noRefCheck() { }}
                                onSelect={handleFriend}
                                options={friendData}
                                showCheckbox
                                placeholder="Friends"
                                selectedValues={friendData.filter(item => {
                                  return selectedFriendFor.includes(item.id)
                                })}
                              />
                              <img className="icon_cancel icon_down_dir" src="data:image/svg+xml,%3Csvg%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232%22%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%20%20%20%3Cg%20id%3D%22background%22%3E%20%20%20%20%20%20%20%20%3Crect%20fill%3D%22none%22%20height%3D%2232%22%20width%3D%2232%22%2F%3E%20%20%20%20%3C%2Fg%3E%20%20%20%20%3Cg%20id%3D%22arrow_x5F_down%22%3E%20%20%20%20%20%20%20%20%3Cpolygon%20points%3D%222.002%2C10%2016.001%2C24%2030.002%2C10%20%20%22%2F%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E" alt="matched" />
                            </div>
                          )}
                          {shareBy === "group" && groupData && (
                            <div className="select-exp-div searchfriendlistview">
                              <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(e) =>
                                  dispatch(setSelectedGroupFor(e))
                                }
                                onSearch={function noRefCheck() { }}
                                onSelect={handleGroup}
                                options={groupData}
                                showCheckbox
                                unCheck={true}
                                placeholder="Groups"
                                selectedValues={groupData.filter(item => {
                                  return selectedGroupFor.includes(item.id)
                                })}
                              />
                              <img className="icon_cancel icon_down_dir" src="data:image/svg+xml,%3Csvg%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232%22%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%20%20%20%3Cg%20id%3D%22background%22%3E%20%20%20%20%20%20%20%20%3Crect%20fill%3D%22none%22%20height%3D%2232%22%20width%3D%2232%22%2F%3E%20%20%20%20%3C%2Fg%3E%20%20%20%20%3Cg%20id%3D%22arrow_x5F_down%22%3E%20%20%20%20%20%20%20%20%3Cpolygon%20points%3D%222.002%2C10%2016.001%2C24%2030.002%2C10%20%20%22%2F%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E" alt="matched" />
							   </div>
							
                          )}</>
						 } 
						  <div className="facustomeI">
                                i
                                <div className="tootlippillerinfo">
                                  <ul>
                                    <li><strong>Global Search:</strong>  Searches all of MatchED resources and external resources.</li>
                                    <li><strong>MatchED Community:</strong>  Searches just MatchED resources.</li>
                                    <li><strong>Local MatchED:</strong>  Searches just resources saved or created through Serve
                                      within your area based on the Zip Code entered on your profile.</li>
                                    <li><strong>Groups:</strong> Searches just Save/Serve resources posted by Groups you
                                      are a member of (can select individual or multiple groups).</li>
                                    <li><strong>Friends:</strong> Searches just Save/Serve resources posted by your Friends (can select individual or multiple friends).</li>
                                  </ul>
                                </div>
                              </div>
                              {
                            (resourceId === "1" ||
                              resourceId === "6" ||
                              resourceId === "7") &&
                            <>
                              {shareBy === "friend" && friendData && (
                                <div className="select-exp-div">
                                  <Multiselect
                                    displayValue="name"
                                    onKeyPressFn={function noRefCheck() { }}
                                    onRemove={(e) =>
                                      dispatch(setSelectedFriendFor(e))
                                    }
                                    onSearch={function noRefCheck() { }}
                                    onSelect={handleFriend}
                                    options={friendData}
                                    showCheckbox
                                    placeholder="Friends"
                                    selectedValues={friendData.filter(item => {
                                      return selectedFriendFor.includes(item.id)
                                    })}
                                  />
                                </div>
                              )}
                              {shareBy === "group" && groupData && (
                                <div className="select-exp-div">
                                  <Multiselect
                                    displayValue="name"
                                    onKeyPressFn={function noRefCheck() { }}
                                    onRemove={(e) =>
                                      dispatch(setSelectedGroupFor(e))
                                    }
                                    onSearch={function noRefCheck() { }}
                                    onSelect={handleGroup}
                                    options={groupData}
                                    showCheckbox
                                    placeholder="Groups"
                                    selectedValues={groupData.filter(item => {
                                      return selectedGroupFor.includes(item.id)
                                    })}
                                  />
                                </div>
                              )}</>
                          }

                       <div className="searchResultInnerBox w-100">
                       {selectedCustomerTypeOptions && (
                            <div className={`select-exp-div ${(selectedCustomerType.length > 0) ? "removeScrollselectedcustomer" : "addScrollselectedcustomer"}`}>
                              <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(e) =>
                                  dispatch(setSelectedCustomerType(e))
                                }
                                onSearch={function noRefCheck() { }}
                                onSelect={(e) =>
                                  dispatch(setSelectedCustomerType(e))
                                }
                                options={selectedCustomerTypeOptions}
                                showCheckbox
                                placeholder="Customer Type"
                              />
                            </div>
                          )}
						  
	{  resourceId === "4" || resourceId === "5" ? <> 

{selectedLocationTypeOptions && (
<div className={`select-exp-div ${(selectedLocationType.length > 0) ? "removeScrollselectedLocation" : "addScrollselectedLocation"}`}>
  <Multiselect
    displayValue="name"
    onKeyPressFn={function noRefCheck() { }}
    onRemove={(e) =>
      dispatch(setSelectedLocationType(e))
    }
    onSearch={function noRefCheck() { }}
    onSelect={(e) =>
      dispatch(setSelectedLocationType(e))
    }
    options={selectedLocationTypeOptions}
    showCheckbox
    placeholder="Location Type"
  />
</div>
)}

</>: "" }					  
						  
                         
                        </div>

                      <div className="searchResultInnerBox w-100">
						  {resourceId === "3" && (
                            <>
                              <div className="select-exp-div">
                                <DatePicker
                                  className="border-0 w-100 searchDate"
                                  id=""
                                  selected={selectedStartDate}
                                  onChange={(date) => setSelectedStartDate(date)}
                                  placeholderText="Start Date"
                                  dateFormat="MM-dd-yyyy"
                                />
                              </div>
                              <div className="select-exp-div">
                                <DatePicker
                                  className="border-0 w-100 searchDate"
                                  id="date"
                                  selected={selectedEndDate}
                                  onChange={(date) => setSelectedEndDate(date)}
                                  placeholderText="End Date"
                                  dateFormat="MM-dd-yyyy"
                                  minDate={selectedStartDate}
                                />
                              </div>
                            </>
                          )}
						</div>  
						  </div>
                      </div>
                    </div>
                  }
                  {/* if resource type group in that case we will not show this */}
                  {resourceId !== "group" &&
                    <div className="curriculam mb-3">
                       {resourceId === "2" && (
                            <>
                              {selectedTimeframeTypeOptions && (
                                <div className="select-exp-div">
                                  <Multiselect
                                    displayValue="name"
                                    onKeyPressFn={function noRefCheck() { }}
                                    onSearch={function noRefCheck() { }}
                                  // options={selectedTimeframeTypeOptions}
                                  onRemove={TimeFrame}
                                  onSelect={TimeFrame}
                                    options={ selectedTimeframeTypeOptions?.map(option => ({
                                      name: option?.name.replace(/^./, option?.name[0].toUpperCase()) ,
                                      value :  option?.name,
                                      id : option?.id
                                    }))}
                                    showCheckbox
                                    placeholder="Time Frame"
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {selectedInstructionStyleOptions && (
                            <div className="select-exp-div select-exp-sharedBy">
                              <Multiselect
                                displayValue="name"
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(e) =>
                                  dispatch(setSelectedInstructionStyle(e))
                                }
                                onSearch={function noRefCheck() { }}
                                onSelect={(e) =>
                                  dispatch(setSelectedInstructionStyle(e))
                                }
                                options={selectedInstructionStyleOptions}
                                showCheckbox
                                placeholder="Instructional Style"
                              />
                            </div>
                          )}
					
                      {selectedAccreditedResource && (
                        <div className={`select-exp-div ${(accredited.length > 0) ? "removeScrollselectedAccredited" : "addScrollselectedAccredited"}`}>
                          <Multiselect
                            displayValue="id"
                            onKeyPressFn={function noRefCheck() { }}
                            onRemove={(e) => {
                              if (e.length > 0) {
                                const lastSelected = e[e.length - 1];
                                dispatch(setSelectedAccredited(e))
                                setAccredited([lastSelected])
                              }
                            }}
                            onSearch={function noRefCheck() { }}
                            onSelect={(e) => {
                              if (e.length > 0) {
                                const lastSelected = e[e.length - 1];
                                dispatch(setSelectedAccredited(e))
                                setAccredited([lastSelected])
                              }
                            }}
                            options={selectedAccreditedResource}
                            showCheckbox
                            placeholder="Accredited"
                            selectedValues={accredited}
                          />
                        </div>
                      )}
                      {selecteddDualEnrollmentResource && (
                        <div className={`select-exp-div ${(selecteddDualEnrollmentResource.length > 0) ? "removeScrolldualSelected" : "addScrolldualSelected"}`}>
                          <Multiselect
                            displayValue="id"
                            onKeyPressFn={function noRefCheck() { }}
                            onRemove={(e) => {
                              if (e.length > 0) {
                                const lastSelected = e[e.length - 1];
                                dispatch(setSelectedDualEnroll(e))
                                setDualSelected([lastSelected])
                              }
                            }}
                            onSearch={function noRefCheck() { }}
                            onSelect={(e) => {
                              if (e.length > 0) {
                                const lastSelected = e[e.length - 1];
                                dispatch(setSelectedDualEnroll(e))
                                setDualSelected([lastSelected])
                              }
                            }}
                            options={selecteddDualEnrollmentResource}
                            showCheckbox
                            placeholder="Dual Enrollment"
                            selectedValues={dualSelected}
                          />
                        </div>
                      )}
                      
                    </div>
                  }
                  {/* if resource type group in that case we will also show this addres fields */}
                  {(resourceId === "1" ||
                    resourceId === "7" ||
                    resourceId === "6" ||
                    resourceId === "3" ||
                    resourceId === "5" ||
                    resourceId === "4" ||
                    resourceId === "group") && (
                      <>
                        <div className="select-exp-div searchfriendlistview addresziplocation">
                          <Multiselect
                            displayValue="name"
                            onKeyPressFn={function noRefCheck() { }}
                            onRemove={handleZipcode}
                            onSelect={handleZipcode}
                            options={zipOptions}
                            showCheckbox
                            placeholder="Address type"
                            selectedValues={addressType ? [zipOptions.find(item => item.value === addressType)] : []}
                          />
                        </div>
                        <div className="experience-input-div">
                          <div className="input-location d-flex">
                            {
                              addressType === "Address" && <input
                                type="text"
                                placeholder="Enter Location"
                                className="input-experienc"
                                name="address"
                                value={address ? address : ""}
                                onChange={(e) => setAddress(e.target.value)}
                                ref={inputRef}
                              />} {
                              addressType === "Zipcode" &&
                              <input
                                type="text"
                                placeholder="Enter Zip Code"
                                className="input-experienc"
                                name="address"
                                 value={zipcode ? zipcode : ""}
                                onChange={(e) => setZipCode(e.target.value)}
                                ref={inputRef2}
                              />
                            }
                            <GpsFixedIcon
                              className="location-icon mr-2"
                              onClick={locatioPremission}
                            />
                          </div>
                          <div></div>
                          <span className="miles-from-s milesDistance">
                            <input
                              type="number"
                              className="miles"
                              onChange={(e) =>
                                setDistanceFromLocation(e.target.value)
                              }
                              value={distanceFromLocation ? distanceFromLocation : km?.setting_value}
                              placeholder="Miles"
                            />
                            <button
                              className="icon-div22"
                              onClick={() => { setQueryStatus(""); localStorage.setItem("chatgptCount", 0); localStorage.setItem("chatgptmsg", ""); hitSearchHandler(true) }}
                            >
                              <i className="fa fa-search iconsearch22" />
                            </button>
                            <div className="facustomeI">i<div className="tootlippillerinfo">
                              <p>Distance in miles from location.</p>
                            </div>
                            </div></span>
                        </div>
                      </>
                    )}
                  <div className="text-center hide_search"><button className="text-center" onClick={() => { setQueryStatus(""); localStorage.setItem("chatgptCount", 0); localStorage.setItem("chatgptmsg", ""); hitSearchHandler(false) }}>Search</button></div>
                </>
              )}
              {searchResults?.length > 0 && (
                <div className="row">
                  <div className="col-lg-12 pageNumber">
                    <div className="select-exp-div">
                      <Multiselect
                        options={viewResult}
                        onSelect={(e) => {
                          e?.forEach((val, i) => {
                            localStorage.setItem("ITEMS_PER_PAGE", val.value); setItemsPerPage(val.value);
                          });
                        }}
                        displayValue="name"
                        showCheckbox
                        showArrow={true}
                        selectedValues={itemsPerPage ? [viewResult.find(item => parseInt(item.value) === parseInt(itemsPerPage))] : []}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(chatGPTLoader || (viewableSearchResults && viewableSearchResults.length)) > 0 ? (
                <div className="main-card-container">
                  <div className="row">
                    {viewableSearchResults.map((result, i) => {
                      const validResourceTypes = [6, 7, 2, 3, 1, 4, 5];
                      const isChatGPTCard = !validResourceTypes.includes(parseInt(result.resource_type_id));
                      return isChatGPTCard ? renderChatGPTCard(result, i) : renderExperienceCard(result, i);
                    })}
                  </div>
                  {chatGPTLoader && (
                    <div className="sharepopupOuter backgroundOpacity1 fixedOuter">
                      <FourDotsLoader text={true} />
                    </div>
                  )}
                  {
                    (downSpin && viewableSearchResults.length >= 1) && <div className="share-spiner">
                      <FourDotsLoader message={true} />
                    </div>
                  }
                  {((isLoadMoreVisible && viewableSearchResults.length > 0 && viewableSearchResults.length !== chatgptDataCount + dummy.length) ||

                    (shareBy === "global" && !isLoadMoreVisible && viewableSearchResults.length !== chatgptDataCount + dummy.length && chatgptDataCount <= SearchResultItam) ||

                    (!isLoadMoreVisible && shareBy === "global" && viewableSearchResults.length > 0 && chatgptDataCount < SearchResultItam && viewableSearchResults.length !== chatgptDataCount + dummy.length &&
                      (resourceId === "2" || resourceId === "1" || resourceId === "3" || resourceId === "7" || resourceId === "4" || resourceId === "5"))) && (
                      <div
                        className="show-more text-center"
                        onClick={showMoreResults}
                      >
                        Show more results
                        <span>
                          <i
                            className="fa fa-angle-down downicon"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    )}
                  {
                    localStorage.getItem("chatgptmsg") && <div
                      className="show-more text-center">
                      {localStorage.getItem("chatgptmsg")}
                    </div>
                  }
                </div>
              ) : (
                <div className="center-div-search">
                  <div className="row">
                    <div className="col-md-8 col-sm-8 col-lg-6 tabssearhimges">
                      {
                        dataFound && dummy.length <= 0 ? <div className="search-no">
                          <p>
                            <span className="text-capitalize"><b>{userData?.firstName}</b></span>, Thank you for your {selectedResourceType} Search using MatchED!  We are sorry we do not have a recommended match for you at this time for this resource type.  As a crowd-sourced platform, we rely in part on our MatchED Community to provide great resources they have found by using our Save feature.  If you find a new resource for this Search, please use our Save feature to store it for your own records.  That will also update our resource cloud making it Searchable to help future MatchED members.  We greatly appreciate your collaboration as part of our community!
                          </p>
                        </div> : localStorage.getItem("chatgptmsg") ? <div className="search-div1">
                          <h4>
                            {localStorage.getItem("chatgptmsg")}
                          </h4>
                        </div> :
                          <div className="search-div1">
                            <h3>Start Your Search</h3>
                            <h4>
                              To begin searching, complete the fields above to receive customized resource recommendations.
                            </h4>
                          </div>
                      }
                    </div>
                    <div className="col-md-12 col-sm-12 col-lg-6">
                      <div className="search-img-container">
                        <div className="image-s1">
                          <img
                            src={searchright}
                            className="search-img-1"
                            alt="MatchED Community"
                          />
                        </div>
                        <div className="image-s2">
                          <img
                            src={searchleft}
                            className="search-img-2 float-start"
                            alt="MatchED Community"
                          />
                          <img src={searchtops} className="search-img-3 float-end" alt="MatchED" />
                        </div>
                        <div className="image-s3"></div>
                        <div className="image-s4">
                          <img src={searchbottom} className="search-img-4" alt="MatchED" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div >
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            {
              alertMessage.msg && alertMessage.msg
            }
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            {
              alertMessage.type === "all" &&
              <>
                <button className="child-delete" onClick={() => navigate("/subscription")}> {" "}
                  Continue
                </button>
                <button className="child-delete" onClick={(() => setOpen(false))}> {" "}
                  Cancel
                </button>
              </>
            }
            {
              alertMessage.type === "search" &&
              <>
                <button className="child-delete" onClick={() => navigate("/subscription")}> {" "}
                  Upgrade
                </button>
                <button className="child-delete" onClick={() => algoliaHit(alertMessage.status)}> {" "}
                  Continue
                </button>
              </>
            }
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
export default Searchpage;
