import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";

export const userLogin = createAsyncThunk("user/login", async (payload) => {
  const response = await makePostRequest(URLS.userLogin, null, payload, null);
  return response.data;
});

const initialState = {
  isAuthenticated: false,
  userData: {},
  currentUser: {},
  phoneNumber: "",
  status: "",
  userChildrens: [],
  selectedResourceType: {},
  SelectFrist: false,
  chatgptPrice: "",
  childList: [],
  panddingList: [],
  searchAlgoliaCount: {
    chatGpt: 0,
  },
  groupList: {
    suggestionGroup: "",
    myGroup: ""
  },
  globalOpenChat: {
 chatOpen : "", userDetail : "", userMapId: "", senderId: "", group:"", pageName : "",
 chat_by_block : ""
  },
  notification: {
    unreadCount: "",
    notification: []
  },
  chatCount:0,
  groupMemberList: [],
  grouppandingList: [],
  chatSound:''
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUserData(state, payload) {
      let { data } = payload;
      if (data) {
        return {
          ...state,
          isAuthenticated: true,
          currentUser: { ...payload.data },
        };
      }
    },
    setUserData(state, payLoad) {
      const { payload } = payLoad;
      if (payload) {
        return {
          ...state,
          userData: payload,
        };
      }
    },
    saveUserChildrens(state, payLoad) {
      const { payload } = payLoad;
      if (payload) {
        return {
          ...state,
          userChildrens: payload,
        };
      }
    },
    getSelectedResourceType(state, payLoad) {
      const { payload } = payLoad;
      if (payload) {
        return {
          ...state,
          selectedResourceType: { ...payload },
        };
      }
    },
    getSelectFrist(state, payLoad) {
      const { payload } = payLoad;
      if (payload) {
        return {
          ...state,
          SelectFrist: payload,
        };
      }
    },
    getchildList(state, payLoad) {
      const { payload } = payLoad;
      if (payload) {
        return {
          ...state,
          childList: payload,
        };
      }
    },
    getFriendList(state, payLoad) {
      const { payload } = payLoad;
      if (payload) {
        return {
          ...state,
          friendList: payload,
        };
      }
    },
    getPanddingList(state, payLoad) {
      const { payload } = payLoad;
      if (payload) {
        return {
          ...state,
          panddingList: payload,
        };
      }
    },

    getChatgptCount(state, payLoad) {
      const { payload } = payLoad;
      return {
        ...state,
        chatgptPrice: payload,
      };
    },
    getSearchAlgoliaCount(state, payLoad) {
      const { payload } = payLoad;
      return {
        ...state,
        searchAlgoliaCount: payload,
      };
    },
    setChatSound(state, payLoad) {
      const { payload } = payLoad;
      return {
        ...state,
        chatSound: payload,
      };
    },
    getGroupList(state, payLoad) {
      const { payload } = payLoad;
      return {
        ...state,
        groupList: payload,
      };
    },
    getNotification(state, payLoad) {
      const { payload } = payLoad;
      return {
        ...state,
        notification: payload,
      };
    },
    getChatCount(state, payLoad) {
      const { payload } = payLoad;
      return {
        ...state,
        chatCount: payload,
      };
    },
    getGroupMemberList(state, payLoad) {
      const { payload } = payLoad;
      return {
        ...state,
        groupMemberList: payload,
      };
    },
    getGroupPandingList(state, payLoad) {
      const { payload } = payLoad;
      return {
        ...state,
        grouppandingList: payload,
      };
    },
    getOpenGlobalChat(state, payLoad) {
      console.log("payloadss", payLoad)
      const { payload } = payLoad;
      
      return {
        ...state,
        globalOpenChat: payload,
      };
    }
  },



  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  checkUserAuth,
  saveUserData,
  saveUserChildrens,
  getSelectedResourceType,
  getSelectFrist,
  getchildList,
  getFriendList,
  getPanddingList,
  getChatgptCount,
  getSearchAlgoliaCount,
  getGroupList,
  getNotification,
  getGroupMemberList,
  getGroupPandingList,
  setUserData,
  getChatCount,
  setChatSound,
  getOpenGlobalChat
} = userSlice.actions;

export default userSlice.reducer;
