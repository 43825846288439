import React, { useEffect } from "react";
import "./Work.css";
import share from "../../assets/Images/share.png";
import Frame53 from "../../assets/Images/Frame 53.png";
import homefrieds from "../../assets/Images/homefrieds.png";
import homegroups from "../../assets/Images/homegroups.png";
import { Link } from "react-router-dom";
const Work = () => {
useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("scrollvalue")
    }, 200);
  }, [])
  return (
    <>
      <div className="Itwork">
        <h2 className="text-center pb-5">How does it work?</h2>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="borderinline mb-3">
                <Link to="/search-page">
                  <div className="imagediv">
                    <i className="fa fa-search iconsearchhome" />
                  </div>
                  <h3>Search</h3>
                  <ul>
                    <li><span className="searchboldcolor">Resources</span>: Printed or digital books, curriculum, online learning, experiences, schools, clubs, tutors, coaches, parent/teacher support.  </li>
                    <li><span className="searchboldcolor">Global Search</span>: Consolidated search across multiple platforms.</li>
                    <li><span className="searchboldcolor">Customized</span>: Personalized results sorted by learning preferences.</li>
                    <li><span className="searchboldcolor">Filtered Search</span>: See what your Friends and Groups have used and reviewed.</li>
                  </ul>
                  <div className="stated-btn">
                    <button ><Link to="/search-page" />Search</button>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 ">
              <div className="borderinline mb-3">
                <Link to="/save-resources-list">
                  <div className="imagediv1">
                    <i className="fa fa-bookmark-o iconsearchhome" />
                  </div>
                  <h3>Save</h3>
                  <ul>
                    <li><span className="saveboldcolor">
                      Student Portfolio</span>: Each student has their own portfolio sorted by grade.
                    </li>
                    <li><span className="saveboldcolor">Artifacts</span>: Store pictures, videos, work samples and memories. </li>
                    <li><span className="saveboldcolor">Resources</span>: Record the educational resourced used for each student for sharing or reporting.</li>
                    <li><span className="saveboldcolor">Rate, Review, Share</span>: Rate and Review your saved resources to share with your Friends and Groups</li>
                  </ul>
                  <div className="stated-btn">
                    <button ><Link to="/save-resources-list" />Save</button>
                  </div>
                   </Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 ">
              <div className="borderinline mb-3">
                <Link to="/share-inspiration">
                  <div className="imagediv2">
                    <img src={share} alt="share"></img>
                  </div>
                  <h3>Share</h3>
                  <ul>
                    <li >
                      <span className="shareboldcolor">Share to Community</span>: Ask questions, start a chat, post an event or resource, or send encouragement to your Friends, Groups, or Local MatchED members.
                    </li>
                     <li>
                      <span className="shareboldcolor">Shared with Me</span>: Stay current with a list of recently shared resources and  events from your Friends, Groups, and Local MatchED Members.
                    </li>
                    <li><span className="shareboldcolor">Local Events</span>: Stay updated on local events and gatherings in your community posted by other MatchED members.</li>
                  </ul>
                  <div className="stated-btn">
                    <button ><Link to="/share-inspiration" />Share</button>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="borderinline mb-3">
                <Link to="/serve-dashboard">
                  <div className="imagediv3">
                    <img src={Frame53} alt="serve"/>
                  </div>
                  <h3>Serve</h3>
                  <ul>
                    <li><span className="serveboldcolor">Post Resources</span>: Organize local field trips and meetings, post services you provide (lessons, coaching, counseling, tutoring), provide mentoring.</li>
                    <li><span className="serveboldcolor">Share</span>: Share resources you offer with Friends, Groups, Local MatchED members or the Global MatchED community.</li>
                    <li><span className="serveboldcolor">Scheduling</span>: Schedule availability and sign-up for services and events</li>
                    <li><span className="serveboldcolor">
                      Payment Processing</span>: Process any fees for services or events.
                    </li>
                  </ul>
                  <div className="stated-btn">
                    <button className="text-center"><Link to="/serve-dashboard" />Serve</button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="homegroupdiv">
             <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="homegroupdiv-inner1">
                  <div className="homegroup-img-friend">
                    <Link to="/purchase-pages-friend">
                      <div className="row">
                        <div className="col-md-12 col-lg-3">
                          <div className="homegroup-img text-center">
                            <img src={homefrieds} className="img-fluid" alt="homefrieds" loading="lazy"/>
                          </div></div>
                          <div className="col-md-12 col-lg-9">
                          <h2>Friends</h2>
                          <div className="homegroup-parainfo">
                            <p>Connect with friends old and new, working together for all students on our platform's social network.</p>
                          </div>
                          <div className="friend-buttons-home">
                            <button className="text-center"><Link to="/purchase-pages-friend" />Friends</button>
                          </div>
                          </div>
                        </div>
                    </Link>
                   </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="homegroupdiv-inner1">
                  <div className="homegroup-img-groups">
                    <Link to="/yourgroup">
                      <div className="row">
                        <div className="col-md-12 col-lg-3">
                          <div className="homegroup-img text-center">
                            <img src={homegroups} className="img-fluid" alt="homegroups" loading="lazy"/>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-9">
                          <h2>Groups</h2>
                          <div className="homegroup-parainfo">
                            <p>Build and support your community by starting a group to share resources, start group conversations, organize events, and provide updates through the bulletin board.</p>
                          </div>
                          <div className="friend-buttons-home">
                            <button className="text-center"><Link to="/yourgroup" />Groups</button>
                          </div>
                        </div>
                        </div>
                    </Link>
                  </div>
                </div>
               </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};
export default Work;
