import React from "react";
import './decision.css';
import { useNavigate } from "react-router-dom";
const Decision = () => {
  const navigate = useNavigate();
  let activeuser = localStorage.getItem('useractive');
 return (
    <div className="decisionMaking pt-5">
      <div className="decisionMaking-innerBox">
        <h4>Simplify the decision making</h4>
        <p>We know there are thousands of decisions parents and educators have to make as they raise and provide education for their children and students.  It can be overwhelming!  Join us through your free membership to start searching, saving, sharing and serving with ease.  Welcome to MatchED!</p>
        <button onClick={() => { !activeuser ? navigate("/sign-in") : navigate("search-page") }}>Get Started</button>
      </div>
      </div>

  );
}
export default Decision;