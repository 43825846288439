import React, { useRef } from "react";
import { useState, useEffect } from "react";
import './Create-new-group.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select'
import { makeFormDateRequset, makeGetRequest, makePostRequest, makePostRequestForAll, imageUrl, } from "../../services/api";
import { URLS } from "../../constants";
import { options } from "../../services/common.services"
import { useDispatch, useSelector } from "react-redux";
import { getFilterOptions } from "../../store/reducers/searchReducer";
import { toastifyMessage, checkUrl } from "../../utilities/CustomFunctions";
import { getGroupList } from "../../store/reducers/userReducer";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import JoditEditor from "jodit-react";
import TextField from "@mui/material/TextField";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CryptoJS from 'crypto-js';
import StripePopup from "./StripePopup";
import Head from "../Layout.WebAppMate/head/Head";
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;
const editorConfig = {
  buttons: [
    'bold', // Bold
    'italic', // Italic
    'underline', // Underline
    'strikethrough', // Strikethrough
    'superscript', // Superscript
    'subscript', // Subscript
    'ul', // Unordered List
    'ol', // Ordered List
    'outdent', // Decrease Indentation
    'indent', // Increase Indentation
    'align', // Align (left, center, right, justify)
    'link', // Insert Link
    'table', // Insert Table
    'undo', // Undo
    'redo', // Redo
  ],
};

/**
 * create components is used create & edit group
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 24-7-2023
 */

const filter = createFilterOptions();
const Creategroup = () => {
  let searchStore = useSelector((state) => state.search);
  const { filterOptions } = searchStore;
  const { learning_pillars } = filterOptions;
  let useractive = localStorage.getItem("useractive");
  const param = useParams();
  const navigate = useNavigate();
  const inputRef = useRef();
  const autoCompleteRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  const inputFocus = useRef();
  const inputFocust = useRef();
  const LearningFocus = useRef();
  const ShareFocus = useRef();
  const AboutFocus = useRef();
  const resourceUrl = useRef();
  const [stripe, setStripe] = useState(false)
  const [gardeSelected, setGradeSelected] = useState([]);
  const [shareWithSelected, setShareWithSelected] = useState([]);
  const [learningPillarSelected, setLearningPillarSelected] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [grade, setGrade] = useState([]);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);
  const [shareWith, setShreWith] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [groupId, setGroupId] = useState([]);
  const [shareFriend, setShareFriends] = useState([]);
  const [friendData, setFriendData] = useState([])
  const [friendList, setFriendList] = useState([]);
  const [spin, setSpin] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [aboutGroupData, setAboutGroupData] = useState("");
  const [isValid, setIsValid] = useState(false)
  const [id, setId] = useState("");
  const [stripeData, setStripeData] = useState([])
  const [countryName, setCountryName] = useState("");
  const [message, setMessage] = useState({
    friend: false,
    group: false
  })
  const [payLoad, setPayLoad] = useState({
    group_name: "",
    url: "",
    group_chat_permission: "0",
    group_join_permission: "admin_approval",
    group_id: ""
  });
  const [address, setAddress] = useState("")
  let Zipcode = /^[a-zA-Z0-9]{5,6}$/;
  const [zipCodeError, setZipCodeError] = useState("");
  const [fullAddress, setFullAddress] = useState({
    city: "",
    state: "",
    zipcode: "",
    latitude: "",
    longitude: "",
  })
  const [inputlist, setInputlist] = useState([{ firstName: "", email: "" }]);
  const shareResourceOptions = [
    { value: "matched", label: "All MatchED" },
    { value: "local", label: "Local MatchED Community" },
    { value: "friend", label: "Friends" },
    { value: "group", label: "Groups" }
  ];


  // this function is use for add inputfield
  const handleadd = () => {
    const values = [...inputlist];
    values.push({
      firstName: "",
      email: "",
    });
    setInputlist(values);
  };
  // this function is use for remove inputfield
  const handleremove = (e, index) => {
    e.preventDefault()
    const values = [...inputlist];
    values.splice(index, 1);
    setInputlist(values);
  };
  const handleInputChange = (index, event) => {
    const values = [...inputlist];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setInputlist(values);
  };

  // this function is use for google address
  const getGoogleAddressStreetOne = () => {
    if (window.google && window.google.maps && window.google.maps.places) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { ...options, disablePoweredByContainer: true }
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();

        let addressComponents;

        let name = "";
        let city = "";
        let state = "";
        let zipcode = "";
        let lat = "";
        let lng = ""
        let fullAddress = "";
        if (place?.address_components) {
          let addressComponents = place.address_components;
          addressComponents.forEach((component) => {
            fullAddress += component.long_name + ", ";
          });
          fullAddress = fullAddress.slice(0, -2);
          setAddress(fullAddress)
          addressComponents.forEach((component) => {
            // name+= component.long_name + ", ";
            const componentType = component.types[0];
            if (componentType === "street_number" || componentType === "route") {
              name += component.long_name + ", ";
            }
            if (componentType === "locality") {
              city = component.long_name;
            }
            if (componentType === "administrative_area_level_1") {
              state = component.long_name;
            }
            if (componentType === "postal_code") {
              zipcode = component.long_name;
            }
          });
          if (place.geometry && place.geometry.location) {
            lat = place.geometry.location.lat();
            lng = place.geometry.location.lng()
          }
          // name = name.slice(0, -2);
          // setAddress(name)
          setFullAddress({ ...fullAddress, "city": city, "state": state, "zipcode": zipcode, "latitude": lat, "longitude": lng })
        }
        else {
          addressComponents = place?.name;
          setAddress(addressComponents);
        }

      });


    } else {
      console.error('Google Maps API not available');
    }
  };

  useEffect(() => {
    if (state === "admin") {
      window.scrollTo({
        top: 1900, behavior: "smooth"
      })
    }
    else {
      window.scrollTo({ top: 0, top: 0 });
    }

  }, [state])

  // this function is use for get keyword & grade data
  const handleeducationbackgrounddata = () => {
    makeGetRequest(URLS.getAllKeywords, null, null, null).then(
      (response) => {
        let options = [];
        response.data.length > 0 &&
          response.data.map((val, i) => {
            options.push({ keyword: val.keyword, label: val.keyword });
          });

        setSelectedOption(options);
      }
    );
  };

  // getting grade options
  const handlegetgradedata = () => {
    makeGetRequest(URLS.usergetallgradelevel, null, null, null).then(
      (response) => {
        setGrade(response.data);
      }
    );
  };

  // get the share group list which is
  const getGroupShare = (id) => {
    makePostRequest(URLS.shareListGroup, null, { "group_id": id }, null)
      .then((res) => {
        let shareArr = [];
        let arr = [];
        for (let i = 0; i < res?.data?.friend?.length; i++) {
          arr.push({ value: res?.data?.friend[i]?.id, label: res?.data?.friend[i].firstName + " " + res?.data?.friend[i]?.lastName });
          shareArr.push(res?.data?.friend[i]?.id)
        }
        setShareFriends(shareArr);
        setFriendData(arr)
        const groupArr = [];
        const shareGroupArr = [];

        for (let i = 0; i < res?.data?.group?.length; i++) {
          groupArr.push({ value: res?.data?.group[i]?.id, label: res?.data?.group[i].group_name });
          shareGroupArr.push(res?.data?.group[i]?.id)
        }
        setGroupData(groupArr);
        setGroupId(shareGroupArr)
      }).catch((error) => {
        console.log("error:::", error)
      })
  }

  // this function use for load time api calling
  useEffect(() => {
    if (useractive === "") {
      navigate("/sign-in");
    }

    handleeducationbackgrounddata();
    handlegetgradedata();
    dispatch(getFilterOptions());
    getGoogleAddressStreetOne();


    document.body.classList.add("createnewGroup");
    return () => {
      document.body.classList.remove("createnewGroup");
    };
  }, []);

  useEffect(() => {
    if (param.id) {
      const encryptedString = atob(param.id); // Decode Base64
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
      const groupId = decryptedBytes.toString(CryptoJS.enc.Utf8);
      setId(groupId)
      groupId && getGroupShare(groupId)
      groupId && getGroupById(groupId);
    }
    getAccounts()
  }, [param.id])

  // handle input start-----
  const handleInput = (e) => {
    const { name, value } = e.target
    if (name === "group_chat_permission") {
      if (e.target.checked) {
        setPayLoad({ ...payLoad, "group_chat_permission": "1" })
      }
      else {
        setPayLoad({ ...payLoad, "group_chat_permission": "0" })
      }
    }
    else if (name === "zipcode") {
      setFullAddress({ ...fullAddress, [name]: value })
    }
    else {
      setPayLoad({ ...payLoad, [name]: value })
    }
  }
// function used for select share with
  const handleselectedOption = (e) => {
    const arr = [];
    const shareWith = [];
    for (let i = 0; i < e.length; i++) {
      arr.push(e[i]);
      shareWith.push(e[i].value)
    }
    setShareWithSelected(arr)
    setShreWith(shareWith)
  };
  //used for select learning pillars
  const handleselectedOptionPillars = (e) => {
    const arr = [];
    for (let i = 0; i < e.length; i++) {
      arr.push(e[i]);
    }
    setLearningPillarSelected(arr)

  }
 //Function used for (when select all deselect previous selected grades)
  const handleGrade = (e) => {
    let arr = [];
    e.length > 0 &&
      e.map((val, i) => {
        arr.push(val.value);
      });
    if ((arr.includes("all") && arr[arr.length - 1] === "all" && arr.length > 1) || (arr.length === 1 && arr.includes("all"))) {
      setGradeSelected([{ label: "All", value: "all" }])
    } else {
      setGradeSelected(e.filter(item => item.value !== "all"))
    }
  }
   //Function used for (when select all deselect previous selected options og group)
  const handleGroupId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.forEach((val, i) => {
        shareArr.push(val.value);
      });
    if ((shareArr.includes("all") && shareArr[shareArr.length - 1] === "all" && shareArr.length > 1) || (shareArr.length === 1 && shareArr.includes("all"))) {
      setGroupData([{ label: "All", value: "all" }]);
      setGroupId(["all"]);
    }
    else {
      setGroupData(shareResourceVal.filter(item => item.value !== "all"))
      setGroupId(shareArr.filter(item => item !== "all"));
    }
  };

  //Function used for (when select all deselect previous selected options)
  const handleFriendsId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.forEach((val, i) => {
        shareArr.push(val.value);

      });
    if ((shareArr.includes("all") && shareArr[shareArr.length - 1] === "all" && shareArr.length > 1) || (shareArr.length === 1 && shareArr.includes("all"))) {
      setShareFriends(["all"]);
      setFriendData([{ label: "All", value: "all" }])
    } else {
      setFriendData(shareResourceVal.filter(item => item.value !== "all"))
      setShareFriends(shareArr.filter(item => item !== "all"));
    }
  };

  // handle input end-----

  useEffect(() => {
    if (shareWith?.includes("friend")) {
      makeGetRequest(URLS.friendList, null, null, null)
        .then((res) => {
          const arr = [];
          res?.data?.length > 0 && arr.push({ label: "All", value: "all" });
          res?.data.map((val) => {
            return arr.push({ label: val.name, value: val.id })
          })
          setFriendList(arr)
        }).catch((error) => {
          console.log("error", error)
        })
    }
    if (shareWith?.includes("group")) {
      makePostRequest(URLS.getGroupList, null, {}, null)
        .then((res) => {
          var arr = [];
          res?.data?.myGroups?.data?.length > 0 && arr.push({ label: "All", value: "all" })
          res?.data?.myGroups?.data?.map((val, index) => {
            if (id != val.id) {
              return arr.push({ label: val?.group_name + " (" + val?.member_type + ") ", value: val?.id })
            }

          })
          setGroupList(arr);
        }).catch((error) => {
          console.log("error:::", error)
        })
    }
  }, [shareWith])
  // this function is use for view group ny id
  const getGroupById = (id) => {
    setSpin(true)
    makePostRequestForAll(URLS.getIdByGroup, { "group_id": id })
      .then((res) => {
        setSpin(false)
        setFileList([{ url: res?.data?.image }])
        setAboutGroupData(res?.data?.about_group ? res?.data?.about_group : "")
        setCountryName(res?.data?.country_name)
        setPayLoad({
          group_name: res?.data?.group_name ? res?.data?.group_name : "",
          url: res?.data?.url,
          group_chat_permission: res?.data?.group_chat_permission ? res?.data?.group_chat_permission : 0,
          group_join_permission: res?.data?.group_join_permission ? res?.data?.group_join_permission : "anyone",
          group_id: res?.data?.group_id
        })
        setFullAddress({
          city: res?.data?.city,
          state: res?.data?.state,
          zipcode: res?.data?.zipcode,
          latitude: res?.data?.latitude,
          longitude: res?.data?.longitude,
        })
        setAddress(res.data.address)
        const grade = [];
        for (let i = 0; i < res?.data?.tbl_group_grade_mappings?.length; i++) {
          grade.push({ value: res?.data?.tbl_group_grade_mappings[i]?.grade_id, label: res?.data?.tbl_group_grade_mappings[i].tbl_grade_level.name });
        }
        setGradeSelected(grade)
        const keyword = [];
        for (let i = 0; i < res?.data?.tbl_group_keywords?.length; i++) {
          keyword.push({ keyword: res?.data?.tbl_group_keywords[i]?.keyword, label: res?.data?.tbl_group_keywords[i].keyword });
        }
        setKeywords(keyword)
        const pillar = [];
        for (let i = 0; i < res?.data?.tbl_group_learning_pillars?.length; i++) {
          pillar.push({ value: res?.data?.tbl_group_learning_pillars[i]?.pillar_id, label: res?.data?.tbl_group_learning_pillars[i].tbl_learning_pillar_master?.name });
        }
        setLearningPillarSelected(pillar)
        const shareWith = [];
        const sharevalue = [];
        const array = res?.data?.shared_with?.split(",")
        for (let i = 0; i < array?.length; i++) {
          if (array[i] === "friend") {
            shareWith.push({ value: array[i], label: "Friends" });
            sharevalue.push(array[i])
          }
          if (array[i] === "local") {
            shareWith.push({ value: array[i], label: "Local MatchED community" });
            sharevalue.push(array[i])
          }
          if (array[i] === "group") {
            shareWith.push({ value: array[i], label: "Groups" });
            sharevalue.push(array[i])
          }
          if (array[i] === "matched") {
            shareWith.push({ value: array[i], label: "All MatchED" });
            sharevalue.push(array[i])
          }
        }
        setShreWith(sharevalue)
        setShareWithSelected(shareWith)
        const useList = [];
        for (let i = 0; i < res?.data?.tbl_group_admin_users?.length; i++) {
          useList.push({ firstName: res?.data?.tbl_group_admin_users[i]?.tbl_user?.firstName, email: res?.data?.tbl_group_admin_users[i]?.tbl_user?.email });
        }
        setInputlist(useList)
      }).catch((error) => {
        console.log("error:::", error)
        setSpin(false)
      })
  }


  // this function is use for create new group
  const createNewGroup = (e) => {
    e.preventDefault();
    const grade = [];
    const keyword = [];
    const pillar = [];
    let valid = true
    if (gardeSelected.length > 0) {
      gardeSelected?.map((val, i) => {
        grade.push(val.value);
      });
    }
    if (keywords.length > 0) {
      keywords?.map((val, i) => {
        keyword.push(val?.keyword?.trim() || val);
      });
    }
    if (pillar.length == 0) {
      learningPillarSelected?.map((val, i) => {
        pillar.push(val.value);
      })
    }
    if (fullAddress.zipcode === "") {
      setZipCodeError("Please enter the Zip Code")
    }
    if (fullAddress.zipcode) {
      if (!Zipcode.test(fullAddress?.zipcode?.replace(/\s/g, ""))) {
        setZipCodeError("Invalid Zip Code");
        inputFocust.current.focus();
      }
    }
    if (payLoad.url) {
      valid = checkUrl(payLoad.url);
      if (!valid) {
        setIsValid(true)
        setError(true)
      }
      else {
        setIsValid(false)
        setError(false)
      }
    }
    if (payLoad?.group_name?.trim() === "") {
      inputFocus.current.focus();
      setError(true)
    } else if (!Zipcode.test(fullAddress?.zipcode?.replace(/\s/g, ""))) {
      inputFocust.current.focus();
    }
    else if (!valid) {
      resourceUrl.current.focus();
    }
    // else if (pillar.length == 0) {
    //   LearningFocus.current.focus();
    //   setError(true)
    // }
    else if (shareWith.length == 0) {
      ShareFocus.current.focus();
      setError(true)
    }
    else if (aboutGroupData === "") {
      AboutFocus.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      setError(true)
    }

    else {
      setSpin(true)
      const body = {
        ...payLoad,
        ...fullAddress,
        "address": address,
        "group_id": "",
        "grade": grade,
        "userList": inputlist[0]?.firstName === "" ? [] : inputlist,
        "keywords": keyword,
        "learning_pillar": pillar,
        "shared_with": shareWith,
        "group_ids": groupId.length == 0 ? [] : groupId,
        "shared_id": shareFriend.length == 0 ? [] : shareFriend,
        "about_group": aboutGroupData
      };
      console.log("body:::", body)
      makePostRequestForAll(URLS.createNewGroup, body)
        .then((res) => {
          console.log("res:::", res)
          if (res.code === 201) {
            if (image) {
              setId(res.data?.data?.id)
              uploadimage(image, res.data?.data?.id, true)
              // const formData = new FormData();
              // formData.append("group_id", res.data?.data?.id);
              // formData.append("image", image);

              // makeFormDateRequset(URLS.groupImage, formData)
              //   .then((res) => {
              //     makePostRequestForAll(URLS.getSidebarGroupImage, {})
              //       .then((res) => {
              //         dispatch(getGroupList({
              //           suggestionGroup: res?.data?.joinedGroup,
              //           myGroup: res?.data?.myGroups
              //         }))
              //       }).catch((error) => {
              //         console.log("error:::", error)
              //         setSpin(false)
              //       })
              //     if (res.code === 200) {
              //       setFileList([{ url: imageUrl + res?.data?.image }])
              //       setSpin(false)
              //       toastifyMessage("Group created successfully", 'success')
              //       navigate("/yourgroup");
              //     }
              //   }).catch((error) => {
              //     setSpin(false)
              //     // toastifyMessage("Something went wrong", 'error')
              //   })
            } else {
              toastifyMessage("Group created successfully", 'success')
              navigate("/yourgroup");
            }
          } else {
            toastifyMessage(res.message, 'warn')
            setSpin(false)
          }
        }).catch((error) => {
          setSpin(false)
          toastifyMessage("Something went wrong", 'error')
        })
    }

  }

  // this function is use for update group
  const updateGroup = (e) => {
    e.preventDefault();
    const grade = [];
    const keyword = [];
    const pillar = [];
    let valid = true
    // const shareWith = [];
    if (gardeSelected.length > 0) {
      gardeSelected?.map((val, i) => {
        grade.push(val.value);
      });
    }
    if (keywords.length > 0) {
      keywords?.map((val, i) => {
        keyword.push(val?.keyword?.trim() || val);
      });
    }
    if (pillar.length == 0) {
      learningPillarSelected?.map((val, i) => {
        pillar.push(val.value);
      })
    }
    if (fullAddress.zipcode === "") {
      setZipCodeError("Please enter the Zip Code")
    }
    if (fullAddress.zipcode) {
      if (!Zipcode.test(fullAddress?.zipcode?.replace(/\s/g, ""))) {
        setZipCodeError("Invalid Zip Code");
        inputFocust.current.focus();
      }
    }
    if (payLoad.url) {
      valid = checkUrl(payLoad.url);
      if (!valid) {
        setIsValid(true)
        setError(true)
      }
      else {
        setIsValid(false)
        setError(false)
      }
    }
    if (payLoad.group_name === "") {
      inputFocus.current.focus();
      setError(true)
    } else if (!Zipcode.test(fullAddress?.zipcode?.replace(/\s/g, ""))) {
      inputFocust.current.focus();
    }
    else if (!valid) {
      resourceUrl.current.focus();
    }
    // else if (pillar.length == 0) {
    //   LearningFocus.current.focus();
    //   setError(true)
    // }
    else if (shareWith.length == 0) {
      ShareFocus.current.focus();
      setError(true)
    }
    else if (aboutGroupData === "") {
      AboutFocus.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      setError(true)
    }
    else if (!inputlist[0].firstName && !inputlist[0].email) {
      setError(true)
    }
    else {
      const body = {
        ...payLoad,
        ...fullAddress,
        "address": address,
        "group_id": id ? id : "",
        "grade": grade,
        "userList": inputlist[0]?.firstName === "" ? [] : inputlist,
        "keywords": keyword,
        "learning_pillar": pillar,
        "shared_with": shareWith,
        "group_ids": groupId.length == 0 ? [] : groupId,
        "shared_id": shareFriend.length == 0 ? [] : shareFriend,
        "about_group": aboutGroupData
      };
      makePostRequestForAll(URLS.updateMyGroup, body)
        .then((res) => {
          setSpin(false)
          if (res.code == 200) {
            toastifyMessage(res.message, 'success');
            navigate(-1);
            makePostRequestForAll(URLS.getSidebarGroupImage, {})
              .then((res) => {
                dispatch(getGroupList({
                  suggestionGroup: res?.data?.joinedGroup,
                  myGroup: res?.data?.myGroups
                }))
              }).catch((error) => {
                console.log("error:::", error)
              })
          } else {
            toastifyMessage(res.message, 'warn');
          }

        }).catch((error) => {
          setSpin(false)
          toastifyMessage("Something went wrong", 'error')
        })
    }

  }
  const [imgflag, setimgflag] = useState(false);
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      url: imageUrl + "groupdefult.jpeg",
    },
  ]);
  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  //used for delete profile picture
  const removeImage = () => {
    if (param.id) {
      const encryptedString = atob(param.id); // Decode Base64
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
      const groupId = decryptedBytes.toString(CryptoJS.enc.Utf8);
      setimgflag(false)
      makePostRequest(URLS.groupImageDelete, null, { "group_id": groupId }, null)
        .then((res) => {
          console.log("res::", res)
          toastifyMessage(res.message, "success")
        }).catch((error) => {
          console.log("error::", error)
        })
    } else {
      setimgflag(false)
    }

  }
  const beforeCrop = (file) => {
    if (
      file.name.toLowerCase().includes("jpg") ||
      file.name.toLowerCase().includes("png") ||
      file.name.toLowerCase().includes("jpeg")
    ) {
      setimgflag(false);
      return true;
    } else {
      setimgflag(true);
      return false;
    }
  };
//used for profile upload
  function uploadimage(cropimage, id, status) {
    if (id) {
      const formData = new FormData();
      formData.append("group_id", id);
      formData.append("image", cropimage ? cropimage : "");
      setSpin(true)
      makeFormDateRequset(URLS.groupImage, formData)
        .then((res) => {
          if (res.code === 200 && !status) {
            setFileList([{ url: imageUrl + res?.data?.image }])
            console.log("res::", res)
            setSpin(false)
            toastifyMessage(res.message, 'success')
            makePostRequestForAll(URLS.getSidebarGroupImage, {})
              .then((res) => {
                dispatch(getGroupList({
                  suggestionGroup: res?.data?.joinedGroup,
                  myGroup: res?.data?.myGroups
                }))
              }).catch((error) => {
                console.log("error:::", error)
              })
          } else if(res.code === 200 && status){
            toastifyMessage("Group created successfully", 'success')
            navigate("/yourgroup");
          }
        }).catch((error) => {
          setSpin(false)
          toastifyMessage("Something went wrong", 'error')
        })

    } else {
      setImage(cropimage)
    }

  }

  // this API use for get strip account
  const getAccounts = () => {
    makePostRequest(URLS.getStripeAccount, null, {}, null)
      .then((res) => {
        const arr = [];
        res?.data?.forEach((val, index) => {
          if (val?.stripe_setup_done) {
            return arr.push({ value: val?.id, label: val?.email, id: val?.stripe_account_id, status: true, payouts_enabled: val?.payouts_enabled, charges_enabled: val?.charges_enabled })
          } else {
            return arr.push({ value: val?.id, label: val?.email, id: val?.stripe_account_id, status: false, payouts_enabled: val?.payouts_enabled, charges_enabled: val?.charges_enabled })
          }
        })
        setStripeData(arr)

      }).catch((error) => {
        console.log("error::", error)
      })
  }


  return (<>
    {
      spin && <div className="sharepopupOuter backgroundOpacity1"><FourDotsLoader /></div>
    }
    {
      (param.id !== undefined && stripe) && <StripePopup datar={() => setStripe(!stripe)} user={false} groupId={id} data={stripeData} />
    }
    <Head title="Create Group"
      content="Build and support your community by starting a group to share resources, start group conversations, organize events, and provide updates through the bulletin board."
      name="Create Groups" ></Head>
    <div className="col-lg-10 col-md-12 col-sm-12">
      <div className="create-new">
        <div className="create-new-group">
          <button className="deshbord pt-1">
            <span onClick={() => navigate(-1)}>
              <i className="fa fa-angle-left" aria-hidden="true"></i>
              {" "} Go Back
            </span>

          </button>
          {
            param.id == undefined ? <h2>Create New Group</h2> : <h2>Update Group</h2>
          }
          <p>Share resources, start group conversations, organize events, and provide updates through the Bulletin Board.</p>
        </div>
        <div className="mt-2">
          <div className="profile-div">
            <div className="input-file1"><div className="upload11">Upload photo</div></div>
            <ImgCrop
              cropperProps={{ restrictPosition: false }}
              cropShape="round"
              showGrid
              showReset
              minZoom={0.2}
              zoomSlider
              rotationSlider
              aspect={10.5 / 9}
              height={1200}
              beforeCrop={(file) => beforeCrop(file)}
              onModalOk={(cropedimg) => uploadimage(cropedimg,id,false)}
            >
              {fileList ? (
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-circle"
                  onChange={onChange}
                  onRemove={removeImage}
                  // beforeUpload={beforeUpload}
                  fileList={fileList}
                >
                  {fileList === "" && (
                    <div className="text">
                      {" "}
                      <PlusOutlined /> upload
                    </div>
                  )}
                </Upload>
              ) : (
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-circle"
                  onChange={onChange}
                >
                </Upload>
              )}
            </ImgCrop>
          </div>
          {imgflag == true ? (
            <span className="error"> Only png, jpg, jpeg are allowed</span>
          ) : null}
          {
            param.id !== undefined && <><div className="profileStripSetings respprofile resstripset">
              {(countryName === "US" || countryName === "CA" ) ?
              <button
              className="strip-activation"
              stripe="true"
              activation="true"
              onClick={() => setStripe(true)}
            >
              {stripeData.length > 0 ? "Manage Stripe Account" : "Stripe Activation"}
            </button>
             :
             "" }
            <button
            className="strip-activation  deactive-group-update"
            stripe="true"
            activation="true"
          ><a  className="profileStripSetings" href="https://matchedcommunity.zendesk.com/hc/en-us/requests/new" target="_blank"> Deactivate Group</a>
            
          </button>
          </div>
          </>
          }
        </div>
        <form>
          <div className="create-new-form">
            <div className="title-group">
              <label htmlFor="username" className="label-form-div">Group Name<strong className="red-color">*</strong>{/* <span>Type Required Group Name</span> */}</label>
              <input type="text" className="form-control" placeholder="Group Name" name="group_name" onChange={handleInput} ref={inputFocus} value={payLoad.group_name} />
              <span className="error-span">{error == true ? payLoad?.group_name?.trim() === "" && "Please enter group name." : ""}</span>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 title-group">
                <label htmlFor="username" className="label-form-div">Location</label>
                <input type="text" className="form-control" id="inputAddress" placeholder="Enter address" name="address" value={address} ref={inputRef} onChange={(e) => setAddress(e.target.value)} />
              </div>
              <div className="col-lg-6 col-12 title-group">
                <label htmlFor="username" className="label-form-div">Zip Code<strong className="red-color">*</strong></label>
                <input type="text" className="form-control" placeholder="Zip code" name="zipcode" onChange={handleInput} ref={inputFocust} value={fullAddress.zipcode} />
                {(!Zipcode.test(fullAddress?.zipcode?.replace(/\s/g, "")) || fullAddress.zipcode === "") && <span className="error-span">{zipCodeError}</span>}
                <div className="facustomeI">i<div className="tootlippillerinfo">
                    For users outside the U.S. and Canada: If you don't have a zip code, please enter '111111' to continue.
                    </div></div>
              </div>
            </div>
            <div className="seleter-div">
              <label htmlFor="username" className="label-form-div ">Keywords <span>enter any/all keywords associated with this group </span></label>
              <div className="kayword-associatedNew">
                <Autocomplete
                  multiple
                  value={keywords}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setKeywords({
                        keyword: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setKeywords({
                        keyword: newValue.inputValue,
                      });
                    } else {
                      let filteredArray = newValue.filter(item => {
                        if (typeof item === 'string') {
                          return item.trim() !== '';
                        }
                        if (item.hasOwnProperty('inputValue') && typeof item.inputValue === 'string') {
                          return item.inputValue.trim() !== '';
                        }
                        return true;
                      });
                      // console.log(filteredArray);
                      setKeywords(filteredArray);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.keyword
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        keyword: inputValue,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={selectedOption}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.keyword;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.keyword}</li>
                  )}
                  sx={{ width: 300 }}
                  freeSolo
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Keywords" />
                  )}
                />
              </div>
            </div>

            <div className="url-link">
              <label htmlFor="username" className="label-form-div ">URL <span>if you want your own url</span></label>
              <input type="text" className="form-control" id="inputAddress" placeholder="URL'S" name="url" onChange={handleInput} value={payLoad.url} ref={resourceUrl} />
            </div>
            <span className="error-span">{error && isValid && " Url is not valid."}</span>


            <div className="grade-range">
              <label htmlFor="username" className="label-form-div ">Grade Range <span>(select one or multiple)</span></label>
              <Select
                onChange={handleGrade}
                options={grade && [{ value: "all", label: "All" }, ...grade.map((temp, key) => ({
                  value: temp.id,
                  label: temp.name,
                }))]}
                value={gardeSelected}
                isMulti={true}
              // closeMenuOnSelect={false}
              />
              {/* <span className="error-span py-2">
                {
                  message.grade && "Note: Now you can not able to select any grade"
                }
              </span> */}
            </div>
            <div className="learning-pillar">
              <label htmlFor="username" className="label-form-div ">Learning Pillars<span> (select one or multiple)</span></label>
              <Select
                onChange={handleselectedOptionPillars}
                options={learning_pillars && learning_pillars.map((temp, key) => ({
                  value: temp.id,
                  label: temp.name,
                }))}
                value={learningPillarSelected}
                isMulti={true}
                // ref={LearningFocus}
              // closeMenuOnSelect={false}
              />
              {/* <span className="error-span">{error && learningPillarSelected.length == 0 && "Please select learning pillar."}</span> */}
            </div>
            <div className="share-group">
              <label htmlFor="username" className="label-form-div ">Who would you like to share this group with?<strong>*</strong><span> (select at least one)</span></label>
              <Select
                name="share_with"
                onChange={handleselectedOption}
                options={shareResourceOptions && shareResourceOptions.map((temp) => ({
                  value: temp.value,
                  label: temp.label,
                }))}
                value={shareWithSelected}
                isMulti={true}
                ref={ShareFocus}
              // closeMenuOnSelect={false}
              />
              <span className="error-span">{error && shareWith.length == 0 && "Please select who you would like to share this with."}</span>
            </div>
            {
              shareWith?.includes("friend") && <div className="input-popup2">
                <label className="pop-label my-2">
                  Select the friends you would like to share this with:
                  {/* <span className="start-color">*</span> */}
                  {" "}
                  <span className="select-one">(select at least one)</span>
                </label>
                <div>
                  <Select
                    value={friendData ? friendData : null}
                    options={friendList}
                    isMulti
                    selectMultiple={true}
                    onChange={handleFriendsId}
                  // closeMenuOnSelect={false}
                  />
                  <span className="error-span">
                  </span>
                </div>
                <span className="error-span">
                  {
                    message.friend && "Note: You have already selected all your friends."
                  }
                </span>
              </div>
            }
            {
              shareWith?.includes("group") && <div className="input-popup2">
                <label className="pop-label my-2">
                  Select the groups you would like to share this with:
                  {/* <span className="start-color">*</span> */}
                  {" "}
                  <span className="select-one">(select at least one)</span>
                </label>
                <div>
                  <Select
                    value={
                      groupData ? groupData : null
                    }
                    options={groupList}
                    isMulti
                    selectMultiple={true}
                    onChange={handleGroupId}
                  // closeMenuOnSelect={false}
                  />
                  <span className="error-span">
                  </span>
                </div>
                <span className="error-span">
                  {
                    message.group && "Note: You have already selected all your Groups."
                  }
                </span>
              </div>
            }
            <div className="about-group" ref={AboutFocus}>
              <label htmlFor="username" ><label className="label-form-div ">About this Group<strong>*</strong></label></label>
              <JoditEditor name="about_group"
                config={editorConfig}
                value={aboutGroupData ? aboutGroupData : ""}
                onBlur={(newContent) => setAboutGroupData(newContent)}

              />
              <span className="error-span">
                {
                  error == true ? aboutGroupData === "" && "Please enter about resource." : " "
                }
              </span>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="permission-group">
                  <label className="form-h3">Permission to join the group<span className="start-color">* </span><span className="select-one">(select one)</span> </label>
                  <div className='radio-btn-handle'>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="group_join_permission" checked={payLoad.group_join_permission === "admin_approval"} id="inlineRadio222" onChange={handleInput} value="admin_approval" />
                      <label className="form-check-label  handle-radio" htmlFor="inlineRadio222">Join upon admin approval</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="group_join_permission" checked={payLoad.group_join_permission === "anyone"} id="inlineRadio111" onChange={handleInput} value="anyone" />
                      <label className="form-check-label  handle-radio" htmlFor="inlineRadio111" >Anyone can join</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-chat-permission">
              <label htmlFor="username" className="label-form-div ">Group Chat Permission<strong>*</strong></label>
              <div className="permission">
                <label className="switch" htmlFor="viewable-friend">
                  <input type="checkbox" id="viewable-friend" name="group_chat_permission" checked={payLoad.group_chat_permission == "1" ? true : false} onChange={handleInput} value="1" />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="admin-group">
              <h4>Add Group Admin
              </h4>
            </div>
            {inputlist.map((val, index) => {
              const isLastInput = inputlist.length === 1;
              return (
                <div className="row">
                  <div className="col-lg-5">
                    <label
                      htmlFor="name"
                      className="form-label1"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="firstName"
                      onChange={(event) =>
                        handleInputChange(index, event)
                      }
                      value={val.firstName}
                    />
                  </div>
                  <div className="col-lg-5">
                    <label htmlFor="Email" className="form-label1 ">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Email"
                      name="email"
                      onChange={(event) =>
                        handleInputChange(index, event)
                      }
                      value={val.email}
                    />
                  </div>

                  <div className="col-lg-2">
                    <div className="add">
                      <label htmlFor="Email" className="form-label1 w-100">

                      </label>
                      {inputlist.length !== -1 && (
                        <>
                          {!isLastInput && (
                            <button
                              className="add-remove"
                              onClick={(e) => handleremove(e, index)}
                            >
                              X
                            </button>
                          )}</>

                      )}
                    </div>
                  </div>
                  <span className="error-span">
                    {
                      param.id != undefined && error == true ? (!inputlist[0].firstName && !inputlist[0].email) && " you need create another admin user. " : " "
                    }
                  </span>
                  <div className="float-start">
                    {inputlist.length - 1 == index && (
                      <button className="add-add" onClick={handleadd}>
                        Add more +
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="save-btn1">
              {
                param.id == undefined ? <button className="s-button" onClick={createNewGroup} >
                  Create
                </button> : <button className="s-button" onClick={updateGroup} >
                  Update
                </button>
              }

            </div>
          </div>
        </form>
      </div>
    </div>
  </>);
}
export default Creategroup;