import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCRa6OST-JFsa05m64Yxm4zZ9pL2NTmSb4",
  authDomain: "extended-creek-399422.firebaseapp.com",
  projectId: "extended-creek-399422",
  storageBucket: "extended-creek-399422.appspot.com",
  messagingSenderId: "166249572604",
  appId: "1:166249572604:web:bfe6bdce9d8fec9d990f79",
  measurementId: "G-4HFED19JQJ"
};

const app = initializeApp(firebaseConfig);

export const messaging = async () => {
  if (await isSupported()) {
    return getMessaging(app);
  } else {
    console.log("Firebase Cloud Messaging is not supported in this environment.");
    return null;
  }
};

export function isIOS() {
  const browserInfo = navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(browserInfo) || (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform));
}

export const ganrateToken = async () => {
  try {
    if (isIOS()) return;
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const currentToken = await getToken(await messaging(), {
        vapidKey: 'BLSF-sCa1KWGUJ6ytKj7kgk4mBAvXjLxeD61V_DJcTDGP2tvcJJ8wV2ZtFZXFYaL4wLRNvU2LRX7NAzTdWyEIKU',
      });
      return currentToken;
    } else {
      console.log("User permission denied.");
    }
  } catch (error) {
    console.error("Error during permission request:", error);
  }
  return null;
};

// export const onMessageListener = () => 
//   new Promise((resolve) => {
//     messaging().then((messagingInstance) => {
//       onMessage(messagingInstance, (payload) => {
//         console.log("payload",payload)
//         resolve(payload);
//       });
//     }).catch((err) => {
//       console.error('Failed to initialize messaging instance:', err);
//     });
//   });



// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyCRa6OST-JFsa05m64Yxm4zZ9pL2NTmSb4",
//   authDomain: "extended-creek-399422.firebaseapp.com",
//   projectId: "extended-creek-399422",
//   storageBucket: "extended-creek-399422.appspot.com",
//   messagingSenderId: "166249572604",
//   appId: "1:166249572604:web:bfe6bdce9d8fec9d990f79",
//   measurementId: "G-4HFED19JQJ"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging(app);
// export function isIOS() {
//   const browserInfo = navigator.userAgent.toLowerCase();
//   return /iphone|ipad|ipod/.test(browserInfo) || (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform));
// }
// export const ganrateToken = async () => {
//   try {
//     if (isIOS()) return;
//     const permission = await Notification.requestPermission()
//     console.log(permission)
//     if (permission === "granted") {
//       const currentToken = await getToken(messaging, {
//         vapidKey: 'BLSF-sCa1KWGUJ6ytKj7kgk4mBAvXjLxeD61V_DJcTDGP2tvcJJ8wV2ZtFZXFYaL4wLRNvU2LRX7NAzTdWyEIKU',
//       });
//       console.log(currentToken)
//       return currentToken;
//     } else {
//       console.log("User permission denied.");
//     }
//   } catch (error) {
//     console.error('Failed to initialize messaging instance:', error);
//   }

// }