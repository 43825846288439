
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillEye } from 'react-icons/ai';
import { AiFillEyeInvisible } from 'react-icons/ai';
import { makePostRequest } from "../../services/api"
import { URLS } from "../../constants";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";

const Updatepass = () => {
	const navigate = useNavigate();
	const [passicon, setpassicon] = useState(false)
	const [passicon1, setpassicon1] = useState(false)
	const [newpassword, setnewpasswords] = useState('')
	const [confirmpass, setConfirmpass] = useState('')
	const [errornewpass, setErrorsnewpass] = useState('');
	const [errorconfirmpass, setErrorsconfirmpass] = useState('');
	const [showspin, setshowspin] = useState(false)
	const params = useParams();

	// this function use for update passoword API calling
	const updatefun = async () => {
		if (newpassword === "") {
			setErrorsnewpass("Please enter new password.")
		} else if (newpassword.length < 8 || newpassword.length > 20) {
			setErrorsnewpass("Please enter a password between 8 and 20 characters long.")
		} else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(newpassword)) {
			setErrorsnewpass("Please include at least one lowercase letter, one uppercase letter, one number, and one special character.'")
		}else if (confirmpass === "") {
			setErrorsconfirmpass("Please enter confirm password")
		} else if (newpassword !== confirmpass) {
			setErrorsconfirmpass("Password dosen't match.")
		} else {
			var payload = {
				"user_id": params.id,
				"newPassword": newpassword,
				"confirmPassword": confirmpass,
			}
			await makePostRequest(URLS.userupdatePassword, null, payload, null)
				.then((res) => {
					if (res.code === 200) {
						setshowspin(true)
						setTimeout(() => {
							navigate("/sign-in")
						}, 3000)
						toast.success("Your password has been successfully reset.")
					}
					else if (res.code === 400) {
						setshowspin(false)
						toast.warn("This link has expired.")
					}

				})
				.catch((err) => {
					console.log(err)
				})
		}
	}
	useEffect(() => {
		document.body.classList.add('password/:id')
		return () => {
			document.body.classList.remove('password/:id')
		}
	}, []);
	return (<>
		<ToastContainer />
		<form className="frm">
			<div className="login">
				<div className="loginouter">
					{showspin &&
						<div className="sharepopupOuter backgroundOpacity1">
							<FourDotsLoader />
						</div>
					}
					<h1>Reset Password</h1>
					<div className="form-group">
						<input type={passicon === false ? "password" : "text"} className="form-control" id="inputEmail" placeholder="Password" onChange={(e) => {
							setnewpasswords(e.target.value);setErrorsnewpass('')
						}} />
						<span className="error"> {errornewpass} </span>
						<span className="showhide" onClick={() => setpassicon(!passicon)} >
							{passicon === true ?
								<AiFillEye /> : <AiFillEyeInvisible />}
						</span>
					</div>
					<div className="form-group">
						<input type={passicon1 === false ? "password" : "text"} className="form-control" id="inputEmail" placeholder="Confirm Password" onChange={(e)=>{setConfirmpass(e.target.value);setErrorsconfirmpass('')}} />
						<span className="error"> {errorconfirmpass} </span>
						<span className="showhide" onClick={() => setpassicon1(!passicon1)} >
							{passicon1 === true ?
								<AiFillEye /> : <AiFillEyeInvisible />}
						</span>
					</div>
					<button type="button" className="btn11 forgetbtn" onClick={updatefun}><Link to="" style={{ textDecoration: "none", color: "white" }}>Submit</Link></button>
				</div>
			</div>
		</form>
	</>);
}
export default Updatepass