import React, { useEffect, useState, useRef } from 'react'
import FourDotsLoader from '../Common.WebAppMate/FourDoted';
import { decodeToken, getTimeDifference, style, toastifyMessage } from "../../utilities/CustomFunctions";
import { URLS } from '../../constants';
import './Yourgroup.css';
import DeleteMember from './DeleteMember';
import { makePostRequest, makePostRequestForAll } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Threedotspopup from '../Common.WebAppMate/Threedotspopup';
import PermissionSharepopup from '../ShareInspiration.WebAppMate/Permissonsharepopup';
import PermissionRemovepopup from '../ShareInspiration.WebAppMate/RemovePermisson';
import { useSelector, useDispatch } from 'react-redux';
import LeaveGroupPopup from './LeaveGroupPopup';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InviteGroups from "./Invitegroups";
import SingleChat from '../Chat.WebAppMate/SingleChat';
import AdminList from './AdminList';
import MemberRequest from './MemberRequest';
import PendingList from './PendingList';
import Multiselect from 'multiselect-react-dropdown';
import { useLocation } from 'react-router-dom';
import { getOpenGlobalChat } from "../../store/reducers/userReducer";
/**
 * create components is used member listing
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 28-7-2023
 */
const MemberList = (props) => {
    const userId = decodeToken().userAuth.id;
    const navigate = useNavigate();
    let dispatch = useDispatch();
    const location = useLocation();
    const [groupMemberList, setGroupMemberList] = useState([]);
    const usersrote = useSelector((store) => store.user)
    const [show, setshow] = useState(false);
    const [invitegroup, setInvitegroup] = useState(false)
    const [matchId, setMatchId] = useState("");
    const [sharpermision, setSharepermision] = useState(false);
    const [removePermissions, setRemovePermissions] = useState(false);
    const [remainingCount, setRemainingCount] = useState("");
    const [page, setPage] = useState(1);
    const [open, setOpen] = React.useState(false);
    const [memberId, setMemberId] = useState("");
    const [search, setSearch] = useState("");
    const [pendingSearch, setPendingSearch] = useState("");
    const [requestSearch, setRequestSearch] = useState("");
    const [search1, setSearch1] = useState("");
    const [spin, setSpin] = useState(false);
    const [AdminBox, setAdminBox] = React.useState(false);
    const [adminId, setAdminId] = useState([]);
    const [groupOpen, setGroupOpen] = useState(false);
    const [lastAdmin, setLastAdmin] = useState("");
    const [chatOpen, setChatOpen] = useState("");
    const [userDetail, setUserDetail] = useState("");
    const [cbstatus, setCbstatus] = useState("");
    const [grp, setGrp] = useState(0);
    const [userMapId, setUserMapId] = useState("");
    const [openfrnd, setOpenfrnd] = useState(false);
    const [groupId, setGroupId] = useState("");
    const [friendId, setFriendId] = useState("");
    const [type, setType] = useState("");
    const menuRef = useRef(null);
    const menuBtnRef = useRef(null);
    const pendingRef = useRef(null);
    const adminRef = useRef(null);
    const requestRef = useRef(null);

    // using for open PermissionSharepopup and this pop up allowning to share the permission with other group members to see the profile/details of group owner child
    const permision = () => {
        setSharepermision(true);
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };
    // using for remove permission
    const removePermission = () => {
        setRemovePermissions(true);
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }
    //this fun used for getting input box value in states on onange
    const searchValue = (e) => {
        if (type === "Members") {
            setSearch(e.target.value)
        }
        if (type === "Admins") {
            setSearch1(e.target.value)
        }
        if (type === "Requested") {
            setRequestSearch(e.target.value);
        }
        if (type === "Pending") {
            setPendingSearch(e.target.value);
        }
    }
    // this is used for : When type changed searchbox should be blank
    useEffect(() => {
        if (type !== "Members") {
            setSearch("")
        }
        if (type !== "Admins") {
            setSearch1("")
        }
        if (type !== "Requested") {
            setRequestSearch("");
        }
        if (type !== "Pending") {
            setPendingSearch("");
        }
    }, [type])

    //This function calling for invite friend
    const InviteFriend = () => {
        const data = {
            "friend_id": friendId
        }
        makePostRequestForAll(URLS.friendInvite, data)
            .then((res) => {
                console.log("res:::", res)
                if (res.code === 201) {
                    toastifyMessage(res.message, "success");
                    setOpenfrnd(false);
                } else {
                    toastifyMessage(res.message, "warn");
                    setOpenfrnd(false);
                }
            })
    }
    // This func used for getting list of group members
    const getGroupmember = (id) => {
        setSpin(true)
        const data = {
            "group_id": id,
            "page_number": page,
            "searchKey": search,
            "item_per_page": 8
        }
        makePostRequest(URLS.getGroupMember, null, data, null)
            .then((res) => {
                setSpin(false)
                const arr = []
                res?.data?.groupDetails?.group_admin_users?.map((val) => {
                    return arr.push(val.user_id)
                })
                setAdminId(arr)
                if (page === 1) {
                    setGroupMemberList(res?.data?.groupMemberList)
                }
                else {
                    setGroupMemberList([...groupMemberList, ...res?.data?.groupMemberList]);
                }
                setRemainingCount(res?.data?.remaining_count)
            }).catch((error) => {
                console.log("error:::", error)
            })
    }
    useEffect(() => {
        if (type === "Members") {
            getGroupmember(props.id)
        }
        else if (type === "Pending") {
            setGroupMemberList([])
        }
        // eslint-disable-next-line 
    }, [page, type])

    //Calling functions on click of search
    const handleSearch = (id) => {
        if (type === "Members") {
            setGroupMemberList([]); setPage(1);
            getGroupmember(props.id)
        } else if (type === "Requested") {
            requestRef.current.getCheck(1, 0);
        }
        else if (type === "Admins") {
            adminRef.current.getCheck(1.0);
        } else if (type === "Pending") {

            pendingRef.current.getCheck(1, 0);
        }
    }
    // this function use for user connect
    const AddUser = (id, userData) => {
        setSpin(true)
        dispatch(getOpenGlobalChat({ ...usersrote?.globalOpenChat, chatOpen: false }))
        makePostRequestForAll(URLS.addChatUser, { chat_with: id })
            .then((res) => {
                if (res.code === 200) {
                    setUserMapId(res?.data?.id);
                    dispatch(getOpenGlobalChat({ ...usersrote?.globalOpenChat, chatOpen: true, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: grp, pageName: "memberlist" 
                        , chat_by_block : res?.data?.block
                    }))
                    setSpin(false)
                }
            })
            .catch((e) => {
                console.log("error:::::", e)
                setSpin(false)
            });
    };
    useEffect(() => {
        usersrote?.groupMemberList && setGroupMemberList(usersrote.groupMemberList)
    }, [usersrote.groupMemberList])

    // Using for close three dot pop when click outside
    const handleClickOutside = (e) => {
        if (
            menuRef?.current &&
            !menuRef?.current?.contains(e.target) &&
            !menuBtnRef?.current?.contains(e.target)
        ) {
            setshow(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, true);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, true);
        };
    }, []);

    // this is used for getting type of member on change of tab
    useEffect(() => {
        if (props?.tab === 1) {
            setType("Members")
        } if (props?.tab === 2) {
            setType("Requested")
        } if (props?.tab === 4) {
            setType("Admins")
        } if (props?.tab === 5) {
            setType("Pending")
        }
    }, [props?.tab])

    useEffect(() => {
        if (cbstatus?.callback === "callback") {
            setUserMapId(cbstatus?.id?.id)
            setGrp(cbstatus?.callback === "callback" ? 0 : 1)
            setUserDetail(cbstatus?.data)
            dispatch(getOpenGlobalChat({ ...usersrote?.globalOpenChat, chatOpen: true, userMapId: cbstatus?.id?.id, userDetail: cbstatus?.data, group: cbstatus?.callback === "callback" ? 0 : 1,  pageName: "session"}))
  
        }
    }, [cbstatus])
    // useEffect(() => {
    //     // This effect runs whenever location (URL) changes so chat window not open again when you come back on this page again
    //     dispatch(getOpenGlobalChat(
    //       {chatOpen : false, userDetail : "", userMapId: "", senderId: "", group: "", pageName : "" })) // Reset or clear the userstore data here
    //   }, [location.pathname]);
    return (
        <div>
            {
                spin && <div className="sharepopupOuter backgroundOpacity1"><FourDotsLoader /></div>
            }
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='purchasebartop d-flex justify-content-between'>
                        <div className="purchasebarsearch groupchangeinfo">
                            <div className="newgroupmemberselectbox float-start">
                                <div className='share-multiple-form-select'>
                                    <Multiselect
                                        options={[
                                            { name: "Active Members", value: "Members" },
                                            ...(props?.admin
                                                ? [
                                                    { name: "Requested", value: "Requested" },
                                                    { name: "Pending Members", value: "Pending" },
                                                ]
                                                : []
                                            ),
                                            { name: "Admin(s)", value: "Admins" },
                                        ]}
                                        onSelect={(selectedList) => {
                                            const selectedValue = selectedList.length > 0 ? selectedList[0].value : "";
                                            setType(selectedValue);
                                            setPage(1)
                                        }}
                                        selectedValues={type === "Members" ? [{ name: "Active Members", value: "Members" }] :
                                            type === "Requested" ? [{ name: "Requested", value: "Requested" }] :
                                                type === "Admins" ? [{ name: "Admin(s)", value: "Admins" }] :
                                                    type === "Pending" ? [{ name: "Pending Members", value: "Pending" }] : ""}
                                        displayValue="name"
                                        placeholder="Request"
                                        singleSelect
                                    />
                                </div>
                            </div>
                            {
                                (type === "Members" || "Requested" || "Admins" || "Pending")
                                    ? <div className="searchbarlist float-start">
                                        <input
                                            type="text"
                                            className="name"
                                            name="searchItem"
                                            placeholder="Search Friends"
                                            onChange={searchValue}
                                            value={type === "Members" ? search :
                                                (type === "Admins" ? search1 : (type === "Requested" ? requestSearch : pendingSearch))}
                                        />
                                        <button
                                            onClick={() => { handleSearch(props.id) }}
                                        >
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div> : ""
                            }
                        </div>
                        <div>
                            {
                                props?.admin && <button className="invite_button"
                                    onClick={() => { setGroupId(props.id); setInvitegroup(!invitegroup) }}
                                >Invite Members</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="member-outer-main row">
                {
                    type === "Members" && <>
                        {
                            groupMemberList.length > 0 ? groupMemberList?.map((val, index) => {
                                return <>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-12 ">
                                        <div className="member-inner-div">
                                            <div className="member-inner-flex">
                                                <div className="imgcirclee">
                                                    <img className="img-fluid" src={val?.tbl_user?.photo} alt='member' loading="lazy" />
                                                </div>
                                                {
                                                    userId !== val?.tbl_user?.id && <div ref={menuBtnRef} className="dots-three" onClick={() => {
                                                        setshow(!show);
                                                        setMatchId(index);
                                                    }}>
                                                        <i className="fa fa-ellipsis-v"></i>
                                                        {show === true && matchId === index ? (
                                                            <Threedotspopup val={permision} id={val?.user_id} remove={removePermission} forwardedref={menuRef} />
                                                        ) : null}
                                                        {sharpermision === true && matchId === index ? (
                                                            <div className="friendRequestpermission">
                                                                <PermissionSharepopup
                                                                    data={() => setSharepermision(false)}
                                                                    userId={val.user_id}
                                                                    userName={val.tbl_user.firstName}
                                                                />
                                                            </div>
                                                        ) : null}
                                                        {removePermissions && matchId === index ?
                                                            <PermissionRemovepopup
                                                                data={() => setRemovePermissions(false)}
                                                                userId={val.user_id}
                                                                userName={val.tbl_user.firstName}
                                                            /> : ""
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            {
                                                val?.tbl_user?.id === userId ? <h6 className="text-capitalize" onClick={() => navigate(`/parent-registration-form`)}>{val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName}</h6> : <h6 className="text-capitalize" onClick={() => navigate(`/user-profile/${val?.user_id}`)}>{val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName}</h6>
                                            }
                                            <p className="text-center">Joined {getTimeDifference(val?.created_at)} </p>
                                            <div className="member-button text-center">
                                                {
                                                    val?.tbl_user?.id !== userId &&
                                                    <>
                                                        {
                                                            val.is_friend === "yes" ?
                                                                <button className="view-profile" onClick={() => {
                                                                    AddUser(val?.tbl_user?.id,{
                                                                        "name": val?.tbl_user?.firstName, "image": val?.tbl_user?.photo,
                                                                        callback: cbstatus?.callback === "callback" ? "ss" : "memberlist"
                                                                    })
                                                                    setMemberId(val?.tbl_user?.id);
                                                                    setUserDetail({
                                                                        "name": val?.tbl_user?.firstName, "image": val?.tbl_user?.photo,
                                                                        callback: cbstatus?.callback === "callback" ? "ss" : "memberlist"
                                                                    })
                                                                }}>Chat  </button>
                                                                :
                                                                <button className="view-profile" onClick={() => {
                                                                    setOpenfrnd(true); setFriendId(val?.tbl_user?.id);
                                                                }}>Friend Invite  </button>
                                                        }
                                                    </>
                                                }
                                                {
                                                    val?.tbl_user?.id !== userId && <> {adminId.includes(userId) && <> {val?.isAdmin === 0 ?
                                                        <button className="remove-from-group" onClick={() => { setMemberId(val.id); setAdminBox(false); setOpen(true) }}>Remove</button> : <button className="remove-from-group" onClick={() => { setMemberId(val.id); setAdminBox(true); setOpen(true) }}>Remove From Group</button>}</>}</>
                                                }
                                                {
                                                    val?.tbl_user?.id === userId && <>
                                                        {
                                                            (adminId.includes(userId) && adminId.length === 1) ? <button className="remove-from-group" onClick={() => { setGroupOpen(true); setLastAdmin("lastAdmin") }}>Leave Group</button> :
                                                                <>
                                                                    {
                                                                        adminId.includes(userId) ? <button className="remove-from-group" onClick={() => { setGroupOpen(true); setLastAdmin("Admin") }}>Leave Group</button>
                                                                            : <button className="remove-from-group" onClick={() => { setGroupOpen(true); setLastAdmin("User") }}>Leave Group</button>
                                                                    }
                                                                </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }) : <h1 className="text-center my-5">Data Not Found</h1>
                        }
                        <div className="show-more">
                            {remainingCount === 1 && <p className="text-center" onClick={() => setPage(page + 1)}>Show more</p>}
                        </div></>
                }
                {
                    type === "Admins" && <AdminList id={props.id} searchKey={search1} type={type}
                        ref={adminRef} />
                }
                {
                    type === "Requested" && <MemberRequest id={props.id} searchKey={requestSearch} type={type} ref={requestRef} />
                }
                {
                    type === "Pending" && <PendingList id={props.id} searchKey={pendingSearch} type={type} ref={pendingRef} />
                }
            </div>
            <DeleteMember
                open={open}
                handleClose={() => setOpen(false)}
                id={memberId}
                funCall={props?.id}
                AdminBox={AdminBox}
            />
            {
                groupOpen && <LeaveGroupPopup
                    open={groupOpen}
                    handleClose={() => setGroupOpen(false)}
                    value={props?.id}
                    lastAdmin={lastAdmin}
                />
            }
            {
                usersrote?.globalOpenChat?.chatOpen && usersrote?.globalOpenChat?.pageName === "memberlist" &&
                 <SingleChat statuspage={"memberlist"} groupName={usersrote?.globalOpenChat?.userDetail} chatClose={() =>  dispatch(getOpenGlobalChat(
                    {chatOpen : false, userDetail : "", userMapId: "", senderId: "", group: "", pageName: ""}))}  userMapId={usersrote?.globalOpenChat?.userMapId} senderId={usersrote?.globalOpenChat?.senderId} group={usersrote?.globalOpenChat?.group} getAllUser={() => { }}
                    callback={(data) => { setCbstatus(data) }}
                    funCall={getGroupmember} />
            }
            {
                invitegroup === true ? <InviteGroups datar={() => setInvitegroup(!invitegroup)} value={groupId} /> : null
            }
            <Modal
                open={openfrnd}
                onClose={() => setOpenfrnd(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="text-center"
                        style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                    >
                        Are you sure you want to invite this friend?
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 3 }}
                        className="d-flex justify-content-center gap-4"
                    >
                        <button className="child-delete" onClick={InviteFriend} >
                            Yes
                        </button>{" "}
                        <button className="child-cancel" onClick={() => setOpenfrnd(false)}>
                            No
                        </button>
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}
export default MemberList