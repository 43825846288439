import axios from "axios";
import CryptoJS from "crypto-js";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SEARCH_URL = process.env.REACT_APP_SEARCH_BASE_URL;
export const imageUrl = process.env.REACT_APP_IMAGE_URL;
export const isProduction = process.env.REACT_APP_IS_PRODUCTION;
export const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;



export const makeGetRequest = async (url, queryParams = {}, headers = {}) => {
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.get(BASE_URL + url, {
      params: queryParams,
      headers: requestHeaders,
      mode: "no-cors",
    });
    if (isProduction === "true") {
      let decryptdata = CryptoJS.AES.decrypt(
        response.data?.data,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptdata);
    }

    return response.data;
  } catch (error) {
    console.error(error?.response?.status);
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      let data = localStorage.getItem("rememberMe") || []
      localStorage.clear();
      localStorage.setItem("rememberMe", data)

      // window.location.href = "/sign-in"
    }
    // throw error;
  }
};

export const makePostRequest = async (url, queryParams, body, headers = {}) => {
  const encriptionBody = {
    data: CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString(),
  };
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.post(
      BASE_URL + url,
      isProduction === "true" ? encriptionBody : body,
      {
        params: queryParams,
        headers: requestHeaders,
        mode: "no-cors",
      }
    );
    if (isProduction === "true") {
      let decryptdata = CryptoJS.AES.decrypt(
        response.data?.data,
        secretKey
      ).toString(CryptoJS.enc.Utf8);

      return JSON.parse(decryptdata);
    }
    return response.data;
  } catch (error) {
    console.error(error?.response?.status);
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      let data = localStorage.getItem("rememberMe") || []
      localStorage.clear();
      localStorage.setItem("rememberMe", data)

      window.location.href = "/sign-in"
    }
    // throw error;
  }
};


export const makePostRequestcancle = async (url, queryParams, body, headers = {}, cancaletoken) => {
  const encriptionBody = {
    data: CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString(),
  };
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.post(
      SEARCH_URL + url,
      isProduction === "true" ? encriptionBody : body,
      {
        params: queryParams,
        headers: requestHeaders,
        mode: "no-cors",
        cancelToken: cancaletoken
      }
    );

    if (isProduction === "true") {
      let decryptdata = CryptoJS.AES.decrypt(
        response.data?.data,
        secretKey
      ).toString(CryptoJS.enc.Utf8);

      return JSON.parse(decryptdata);
    }

    return response.data;
  } catch (error) {
    console.error("error:---:", error);
    if (axios.isCancel(error)) {
      return true;
    } else if (error?.response?.status === 403 || error?.response?.status === 401) {
      let data = localStorage.getItem("rememberMe") || [];
      localStorage.clear();
      localStorage.setItem("rememberMe", data);
      window.location.href = "/sign-in";
    } else {
      // Re-throw the error if it's not a cancellation
      throw error;
    }
  }
};

export const makePutRequest = async (url, queryParams, body, headers = {}) => {
  const encriptionBody = {
    data: CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString(),
  };
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.put(
      BASE_URL + url,
      isProduction === "true" ? encriptionBody : body,
      {
        params: queryParams,
        headers: requestHeaders,
      }
    );
    if (isProduction === "true") {
      let decryptdata = CryptoJS.AES.decrypt(
        response.data?.data,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptdata);
    }

    return response.data;
  } catch (error) {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      let data = localStorage.getItem("rememberMe") || []
      localStorage.clear();
      localStorage.setItem("rememberMe", data)

      window.location.href = "/sign-in"
    }
    // throw error;
  }
};

export const makeDeleteRequest = async (
  url,
  queryParams,
  body,
  headers = {}
) => {
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.delete(BASE_URL + url, {
      params: queryParams,
      headers: requestHeaders,
    });
    if (isProduction === "true") {
      let decryptdata = CryptoJS.AES.decrypt(
        response.data?.data,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptdata);
    }
    return response.data;
  } catch (error) {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      let data = localStorage.getItem("rememberMe") || []
      localStorage.clear();
      localStorage.setItem("rememberMe", data)

      window.location.href = "/sign-in"
    }
    // throw error;
  }
};

const getHeaders = (headers = {}) => {
  const token = localStorage.getItem("auth-token");
  let headerObj = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    ...headers,
  };
  if (token) {
    headerObj.Authorization = `Bearer ${token}`;
  }
  return headerObj;
};

//UPDATE PROFILE
export const updateChildProdile = async (url, body, headers) => {
  const encriptionBody = {
    data: CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString(),
  };
  try {
    let requestHeaders = getHeaders(headers);
    const response = await axios.post(
      BASE_URL + url,
      isProduction === "true" ? encriptionBody : body,
      {
        headers: requestHeaders,
      }
    );
    if (isProduction === "true") {
      let decryptdata = CryptoJS.AES.decrypt(
        response.data?.data,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptdata);
    }

    return response.data;
  } catch (error) {
    console.error(error);
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      let data = localStorage.getItem("rememberMe") || []
      localStorage.clear();
      localStorage.setItem("rememberMe", data)

      window.location.href = "/sign-in"
    }
    // throw error;
  }
};

// comman funcation for api calling
export const makePostRequestForAll = async (url, body) => {
  const encriptionBody = {
    data: CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString(),
  };
  try {
    let requestHeaders = getHeaders({});
    const response = await axios.post(
      BASE_URL + url,
      isProduction === "true" ? encriptionBody : body,
      { headers: requestHeaders, mode: "no-cors" }
    );
    if (isProduction === "true") {
      let decryptdata = CryptoJS.AES.decrypt(
        response.data?.data,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptdata);
    }

    return response.data;
  } catch (error) {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      let data = localStorage.getItem("rememberMe") || []
      localStorage.clear();
      localStorage.setItem("rememberMe", data)

      window.location.href = "/sign-in"
    }
    // throw error;
  }
};
// for formdata
export const makeFormDateRequset = async (url, body) => {
  let my_token = localStorage.getItem("auth-token");
  let headers = {
    Authorization: "Bearer " + my_token,
  };
  try {
    const res = await axios.post(BASE_URL + url, body, { headers });
    let decryptdata = CryptoJS.AES.decrypt(res.data?.data, secretKey).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decryptdata);
  } catch (error) {
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      let data = localStorage.getItem("rememberMe") || []
      localStorage.clear();
      localStorage.setItem("rememberMe", data)

      window.location.href = "/sign-in"
    }
    console.log("error:::", error);
  }
};
