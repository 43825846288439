import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { decodeToken } from "../utilities/CustomFunctions";

function ProtectedRoute({ role, children, path }) {
  let userData = decodeToken();
  const location = useLocation();
  sessionStorage.setItem("locationfriendRoute", JSON.stringify(location));

  if (window.location.pathname !== "success") {
    // localStorage.removeItem("unique_id")
  }
  if (!userData) {
    return <Navigate to="/sign-in" state={{ from: location?.pathname }} />;
  }
  if (userData?.userAuth && !userData.userAuth.firstName) {
    return <Navigate to="/parent-registration-form" />;
  }
  if (
    userData?.userAuth?.is_subscription_community !== "yes" &&
    userData?.userAuth?.is_subscription_group !== "yes" &&
    userData?.userAuth?.is_subscription_vander !== "yes"
  ) {
    return <Navigate to="/subscription" />;
  }
  return children;
}
export default ProtectedRoute;
