import React, { useEffect, useRef } from "react";
import "../Search.WebAppMate/reportpopup.css";
import { useState } from "react";
import { makePostRequestForAll } from "../../services/api";
import { decodeToken, toastifyMessage,Emailvalidation } from "../../utilities/CustomFunctions";
import { URLS } from "../../constants";
import FourDotsLoader from "./FourDoted";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { makeGetRequest } from "../../services/api";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
/**
 * create components is used for friend list
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const filter = createFilterOptions();
function InviteFriendpopup(props) {
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [spin, setSpin] = useState(false);
  const [addError, setAddError] = useState();
  const [emailList, setEmailList] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [friendData, setFriendData] = useState([]);
  let userData = decodeToken()?.userAuth?.id;
  let emailFocus;
  const cmntFocus = useRef();
  const [data, setData] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [copied, setCopied] = useState(false);
  const URL = process.env.REACT_APP_URL;

  console.log(emailList,"emailList.........")

  // this function is used for copy friend link
  const handleCopyClick = () => {
    navigator.clipboard.writeText(`${URL}/purchase-pages-friend/${userData}`)
      .then(() => {
        setCopied(true);
      })
      .catch(error => {
        console.error('Error copying text:', error);
      });
  };

  useEffect(() => {
    setSelectedOption([{ keyword: "", label: "" }]);
  }, [])
  // this function is use for send friend request to anyone
  const handleSubmit = () => {
    if (friendData.length === 0 && emailList.length <= 0) {
      setEmailError("Please fill up at least one field.");
    }
    else if (addError) { 
      setEmailError(addError);
    } 
    else if (data === "") {
      setMessageError("Please enter comment");
      cmntFocus.current.focus();
    }
   
    else {
      setSpin(true)
      const list = [];
      for (var i = 0; i < emailList.length; i++) {
        list.push({ email: emailList[i] });
      }
      for (var j = 0; j < friendData.length; j++) {
        list.push({ email: friendData[j].email });
      }
      const payLoad = {
        user_id: userData,
        emailList: list,
        message: data,
      };
      makePostRequestForAll(URLS.createAddInviteFriend, payLoad)
        .then((res) => {
          console.log("res", res, payLoad)
          setSpin(false)
          if (res.code === 201) {
            toastifyMessage(res.message, "success");
            props?.datar();
            props?.funcall()
          } else if (res.code === 400) {
            toastifyMessage(res.message, "warn");
            props?.datar()
          } else {
            props?.datar()
          }
        })
        .catch((error) => {
        });
    }
  };
  useEffect(() => {
    const validEmails = [];
    const invalidEmails = [];
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    for (const email of keywords) {
      const trimmedEmail = email?.keyword?.trim() || email;
      if (emailPattern.test(trimmedEmail)) {
        validEmails.push({ keyword: trimmedEmail });
      } else {
        invalidEmails.push({ keyword: trimmedEmail });
      }
    }
    if (invalidEmails.length > 0) {
      setAddError("Please enter valid friend's email");
    } else {
      setAddError('');
    }
    setEmailList([...validEmails, ...invalidEmails]);
  }, [keywords]); 
  
  useEffect(() => {
    makeGetRequest(URLS.allFriend, null, null, null)
      .then((res) => {
        console.log("allfriend", res)
        const arr = [];
        res?.data.map((val) => {
          return arr.push({ label: val.firstName + " " + val.lastName, value: val.id, email: val.email })

        })
        setFriendList(arr)
      }).catch((error) => {
        console.log("error", error)
      })
  }, [])

  const handleFriendsId = (shareResourceVal) => {
    console.log("shareResourceVal", shareResourceVal)
    let shareArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.forEach((val, i) => {
        shareArr.push(val.value);
      });
    // setFriendData(shareResourceVal.filter(item => item.value !== "all"))
  };
  return (
    <>
      <div className="sharepopupOuter">
        {spin && (
          <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        )}
        <div className="pop-up-container InviteFriendInspiration">
          <button className="close-btn" onClick={props.datar}> X </button>
          <h3>Invite Friends to MatchED</h3>
          {/* <div className="share-group">
            <label className="inviteGpopupinnerlabel mx-0">Type or Select names of MatchED Members<span className="start-color">*</span></label ><label className="oneSelect">
            </label><br />
            <Select
              value={friendData ? friendData : null}
              options={friendList}
              isMulti
              selectMultiple={true}
              onChange={handleFriendsId}
            />
          </div> */}
          <div className="kayword-associatedNew">
            <div className="email-text-option"> <label>
              {/* Type email addresses of MatchED/Non-MatchED Members */}
Friend's Email(s)
              <span className="start-color">*</span></label></div>
            <Autocomplete
              multiple
              value={emailList}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setKeywords({
                    keyword: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  setKeywords({
                    keyword: newValue.inputValue,
                  });
                } else {
                  let filteredArray = newValue.filter(item => {
                    if (typeof item === 'string') {
                      return item.trim() !== '';
                    }
                    if (item.hasOwnProperty('label') && typeof item.label === 'string') {
                      return item.label.trim() !== '';
                    }
                    return true;
                  });

                  setKeywords(filteredArray);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.keyword
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    keyword: inputValue,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={selectedOption}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.keyword;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.keyword}</li>
              )}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Email" inputRef={input => {
                  emailFocus = input;
                }} />
              )}
            />
          </div>
          {emailList.length <= 0 && friendData.length <= 0 ? <span className="error-span">
            {addError ? addError : emailError && emailError}
          </span> : ""}
       {addError && (
  <div style={{ color: 'red', marginTop: '10px' }}>
    {addError}
  </div>
)}
          <div className="email-text-option">
            <label> Comments<span className="start-color">*</span></label>
            <textarea
              className="form-control"
              name="message"
              ref={cmntFocus}
              onChange={(e) => setData(e.target.value)}
              value={data}
            ></textarea>
            <span className="error">{data === "" ? messageError : ""}</span>
          </div>
          <div className="create-link-btn">
            <button className="sendinvite  sendivite1"  onClick={handleSubmit}>
              Send Invite
            </button>
            
            <button className="sendinvite mx-3 copy-icon copyLink" onClick={handleCopyClick}>
            <div className="content-text-bsvg">
              <p className="my-1">Copy link</p>
              <div className="copy-icon">
                <div className="tooltip">{copied ? "Link copied" : "Copy Link"}</div>
                <ContentCopyIcon className="mt-1" />
              </div>
            </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default InviteFriendpopup;
