import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { makePostRequest, makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { toastifyMessage, style } from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { useDispatch } from "react-redux";
import { getchildList } from "../../store/reducers/userReducer";

/**
 * create components is used to delete child profile
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const DeleteChildPopUp = (props) => {
  const naviget = useNavigate();
  const [spin, setSpin] = useState(false)
  let dispatch = useDispatch();

  // this function is used for delete child
  const deleteChild = () => {
    setSpin(true)
    const payload = {
      id: props.id,
    };
    makePostRequestForAll(URLS.deleteChild, payload)
      .then((res) => {
        setSpin(false)
        if (res.code === 200) {
          var payload = {
            resource_id: "",
          };
          toastifyMessage(res.message, "success");
          makePostRequest(URLS.getChildList, null, payload, null).then((res) => {
            dispatch(getchildList(res.data))
          });
        }
        props.handleClose();
        setTimeout(() => {
          naviget("/search-page");
        }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      {spin && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >
            Are you sure you want to remove this profile?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button className="child-delete" onClick={deleteChild}>
              Remove
            </button>{" "}
            <button className="child-cancel" onClick={props.handleClose}>
              Cancel
            </button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteChildPopUp;
