
import React from "react";
import './ourwhy.css';
import graphic1 from '../../assets/Images/Matched-graphic-01.png';
import graphic2 from '../../assets/Images/people.png';
const Ourwhy = () => {
return (
        <div className="main-container">
            <div className="container">
                <div className="row">
                     <div>
                        <h2 className="ourwhy-text">Our Why </h2>
                        <p className="ourwhy-para">We are making it easier to find learning resources matched personally for your students.</p>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <img src={graphic1} className="image-1  img-fluid" alt="matched story" />
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="storyInnerBox">
                            <img src={graphic2} className="image-2 img-fluid" alt="matched story" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div>
                            <p className="paragraph-ourwhy">
                                <span className="spanpara">Millions of parents/educators have the same question: </span>
                                “I know there are many options,<span className="spanpara2"> but how do I find and access the best learning resources for MY child/student?”</span></p>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div>
                            <p className="paragraph-ourwhy2" > <span className="spanpara2">MatchED </span> will be a vital tool in the hands of parents and educators
                                which<span className="spanpara2"> recommends great education resource matches</span> </p>
                            <p className="paragraph-ourwhy2 m-0" ><span className="spanpara2"> for MY child/student!</span></p>
                        </div>
                    </div>
                </div>
                 <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                       <div className="believe-container">
                         <div className="circle1">
                                <div className="heart-center">
                                    <strong>Heart</strong>
                                    <span className="circle-text">Character</span>
                                </div>
                                </div>
                            <div className="circle2box">
                                <div className="circle2">
                                    <div className="heart-center">
                                        <strong>Strength</strong>
                                        <span className="circle-text">physical Health</span>
                                    </div>
                                    </div>
                                <div className="circle4">
                                    <div className="heart-center">
                                        <strong>Mind</strong>
                                        <span className="circle-text">Knowledge</span>
                                    </div>
                                </div>
                            </div>
                            <div className="circle3">
                                <div className="heart-center">
                                    <strong>Soul</strong>
                                    <span className="circle-text"> Faith</span>
                                </div>
                                </div>
                                 </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <div className="text-con">
                            <div> <h2 className="we-Believe">We believe</h2> </div>
                            <div>
                                <ul className="ourwhylistitems">
                                    <li className="para-list-1"><span className="spanpara2">We believe </span>every child is uniquely and wonderfully made.</li>
                                    <li className="para-list-2"><span className="spanpara2">We believe </span>education should be adapted for each child rather than making each child adapt to their education.</li>
                                    <li className="para-list-3"><span className="spanpara2">We believe </span> education includes the formation of a child’s heart (character), mind (academics), soul (faith) and strength (health and physical fitness).</li>
                                    <li className="para-list-4"><span className="spanpara2">We believe </span> “education” is far more than just what happens in classrooms. It includes all learning activities and experiences that form a child.</li>
                                    <li className="para-list-5"><span className="spanpara2">We believe </span> parents have the ultimate privilege and responsibility to train up their children in the ways they should go by providing learning experiences aligned with their children's passions and learning styles as well as their family's values and/or faith.</li>
                                </ul>
                            </div>
                         </div>
                    </div>
                </div>
             </div>
        </div>
     );
}
export default Ourwhy;