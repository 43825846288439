import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import "./Chat.css";
import { makeGetRequest, makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants/urls";
import Box from "@mui/material/Box";
import { io } from "socket.io-client";
import Typography from "@mui/material/Typography";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import { imageUrl } from "../../services/api";
import AddMember from "./AddMember";
import { decodeToken, getTime, toastifyMessage } from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import SingleChat from "./SingleChat";
import GroupSetting from "./GroupSetting";
import { useSelector, useDispatch } from 'react-redux';
import { getOpenGlobalChat , setChatSound} from '../../store/reducers/userReducer';

const host = process.env.REACT_APP_CONECT_URL;

// Modal styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 6,
};

/**
 * Component for managing chat list.
 * block /unblock listing ,get users
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */
const Chatlist = (props) => {
  const getimage = imageUrl;
  const dispatch = useDispatch()
  const payLoad = decodeToken().userAuth;
  const userStore = useSelector((state) => state.user);
  const image = decodeToken().userAuth.photo;
  const [toggle, setToggle] = useState(false);
  const [allChats, setAllChats] = useState("");
  const [openGroup, setOpenGroup] = useState(false);
  const [groupDta, setGroupDta] = useState("");
  const [openBlockUser, setOpenBlockUser] = useState(false);
  const [blockUser, setBlockUser] = useState("");
  const [leavePopup, setLeavePopup] = useState(false);
  const [fun, setFun] = useState("");
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [tempUser, setTempUser] = useState("");
  const [count, setCount] = useState("");
  const [viewText, setViewText] = useState("");
  const [statusCheck, setStatusCheck] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [sound, setSound] = useState(true)
  const menuRef = useRef(null);
  const menuBtnsRef = useRef(null);

  // Search user in chat list
  const handleSearchUser = (e) => {
    const searchUser = e.target.value.toLowerCase();
    if (user) {
      const filtered = tempUser.filter((res) =>
        res.name.toLowerCase().includes(searchUser)
      );
      setAllChats(filtered);
      setSearchUser(searchUser);
    }
  };

  // Fetch chat list on component mount
  useEffect(() => {
    getllchatlist();
    makePostRequestForAll(URLS.getChatMemberListToAddApi, {
      user_id: payLoad?.id,
    }).then((res) => {
      setUser(res.data);
    });
    setSound(payLoad?.chat_sound)
    const socket = io.connect(host);
    socket.emit("add-user", payLoad?.id);
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, [payLoad?.id]);

  // Open chat screen after receiving callback data
  useEffect(() => {
    if (groupDta) {
      dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true }))
    }
     // eslint-disable-next-line
  }, [groupDta]);
  
  // Fetch all chats
  const getllchatlist = () => {
    setIsLoading(true);
    makePostRequestForAll(URLS.getChatWithGroupUser, {}).then((res) => {
      const data = res.data;
      var count = 0;
      for (let i = 0; i < data.length; i++) {
        count += data[i].unread_message;
      }
      setCount(count);
      props?.count(count)
      setAllChats(res.data);
      setTempUser(res.data);
      setIsLoading(false);
    });
  };

  // Handle sound
  const handleSound = () => {
    makePostRequestForAll(URLS.saveChatSound, { user_id: payLoad?.id })
      .then(() => {
        setSound(!sound)
        dispatch(setChatSound(!sound))
        makeGetRequest(URLS.token, null, null).then((res) => {
          localStorage.setItem("auth-token", res?.data?.authorization);
        });
      })
      .catch((error) => {
        toastifyMessage("Something went wrong.", "error");
      });
  };

  // Marks all as read
  const markAsAllRead = (id, status) => {
    if (payLoad?.id === id || status === 0) {
      let data;
      if (payLoad?.id === id) {
        data = {
          users_map_id: "",
          group_id: "",
        };
      } else if (status === 0) {
        data = {
          users_map_id: id,
          group_id: "",
        };
      }
      makePostRequestForAll(URLS.markAllAsRead, data)
        .then((res) => {
          console.log("res:---", res)
          getllchatlist();
        })
        .catch((error) => console.log("err:", error));
    }
    if (status === 1) {
      const data = {
        "chat_multiple_id": id
      }
      makePostRequestForAll(URLS.multiChatReadAll, data)
        .then((res) => {
          console.log("res::", res)
          getllchatlist();
        })
        .catch((error) => {
          toastifyMessage("something went wrong.", "error");
        });
    }
  };

  // Fetch blocked chat group list
  const BlockedChatGroupList = () => {
    setLoading(true);
    makePostRequestForAll(URLS.getBlockedChatGroupList, {
      user_id: payLoad?.id,
    })
      .then((res) => {
        setLoading(false);
        setBlockUser(res.data);
      })
      .catch((error) => {
        setLoading(false);
        toastifyMessage("something went wrong.", "error");
      });
  };

  // Common function for block/unclock/leave/delete
  const CommamFuncation = (id, status) => {
    if (statusCheck === "blockOrUnblock") {
      const data = {
        block_by_user_id: payLoad?.id,
        users_map_id: id,
        status: status,
      };
      makePostRequestForAll(URLS.blockUnblockChat, data)
        .then((res) => {
          if (res.code === 200) {
            setLeavePopup(false);
            dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false }))
            toastifyMessage(res.message, "success");
          }
          getllchatlist();
        })
        .catch((error) => {
          toastifyMessage("something went wrong.", "error");
        });
    }
  };

  // Handle click outside
  const handleClickOutsides = (e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !menuBtnsRef.current.contains(e.target)
    ) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsides, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsides, true);
    };
  }, []);

  // Handle count
  useEffect(() => {
    !userStore?.globalOpenChat?.chatOpen && getllchatlist();
    setCount(userStore?.chatCount);
    // eslint-disable-next-line
  }, [userStore?.chatCount]);

  return (
    <div ref={props.forwardedref} className="modal-container">
      <div className="Messanger">
        {userStore?.globalOpenChat?.chatOpen && userStore?.globalOpenChat?.pageName === "chatlist" &&
        (
          <SingleChat
            groupName={userStore?.globalOpenChat?.userDetail ? userStore?.globalOpenChat?.userDetail : groupDta}
            chatClose={() =>    dispatch(getOpenGlobalChat({
            chatOpen: false, userDetail: "", userMapId: "", senderId: "", group: "", pageName : "" }))}
            userMapId={userStore?.globalOpenChat?.userMapId}
            senderId={userStore?.globalOpenChat?.senderId}
            getAllUser={getllchatlist}
            group={userStore?.globalOpenChat?.group}
            statuspage={"chatlist"}
          />
        )}
        <div className="messanger-inner">
          <div className="messanger-inner-div">
            {/* Chat user profile */}
            <div className="chat-user-profile">
              <img className="img-fluid" src={getimage + image} alt="user" loading="lazy" />
              <span></span>
            </div>
            <div className="edit-button">
              {count > 0 && <span className="four">{count}</span>}
              <span ref={menuBtnsRef}>
                <i
                  className="fa fa-ellipsis-h"
                  onClick={() => {
                    setToggle(!toggle);
                    getllchatlist();
                  }}
                ></i>
              </span>
              <div className="position-relative" style={{ marginTop: "50px" }}>
                <div ref={menuRef}
                  className="position-absolute  messagethreedostpopup"
                  style={{ width: "150px" }}
                >
                  {toggle && (
                    <div className="bg-white font-weight-normal toggle-of-chat shadow-sm rounded px-1">
                      <p
                        className="border-bottom d-flex justify-content-start px-2 courser-point"
                        onClick={() => setOpen(true)}
                      >
                        <span>New Chat</span>
                      </p>
                      <p
                        className="border-bottom d-flex justify-content-start px-2 courser-point"
                        onClick={() => {
                          setOpenGroup(true);
                        }}
                      >
                        <span>New Group Chat</span>
                      </p>
                      <p
                        className="border-bottom d-flex justify-content-start px-2 courser-point"
                        onClick={() => markAsAllRead(payLoad?.id, 0)}
                      >
                        <span>Mark All As Read</span>
                      </p>
                      <p className="border-bottom d-flex justify-content-around px-1 courser-point">
                        <span>Chat Sounds</span>
                        <span>
                          <div className="form-check form-switch form-switch-sm">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultChecked={sound}
                              id="flexSwitchCheckDefault"
                              onChange={handleSound}
                            />
                          </div>
                        </span>
                      </p>
                      <p
                        className="d-flex justify-content-start px-2 courser-point"
                        onClick={() => {
                          BlockedChatGroupList();
                          setOpenBlockUser(true);
                        }}
                      ><span>Blocked Friends</span></p>
                    </div>
                  )}
                </div>
              </div>
              <div className="toprigntschatmiddle">
                <ul>
                  <li>
                    <button
                      onClick={() => {
                        props.datar();
                      }}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="icons-inner">
              <input
                type="text"
                placeholder="Search Message"
                onChange={handleSearchUser}
                value={searchUser}
              />
              <span>
                <i className="fa fa-search"></i>
              </span>
            </div>
            <div className="table-responsive chatList">
              {isLoading ? (
                <FourDotsLoader />
              ) : (
                <>
                  {allChats.length > 0 ? (
                    allChats.map((val) => {
                      return (
                        <>
                          <div
                            className="chatnames"
                            onClick={() => {
                              dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false }))
                              markAsAllRead(val.id, val.chat_multiple ? val.chat_multiple : 0);
                              // setGroup(val.chat_multiple ? val.chat_multiple : 0);
                              // setSenderId(val.send_to);
                              // setUserMapId(val.id);
                              // setGroupName({
                              //   image: val?.image,
                              //   name: val?.name,
                              //   admin: val?.is_admin,
                              //   name_Status: val?.name_status,
                              //   callback: "chatlist"
                              // });
                              // setChatOpen(true);
                              dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true, userMapId: val.id, userDetail: {
                                image: val?.image,
                                name: val?.name,
                                admin: val?.is_admin,
                                name_Status: val?.name_status,
                                callback: "chatlist"
                              }, senderId: val.send_to, group: val.chat_multiple ? val.chat_multiple : 0 , pageName : "chatlist" }))
                              setSearchUser("");
                            }}
                          >
                            <div className="chatnamesimg">
                              <img className="img-fluids" src={val.image} alt="chat content" loading="lazy" />
                            </div>
                            <div className="chatnamemiddle">
                              <div className="chatnamemiddlelt ">
                                <h2>{val.name} </h2>
                                <p> {val.message} </p>
                              </div>
                              <div className="chatnamemiddlert pr-3">
                                <h5>
                                  {getTime(val?.message_date, "chat")}
                                </h5>
                                <p className="py-1">
                                  {val.unread_message ? (
                                    <span>{val.unread_message}</span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <h2 className="text-center my-5">No user Found</h2>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add member modal */}
      {open && <AddMember
        open={open}
        handleCloseButton={() => {
          setOpen(false);
        }}
        data={(data) => setAllChats(data)}
      />}
      {/* New group chat modal */}
      {openGroup && <GroupSetting open={openGroup}
        onClose={() => {
          setOpenGroup(false);
        }}
        callback={(id, data, status) => { setGroupDta(data); 
          dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true, userMapId: id, userDetail: data,
            group: status, pageName : "chatlist"  }))

         }}
        funCall={getllchatlist} />
      }
      {/* Blocked user modal */}
      <Modal
        open={openBlockUser}
        onClose={() => setOpenBlockUser(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {blockUser.length > 0 ? <h1 className="add-title my-2">Blocked Friends</h1> : ""}
          <div className="search-list">
            {loading && <FourDotsLoader />}
            {blockUser.length > 0 ? (
              blockUser.map((val, index) => {
                return (
                  <>
                    <div className="user-search border-bottom">
                      <div>
                        <h2 className="py-2">{val.name}</h2>
                      </div>
                      <div className="py-2">
                        <button
                          onClick={() => {
                            setLeavePopup(true);
                            setViewText(
                              "Are you sure you want to unblock this member?"
                            );
                            setStatusCheck("blockOrUnblock");
                            setFun({ id: val.id, status: "unblock" });
                            setOpenBlockUser(false);
                          }}
                        >
                          unblock
                        </button>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="search-list"> <h1 style={{ textAlign: "center", fontSize: "30px", verticalAlign: "middle"}}>No data found</h1></div>
            )}
          </div>
        </Box>
      </Modal>
      {/* Comman delete modal */}
      <Modal
        open={leavePopup}
        onClose={() => setLeavePopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
            style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
          >{viewText}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 3 }}
            className="d-flex justify-content-center gap-4"
          >
            <button
              className="child-delete"
              onClick={() => CommamFuncation(fun.id, fun.status)}
            >
              Unblock
            </button>
            <button
              className="child-cancel"
              onClick={() => setLeavePopup(false)}
            >
              Cancel
            </button>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Chatlist;
