/**
 * Serve Dashboard  page
 *
 * @author Nidhi Mishra <nidhi.mishra@webappmate.com>
 * created on: 15-05-2023
 */
import { useEffect } from "react";
import "./ServeDashboard.css";
import Vectorman from "../../assets/Images/servetop.jpg";
import Vectorwoman from "../../assets/Images/servebottom.jpg";
import serve1 from "../../assets/Images/serve1.png";
import serve2 from "../../assets/Images/serve2.png";
import serve3 from "../../assets/Images/serve3.png";
import serve4 from "../../assets/Images/serve4.png";
import serve5 from "../../assets/Images/serve5.png";
import serve6 from "../../assets/Images/serve6.png";
import { useNavigate, Link } from "react-router-dom";
import Head from "../Layout.WebAppMate/head/Head";
import { URLS } from "../../constants";
import { makePostRequest } from "../../services/api";
import { useState } from "react";
import { decodeToken } from "../../utilities/CustomFunctions";
function ServeDashboard() {
  let useractive = localStorage.getItem("useractive");
  let navigate = useNavigate();
  let userData = decodeToken();
  const [countryName, setCountryName] = useState("");
  const headerTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  useEffect(() => {
    headerTop();
  }, []);

  useEffect(() => {
    if (useractive === "") {
      navigate("/sign-in");
    }
    window.scrollTo({ top: 0,behavior:"smooth"});
    return () => {
      window.scrollTo({ top: 0,behavior:"smooth" });
    }
  }, [useractive, navigate]);

  useEffect(() => {
    document.body.classList.add("serveDashboard");
    return () => {
      document.body.classList.remove("serveDashboard");
    };
  }, []);

  const getUserData = () => {
    let data = {
      id: userData.userAuth.id,
    };
    makePostRequest(URLS.userfetchdata, null, data, null).then((res) => {
      console.log("userfetchdata", res)
      setCountryName(res?.data?.uesrData?.country_name)
      
    });
  };

  console.log("country name", countryName)
useEffect(() => {
  getUserData();
}, [])
  return (
    <>
      <Head title="Serve"
        content="Elevate Your Impact with MatchED Serve: Post Local Field Trips, Services, and Mentorship Opportunities. Connect and Share Valuable Resources with Friends, Groups, Local Members, and the Global MatchED Community." ></Head>

      <div className="col-lg-10">
        <div className="serve-dash">
          <h1>Your Serve Dashboard</h1>
          <div className="serve-dash-image">
            <img className="img-fluid" src={Vectorman} alt="Resources" />
          </div>
        </div>
        <div className="serve-dashboard ">
          <div className="serve-inner-dashboard col-12">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="serverboxtem">
                  <Link to="/serve-your-resources" className="link-style">
                    <div className="serve-inner-image3">
                      <img className="img-fluid" src={serve4} alt="Resources" />
                    </div>
                    <h4>Your Resources</h4>
                    <p>View all your active and archived resources.</p>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="serverboxtem">
                  <Link to="/serve" className="link-style">
                    <div className="serve-inner-image4">
                      <img className="img-fluid" src={serve5} alt="MatchED Resource" />
                    </div>
                    <h4>Create Resource/Service</h4>
                    <p>
                      Start teaching or mentoring today or create your own resource.
                    </p>
                  </Link>
                </div>
              </div>
              {(countryName === "US" || countryName === "CA") && 
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="serverboxtem">
                  <Link to={`/server-payment-history?country=${countryName}`} className="link-style">
                    <div className="serve-inner-image5">
                      <img className="img-fluid" src={serve6} alt="resource purchse Invoices" />
                    </div>
                    <h4>Your Purchases</h4>
                    <p>View your purchases and invoices in one place.</p>
                  </Link>
                </div>
              </div>}
              {(countryName === "US" || countryName === "CA") && 
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="serverboxtem">
                  <Link to="/create-schedule" className="link-style">
                    <div className="serve-inner-image">
                      <img className="img-fluid" src={serve1} alt="resource Schedule" />
                    </div>
                    <h4>Manage Your Schedule</h4>
                    <p>
                      Use our scheduling tool to create, edit, and view your  availability and appointments.
                    </p>
                  </Link>
                </div>
              </div>
}
{(countryName === "US" || countryName === "CA") && 
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="serverboxtem">
                  <Link to="/session-history" className="link-style">
                    <div className="serve-inner-image1">
                      <img className="img-fluid" src={serve2} alt="serve Upcoming Appointments" />
                    </div>
                    <h4>Upcoming Appointments</h4>
                    <p>
                      Never miss an appointment by seeing all your upcoming appointments and who has signed up in one place.
                    </p>
                  </Link>
                </div>{" "}
              </div>
}
              {(countryName === "US" || countryName === "CA") && 
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="serverboxtem">
                  <Link
                    to="/sale-history"
                    className="link-style"
                  >
                    <div className="serve-inner-image2">
                      <img className="img-fluid" src={serve3} alt="serve Sales Histor" />
                    </div>
                    <h4>Sales / Bookings</h4>
                    <p>View all your sales and bookings in one place.</p>
                  </Link>
                </div>{" "}
              </div>
}
              <div className="womanimage">
                <div className="servedashboardbottomImg">
                  <img className="img-fluid" src={Vectorwoman} alt="serve" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ServeDashboard;
