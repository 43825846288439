import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import searchReducer from './reducers/searchReducer';
import counterSlice  from './reducers/cartReducer';
const store = configureStore({
  reducer:{
    user : userReducer,
    search : searchReducer,
    cart : counterSlice,
  }
});

export default store;